import moment from "moment";
import { ChannelType } from "../../models/channel";
import { DATE_METRICS } from "../../models/chart";
import currencySymbolMap from 'currency-symbol-map';

function getDurationString(time) {
  // return time;
  var duration = parseFloat(time.toString().replaceAll(",", ""));

  const minuteDuration = 60;
  const hourDuration = 60 * minuteDuration;
  const dayDuration = 24 * hourDuration;

  var days = 0;
  var hours = 0;
  var minutes = 0;
  if (duration >= dayDuration) {
    days = Math.floor(duration / dayDuration);
    duration -= dayDuration * days;
  }
  if (duration >= hourDuration) {
    hours = Math.floor(duration / hourDuration);
    duration -= hourDuration * hours;
  }
  if (duration > minuteDuration) {
    minutes = Math.floor(duration / minuteDuration);
    duration -= minuteDuration * minutes;
  }

  if (!Number.isSafeInteger(duration)) {
    duration = parseInt(duration);
  }

  var string = `${duration}s`;
  if (minutes > 0) { string = `${minutes}m ` + string }
  if (hours > 0) { string = `${hours}h ` + string }
  if (days > 0) { string = `${days}d ` + string }

  return string;
}

const Attribution_Keys = {
  "1d_click": "1 day click",
  "7d_click": "7 day click",
  "28d_click": "28 day click",
  "1d_view": "1 day view",
  "1d_ev": "1 day engaged view",
  "7d_view": "7 day view",
  "28d_view": "28 day view",
  "skan_view": "View through SKAd",
  "skan_click": "Click through SKAd",
}

export const AttributionDataMapper = (value = "") => value.split("_")
  .reduce((acc, current, index) => (index % 2 === 0 ? acc.push(current) : (acc[acc.length - 1] += `_${current}`), acc), [])
  .map(key => Attribution_Keys[key] ?? key)
  .join(" or ");

const unitMapper = {
  "DATE": getDurationString,
  "TIME": getDurationString,
  "DATETIME": getDurationString,
  "PERCENT": val => `${val}%`,
  "PRICE": (val, currency) => `${currencySymbolMap(currency) ? currencySymbolMap(currency) : ""}${val}`,
  "CURRENCY": (val, currency) => `${currencySymbolMap(currency) ? currencySymbolMap(currency) : ""}${val}`,
}

const insertComma = valStr => {
  const digits = valStr.length;
  for (let i = 0; i < Math.floor((digits - 1) / 3); i++) {
    const endIndex = digits - (i + 1) * 3;
    valStr = valStr.slice(0, endIndex) + "," + valStr.slice(endIndex);
  }
  return valStr;
}

export const DisplayFormatter = value => {
  // for arrays, insert inside div
  if (Array.isArray(value)) {
    return value.map(v => <div>{v}</div>);
  }
  if (typeof value === "number") {
    const signString = Math.sign(value) === -1 ? "-" : "";
    const mag = Math.abs(value);
    // for big integers, insert commas
    if (Number.isSafeInteger(value)) {
      return signString + insertComma(mag.toString());
    }
    // for float, only 2 decimal places
    const valStr = mag.toFixed(2);
    return signString + insertComma(valStr.slice(0, valStr.length - 3)) + valStr.slice(valStr.length - 3);
  }
  return value ?? "--";
}

export const UnitDataFormatter = (number, unit, currency) => {
  const mapper = (unit ? unitMapper[unit.toUpperCase()] : null) ?? (val => val);
  return mapper(DataFormatter(number), currency);
};

export const UnitDisplayFormatter = (number, unit, currency) => {
  const mapper = (unit ? unitMapper[unit.toUpperCase()] : null) ?? (val => val);
  return mapper(DisplayFormatter(number), currency);
};

export const DataFormatter = (number) => {
  if (!number) { number = 0; }
  const signString = Math.sign(number) === -1 ? "-" : "";
  const mag = Math.abs(number);
  if (mag > 1000000000) {
    return signString + (mag / 1000000000).toFixed(2) + "B";
  } else if (mag > 1000000) {
    return signString + (mag / 1000000).toFixed(2) + "M";
  } else if (mag > 1000) {
    return signString + (mag / 1000).toFixed(2) + "K";
  } else {
    return signString + mag.toFixed(2);
  }
};

export const DateFormatter = (date) => {
  if (!date) { return ""; }
  const formattedDate = moment(date, ["MMMM , YYYY", "YYYY-MM-DD"]).format("MMM DD");
  return formattedDate === "Invalid date" ? date : formattedDate;
};

export const DateDisplayFormatter = (date) => {
  if (!date) { return ""; }
  return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
};

export const DisplayDateForPayload = (date) => {
  if (!date) { return ""; }
  return moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");
};

export const isDate = (value) => {
  if (Number(value) || value == 0) return false;
  else return !!Date.parse(value);
};

export const TooltipContent = (e, record = {}, xField, useDateFormatter, currencyMapping) => (
  <div
    className="smooth-shadow"
    style={{
      backgroundColor: "white",
      border: "0px",
      borderRadius: "10px",
      minWidth: "100px",
      textAlign: "left",
      padding: "10px",
      margin: "-20px"
    }}
  >
    {(record[`${xField} - Compared`] && record[`${xField} - Compared`] !== "-")
      ? <div>
        {useDateFormatter
          ? `${DateFormatter(record[xField])} vs ${DateFormatter(record[`${xField} - Compared`])} (X)`
          : `${record[xField]} - ${record[`${xField} - Compared`]} (X)`
        }
      </div>
      : record[xField] && <div>
        <span className="inter" style={{ fontSize: "14px" }}>
          {useDateFormatter ? DateFormatter(record[xField]) : record[xField]}
        </span>
        <br />
      </div>
    }
    {e.payload?.filter(row => row.compare !== true)?.map((row) => {
      const origin = Number(row.payload[row.dataKey] ?? record[row.dataKey]);
      const compare = row?.compare ? Number(record[row.unqName + row?.compare]) : null;
      const deg = (compare ? (((origin - compare) * 100) / compare).toFixed(2) : 0);
      const currency = currencyMapping?.get(row.name);
      const account = row.audiences?.[0]?.metaData;
      return (
        <div style={{ lineHeight: "15px", marginTop: "5px" }}>
          <span style={{ color: row.color, marginRight: "5px" }}>●</span>
          <span className="inter" style={{ fontSize: "12px", color: "#000" }}>
            {`${row.name} ${currency ? ("(" + currency + ")") : ""} : `}
          </span>
          <span className="inter" style={{ fontSize: "13px", color: row.color }}>
            {UnitDataFormatter(origin, row.unit, currency)}
            {row.percentage ? "(" + row.percentage.toFixed(1) + "%)" : ""}
            {row?.compare ? " vs " + (UnitDataFormatter(compare, row.unit, currency)) : ""}
          </span>
          <span className="inter" style={{
            fontSize: "12px",
            color: row.metricType === "POSITIVE" ? (deg > 0 ? "#35915E" : "#E91B16")         //"#83BF6E" : "#eb5b3c",
              : row.metricType === "NEGATIVE" ? (deg < 0 ? "#35915E" : "#E91B16") : "#7c7e8c"
          }}>
            {row?.compare ? " (" + (deg > 0 ? "↑" + deg : "↓" + deg * -1) + "%)" : ""}
          </span>
          <br />
          {row.filter && <>
            <span className="inter" style={{ fontSize: "10px", color: "#5C6674", marginLeft: "15px" }}>
              {"Filter : " + row.filter}
            </span> <br />
          </>}
          {account && <>
            <span className="inter" style={{ fontSize: "10px", color: "#5C6674", marginLeft: "15px" }}>
              {"Account : " + account.title + " (" + account.id + ")"}
            </span> <br />
          </>}
        </div>
      )
    })}
  </div>
);

export const TooltipContentType2 = (items, u, curr) => (
  <div
    className="smooth-shadow"
    style={{
      backgroundColor: "white",
      borderRadius: "10px",
      minWidth: "100px",
      textAlign: "left",
      padding: "10px",
      margin: "-15px -18px",
      display: "flex",
      flexDirection: 'column',
      border: "0px !important"
    }}
  >
    {
      items?.map((item) => {
        let unit = u ?? item?.data?.unit;
        let currency = curr ?? item?.data?.currency;
        const origin = Number(item.value);
        const compare = (item?.data?.compare || item?.data?.compare === 0) ? Number(item?.data?.compare) : null;
        const deg = (compare ? (((origin - compare) * 100) / compare).toFixed(2) : 0);
        const account = item?.data?.audiences?.[0]?.metaData;
        const { percentage } = item.data;
        return (
          <div style={{ lineHeight: "15px", padding: "3px 0px" }}>
            {item.data.metricName &&
              <div className="inter" style={{ fontSize: "14px", marginBottom: "5px" }}>
                {item.name} {(item.dimCompare && item.dimCompare !== "-") ? ` vs ${item.dimCompare} (X)` : ""}
              </div>
            }
            <span className="inter" style={{ fontSize: "12px", color: "#000" }}>
              {`${item.data.metricName ?? item.name} ${currency ? " (" + currency + ") " : ""}: `}
            </span>
            <span className="inter" style={{
              fontSize: "13px", color: item.color, marginLeft: "5px",
              // whiteSpace: "pre-line", wordWrap: "break-word", maxWidth: "200px"
            }}>
              {(unit && isFinite(item.value)) ? UnitDataFormatter(origin, typeof (unit) === "string" ? unit : unit[item.data.metricName ?? item.name], currency) : item.value}
              {(item?.data?.compare || item?.data?.compare === 0)
                ? " vs " + ((unit && isFinite(item?.data?.compare))
                  ? UnitDataFormatter(compare, typeof (unit) === "string" ? unit : unit[item.data.metricName ?? item.name], currency)
                  : item?.data?.compare)
                : ""}
              {(percentage && compare === null) && ` (${percentage.toFixed(1)}%)`}
            </span>
            <span className="inter" style={{
              fontSize: "12px",
              color: item?.data?.metricType === "POSITIVE" ? (deg > 0 ? "#35915E" : "#E91B16")
                : item?.data?.metricType === "NEGATIVE" ? (deg < 0 ? "#35915E" : "#E91B16") : "#7c7e8c"
            }}>
              {(item?.data?.compare || item?.data?.compare === 0) && (unit && isFinite(item?.data?.compare))
                ? " (" + (deg > 0 ? "↑" + deg : "↓" + deg * -1) + "%)"
                : ""}
            </span>
            <br />
            {item?.data?.filter && <>
              <span className="inter" style={{ fontSize: "10px", color: "#5C6674" }}>
                {"Filter : " + item?.data?.filter}
              </span> <br />
            </>}
            {account && <>
              <span className="inter" style={{ fontSize: "10px", color: "#5C6674" }}>
                {"Account : " + account.title + " (" + account.id + ")"}
              </span> <br />
            </>}
          </div>
        )
      })
    }
  </div >
);

export const Legend = (chartStyle) => {
  let legendStyle;
  switch (chartStyle.legendPosition) {
    case "left":
    case "right":
      legendStyle = { layout: "vertical", verticalAlign: "middle", align: chartStyle.legendPosition, maxHeight: undefined }
      break;
    case "top":
    case "bottom":
    default:
      legendStyle = { layout: "horizontal", verticalAlign: chartStyle.legendPosition, align: "center", maxHeight: (chartStyle.legendStyle.fontSize - 2) + 35 }
      break;
  }
  return ({
    alignColumns: false,
    ...legendStyle,
    itemStyle: {
      fontFamily: chartStyle.legendStyle.fontStyle,
      fontSize: chartStyle.legendStyle.fontSize - 2,
      width: "120px",
      textOverflow: "ellipsis"
    },
  })
}

// const Colors = ["#496DFF", "#5F27BA", "#21ADFF", "#8CD6A0"];
export const Palette = [
  ["#5296FC", "#7AE0EE", "#9E8FE9", "#FF878B", "#FAA3C8"],
  ["#28374B", "#4A5B7A", "#9EB9DA", "#EEC1C1", "#F1E5E5"],
  ["#9A6DA0", "#CF7370", "#66AFB1", "#DF9968", "#A3C049"],
  ["#153547", "#DBCDAF", "#E7DDCC", "#C8B486", "#B79F71"],
  ["#56626F", "#B9E5E1", "#EAFBC3", "#CBECBB", "#C3E0FB"],
  ["#86A6D4", "#DC8A87", "#E2615B", "#DF7E57", "#AFAEAB"],
  ["#D7B1F8", "#ABCCFE", "#F7B2D9", "#F8B3B1", "#FCD0AD"],
  // ["#496DFF", "#5F27BA", "#21ADFF", "#8CD6A0", "#69D2E7"],
  // ["#69D2E7", "#A7DBD8", "#E0E4CC", "#F38630", "#FA6900"],
  // ["#FE4365", "#FC9D9A", "#F9CDAD", "#C8C8A9", "#83AF9B"],
  // ["#ECD078", "#D95B43", "#C02942", "#542437", "#53777A"],
  // ["#556270", "#4ECDC4", "#C7F464", "#FF6B6B", "#C44D58"],
  // ["#774F38", "#E08E79", "#F1D4AF", "#ECE5CE", "#C5E0DC"],
  // ["#E8DDCB", "#CDB380", "#036564", "#033649", "#031634"],
];

export const TablePalette = [
  ["#0869FB", "#9798A1", "#F8F9FD", "#F2F2F4", "#FFFFFF"],
  ["#0869FB", "#333333", "#E2EEFF", "#F2F2F4", "#F9FBFF"],
  ["#5D3FC8", "#333333", "#F9F2FF", "#F2F2F4", "#FEFDFF"],
  ["#4C6779", "#333333", "#DFF0EC", "#F2F2F4", "#FBFFFE"],
  ["#62BFE7", "#333333", "#E7F3D8", "#F2F2F4", "#FDFFF5"],
  ["#6D8AFF", "#333333", "#C7D2FA", "#F2F2F4", "#FDFDFF"],
  ["#CD328A", "#333333", "#F6E2EE", "#F2F2F4", "#FFFBFD"]
];

export const CustomPalette = (type) => {
  switch (type) {
    case "TABLE":
      return ["#F8862D", "#333333", "#FFE5CD", "#F2F2F4", "#FFFCFA"];
    case "HEATMAP":
      return ["#F8862D", "#333333", "#FFE5CD", "#F2F2F4", "#FFFCFA"];
    default:
      return ["#71513D", "#EBCFC5", "#FFE5CD", '#D8EAE7', '#E6FFF3'];
    // return ['#3F979B', '#C0DBEA', '#F2BFC5', '#BA90C6', '#D6769F']
  }
}

export const GetColor = (index, selectedPallete = 0) => {
  if (Array.isArray(selectedPallete)) {
    return selectedPallete[index % selectedPallete.length];
  } else {
    return Palette[selectedPallete][index % Palette[selectedPallete].length];
  }
}

export const LegendPosition = ["bottom", "left", "right", "top"];

export const TextStyle = {
  FontStyle: ['Monospace', 'Times New Roman', 'Inter'],
  FontSize: [8, 10, 12, 14, 16, 18, 20, 24, 28, 32, 44, 56],
  FontColor: ["#21ADFF", "#21ADFF", "#8CD6A0", "#69D2E7", "#C7F464", "#FF6B6B", "#C44D58", "#FC9D9A", "#F9CDAD", "#C8C8A9", "#A7DBD8", "#E0E4CC"]
}
export const LegendStyle = {
  FontStyle: ['Monospace', 'Times New Roman', 'Inter'],
  FontSize: [8, 10, 12, 14, 16, 18, 20, 24, 28, 32, 44, 56],
}


export const checkDate = (dim) => {
  return dim && (["Date", "DATE"].includes(dim.dataType) || dim.group === "Time" || DATE_METRICS.includes(dim.name))
}

// const isDateAvailable = (dim) => {
//   var isDateType = 0;
//   dim.forEach((q) => {
//     isDateType |= checkDate(q)
//   })
//   return isDateType
// }

// const dateDimensionsIndex = (dim) => {
//   var dimInd = [];
//   dim.forEach((q, ind) => {
//     if (checkDate(q))
//       dimInd.push(ind)
//   })
//   return dimInd
// }

// const checkDimensions = (d, f, dim) => {
//   var sol = 1
//   dim.forEach((y) => {
//     if (d[y] !== f[y])
//       sol = 0;
//   })
//   return sol;
// }

// const checkDimensionsWithDate = (d, f, allDims, dateDimIndex, compareWith, chartDim) => {
//   var sol = 1
//   for (let [ind, y] of allDims.entries()) {
//     let currentDim = (y?.name === "Name" ? y?.group + " " + y?.name : y?.name) ?? y;
//     if (dateDimIndex === ind) {
//       if (moment(d[currentDim], "YYYY-MM-DD").subtract(1, compareWith).format("YYYY-MM-DD") !== f[currentDim])
//         sol = 0;
//     } else {
//       if (d[currentDim] !== f[currentDim])
//         sol = 0;
//     }
//     if (currentDim === chartDim) {
//       break;
//     }
//   }
//   return sol;
// }

// export const transFormComparisonDataTable = (source, compared, dime, compareWith) => {
//   if (compared?.data?.length > 0 && compared?.dimensions?.length > 0) {
//     if (compared?.dimensions?.length > 1) {
//       var isDateType = isDateAvailable(dime)
//       if (isDateType) {
//         const dateDimIndex = dateDimensionsIndex(dime)
//         const final = source
//         const dim = compared?.dimensions;
//         final.data.forEach((d, ind) => {
//           d.comp = {}
//           compared.data.forEach((f) => {
//             if (checkDimensionsWithDate(d, f, dim, dateDimIndex?.[0], compareWith)) {
//               source.leftMetrics.concat(source.rightMetrics ?? []).forEach((e, idx) => {
//                 const met = e.name;
//                 d.comp[met] = f[met];
//                 const metId = e.id;
//                 d.comp[metId] = f[metId];
//               })
//             }
//           })
//           final.data[ind] = d
//         })
//         return final;
//       }
//       else {
//         const final = source
//         const dim = compared?.dimensions;
//         final.data.forEach((d, ind) => {
//           d.comp = {}
//           compared.data.forEach((f) => {
//             if (checkDimensions(d, f, dim)) {
//               source.leftMetrics.concat(source.rightMetrics ?? []).forEach((e, idx) => {
//                 const met = e.name;
//                 d.comp[met] = f[met];
//                 const metId = e.id;
//                 d.comp[metId] = f[metId];
//               })
//             }
//           })
//           final.data[ind] = d
//         })
//         return final;
//       }
//     } else if (checkDate(dime[0])) {
//       const final = source
//       const dim = compared?.dimensions[0]?.name ?? compared?.dimensions[0];
//       if (dime.length > 1) {
//         const dateDimIndex = dateDimensionsIndex(dime)
//         const allDims = dime
//         final.data.forEach((d, ind) => {
//           d.comp = {}
//           compared.data.forEach((f) => {
//             if (checkDimensionsWithDate(d, f, allDims, dateDimIndex?.[0], compareWith, dim)) {
//               source.leftMetrics.concat(source.rightMetrics ?? []).forEach((e, idx) => {
//                 const met = e.name;
//                 d.comp[met] = f[met];
//                 const metId = e.id;
//                 d.comp[metId] = f[metId];
//               })
//             }
//           })
//           final.data[ind] = d
//         })
//       } else {

//         final.data.forEach((d, ind) => {
//           d.comp = {}
//           compared.data.forEach((f) => {
//             if (moment(d[dim], "YYYY-MM-DD").subtract(1, compareWith).format("YYYY-MM-DD") === f[dim]) {
//               source.leftMetrics.concat(source.rightMetrics ?? []).forEach((e, idx) => {
//                 const met = e.name;
//                 d.comp[met] = f[met];
//                 const metId = e.id;
//                 d.comp[metId] = f[metId];
//               })
//             }
//           })
//           final.data[ind] = d
//         })
//       }
//       return final;
//     } else {
//       const final = source
//       const dim = compared?.dimensions[0]?.name ?? compared?.dimensions[0]
//       final.data.forEach((d, ind) => {
//         d.comp = {}
//         compared.data.forEach((f) => {
//           if (f[dim] === d[dim]) {
//             source.leftMetrics.concat(source.rightMetrics ?? []).forEach((e, idx) => {
//               const met = e.name;
//               d.comp[met] = f[met];
//               const metId = e.id;
//               d.comp[metId] = f[metId];
//             })
//           }
//         })
//         final.data[ind] = d
//       })
//       return final;
//     }
//   } else {
//     const final = source
//     final.data.forEach((d, ind) => {
//       d.comp = {};
//       source.leftMetrics.concat(source.rightMetrics ?? []).forEach((e, idx) => {
//         const met = e.name;
//         const metId = e.id;
//         if (compared.dimensions?.length || compared.error) {
//           d.comp[met] = 0;
//           d.comp[metId] = 0;
//         } else if (compared?.data?.length) {
//           d.comp[met] = compared.data[ind][met];
//           d.comp[metId] = compared.data[ind][metId];
//         }
//       })
//       final.data[ind] = d
//     })
//     return final;
//   }
// }

// export const transFormComparisonData = (source, compared) => {

//   if (compared?.data?.length > 0) {
//     const sourceData = source.data.map((d, index) => {
//       let obj = {};

//       for (let key in d) {
//         obj[key] = d?.[key]
//         if (compared.dimensions?.includes(key) && compared.data?.[index]?.[key] === d?.[key]) {
//           compared.data?.splice(index, 0, 0);
//         } else {
//           obj[`X-${key}`] = compared.data?.[index]?.[key]
//         }
//       }
//       obj.type = 'compare';

//       return obj;
//     });

//     const leftMetrics = compared.leftMetrics.map(m => ({
//       ...m,
//       id: `X-${m.id}`,
//       name: `X-${m.name}`,
//       filter: m.filter
//     }));

//     const rightMetrics = compared.rightMetrics.map(m => ({
//       ...m,
//       id: `X-${m.id}`,
//       name: `X-${m.name}`,
//       filter: m.filter
//     }));

//     return {
//       sourceData,
//       leftMetrics,
//       rightMetrics
//     }
//   }

//   return {
//     sourceData: source.data,
//     leftMetrics: [],
//     rightMetrics: []
//   }

// }



export const Colors = ["#21ADFF", "#8CD6A0", "#69D2E7", "#C7F464", "#FF6B6B", "#C44D58", "#FC9D9A", "#F9CDAD", "#C8C8A9", "#A7DBD8", "#E0E4CC"]

export const displayCurrency = (type) => {
  return "CURRENCY";
  // return (type === ChannelType.FacebookAds || type === ChannelType.ShopifyAds) ? "PRICE" : "CURRENCY";
}

export const dataBlendSource = (type) => {
  const getType = type.match(/\(([^)]+)\)/);
  if (getType?.length === 2) {
    const updatedType = getType[1].toLowerCase().replaceAll(" ", "-");
    return updatedType;
  } else {
    return null;
  }
}

export const dataBlendDisplayCurrency = (type, unit, currency) => {
  const getType = type.match(/\(([^)]+)\)/);
  if (getType?.length === 2 && currency) {
    const updatedType = getType[1].toLowerCase().replace(" ", "-");
    if ((updatedType === ChannelType.FacebookAds || updatedType === ChannelType.ShopifyAds) && unit?.toUpperCase() === "PRICE") {
      return " (" + currency + ")";
    } else if ((updatedType !== ChannelType.FacebookAds && updatedType !== ChannelType.ShopifyAds) && unit?.toUpperCase() === "CURRENCY") {
      return " (" + currency + ")";
    } else if (unit?.toUpperCase() === "CURRENCY") {
      return " (" + currency + ")";
    } else {
      return "";
    }
  } else {
    return "";
  }
}

export const ChartFilterSupport = (chartType) => {
  return (
    chartType === "TABLE" || chartType === "COLUMN" || chartType === "BUBBLE" ||
    chartType === "SCATTER" || chartType === "WORDCLOUD" || chartType === "TREEMAP" || chartType === "HEATMAP" ||
    chartType === "WATERFALL" || chartType === "GEO"
  )
}

export const DimensionsLength = {
  COLUMN: 1,
  BUBBLE: 2,
  SCATTER: 2,
  WORDCLOUD: 1,
  TREEMAP: 3,
  HEATMAP: 2,
  WATERFALL: 1,
  GEO: 2
}

export const MetricsLength = {
  BUBBLE: 3,
  SCATTER: 2,
  WORDCLOUD: 1,
  TREEMAP: 1,
  HEATMAP: 1,
  WATERFALL: 1,
  GEO: 1
}

// export const mappedDataLevelTiktokAds = {
//   "Basic": [
//     { id: "AUCTION_ADVERTISER", name: "Auction Advertiser" }, { id: "AUCTION_CAMPAIGN", name: "Auction Campaign" },
//     { id: "AUCTION_ADGROUP", name: "Auction Adgroup" }, { id: "AUCTION_AD", name: "Auction Ad" },
//     { id: "RESERVATION_ADVERTISER", name: "Reservation Advertiser" }, { id: "RESERVATION_CAMPAIGN", name: "Reservation Campaign" },
//     { id: "RESERVATION_ADGROUP", name: "Reservation Adgroup" }, { id: "RESERVATION_AD", name: "Reservation Ad" }
//   ],
//   "Other": [
//     { id: "AUCTION_ADVERTISER", name: "Auction Advertiser" }, { id: "AUCTION_CAMPAIGN", name: "Auction Campaign" },
//     { id: "AUCTION_ADGROUP", name: "Auction Adgroup" }, { id: "AUCTION_AD", name: "Auction Ad" }
//   ]
// };

export const calComparedDate = (value, dateRange) => {
  if (!value) {
    return null;
  } else if (value === "Previous Period") {
    let diffTime = Math.floor(Math.abs((new Date(dateRange.endDate) - new Date(dateRange.startDate)) / (1000 * 60 * 60 * 24))) + 1;
    return {
      label: value,
      startDate: moment(dateRange.startDate, "YYYY-MM-DD").subtract(diffTime, "Days").format("YYYY-MM-DD"),
      endDate: moment(dateRange.endDate, "YYYY-MM-DD").subtract(diffTime, "Days").format("YYYY-MM-DD")
    }
  } else if (value.startsWith("Previous")) {
    const [_, num, period] = value.split(" ");
    return {
      label: value,
      startDate: moment(dateRange.startDate, "YYYY-MM-DD").subtract(num, period).format("YYYY-MM-DD"),
      endDate: moment(dateRange.endDate, "YYYY-MM-DD").subtract(num, period).format("YYYY-MM-DD")
    }
  } else if (value.indexOf("_") > -1) {
    const dates = value.split("_");
    return { startDate: dates[0], endDate: dates[1] }
  } else {
    return {
      label: `Previous 1 ${value}s`,
      startDate: moment(dateRange.startDate, "YYYY-MM-DD").subtract(1, value).format("YYYY-MM-DD"),
      endDate: moment(dateRange.endDate, "YYYY-MM-DD").subtract(1, value).format("YYYY-MM-DD")
    }
  }
}

export const StatusDimensionMap = {
  [ChannelType.LinkedInAds]: {
    accountName: "accountStatus",
    accountId: "accountStatus",
    campaignName: "campaignStatus",
    campaignId: "campaignStatus",
    campaignGroupName: "campaignGroupStatus",
    campaignGroupId: "campaignGroupStatus",
  },
  [ChannelType.FacebookAds]: {
    campaign_name: "campaign_effective_status",
    campaign_id: "campaign_effective_status",
    adset_name: "adset_effective_status",
    adset_id: "adset_effective_status",
    ad_name: "ad_effective_status",
    ad_id: "ad_effective_status",
  },
  [ChannelType.GoogleAds]: {
    'campaign.name': 'campaign.status',
    'ad_group.name': 'ad_group.status',
    'ad_group_ad.ad.name': 'ad_group_ad.status',
  }
}

export const IdDimensionMap = {
  campaignName: "campaignId",
  campaignGroupName: "campaignGroupId",
  campaign_name: "campaign_id",
  adset_name: "adset_id",
  ad_name: "ad_id",
  'campaign.name': 'campaign.id',
  'ad_group.name': 'ad_group.id',
  'ad_group_ad.ad.name': 'ad_group_ad.ad.id',
  'ai_group.name': 'ai_group.id',
  'aigroup_name': 'aigroup_id',
}

export const getMetrics = (metrics, chartMetrics) => {
  const tempMetrics = [];
  chartMetrics?.forEach((cm) => {
    if (cm?.id) {
      const cmId = cm?.id?.split("(");
      //blend metric
      if (cmId?.length > 1) {
        metrics?.findIndex(metric => metric?.id?.trim() == cmId[0]?.trim()) >= 0 && tempMetrics.push(cm);
      } else {
        metrics?.findIndex(metric => metric?.id?.trim() == cm?.id?.trim()) >= 0 && tempMetrics.push(cm);
      }
    }
  });
  return tempMetrics;
}

export const mappedAccountWithApiConfigKey = (accounts, apiConfigKeys) => {
  return accounts?.reduce((acc, account, index) => { acc[account] = apiConfigKeys[index]; return acc; }, {}) ?? {};
}

export const trendingChartUnqNameMultiAccount = (filters) => {
  return Object.keys(filters).join("^");
}

export const addFilterNameAggrMetrics = (aggregatedValues,metrics) => {
  const tempArr = [];
  let index = 0;
  metrics?.forEach((metric => {
    const endIndex = metric?.compare ? index + 4 : index + 1;
    for(let i = index; i < endIndex; i++){
      const aggr = aggregatedValues[i];
      const filterName = Object.keys(metric?.filters ?? {}).length > 0 ? trendingChartUnqNameMultiAccount(metric.filters) : metric?.filter?.trim() ?? "";
      const metricName = metric?.compare ? aggr.metric?.split("(")[0]?.trim() : aggr?.metric;
      const appendStr = filterName.length > 0 ? `~${filterName}` : "";
      tempArr.push({ ...aggr, ["metric"]: (metric?.compare && filterName) ? aggr.metric.replace(metricName,metricName + appendStr) : metricName + appendStr});
    }
    index = metric?.compare ? index + 4 : index + 1;
  }));
  return tempArr;
}
