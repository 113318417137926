import { Box, Button, MenuItem, Popover, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { transformFromFilterDataObject } from "../Filteration/Utils";
import { Filter } from "../../models/chart";
import { ReactComponent as AddIcon } from "../../Assets/Icons/plus_icon.svg";
import Drop from "../../Assets/Icons/new_down_arrow.svg";
import External from "../../Assets/Icons/export-icon.svg";
import "./editwidget.css"
import { ShareContext } from "./Sidebar";

const FilterDrop = ({ chart, channel, setCurrentFilter, updateFilter, setIsFilterOpen,selectedAccounts,chartFilters,allFilterList=[] }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const filterList = useSelector(state => state.filters.allFilters);
    allFilterList = allFilterList?.length > 0 ? allFilterList : filterList;
    const filterdList = React.useMemo(() => {
        if(channel?.isBlend) return allFilterList;
        return selectedAccounts?.reduce((acc, curr) => {
          if(chartFilters[curr.id]) return acc;
          const tempFilterList = allFilterList.filter((filter) => curr.id === filter?.audiences[0]?.id);
          if(tempFilterList.length > 0){
            const id = curr.id;
            acc[id] = tempFilterList;
          }
          return acc;
        },[]);
    },[allFilterList,chartFilters,selectedAccounts]);
    const { shared } = useContext(ShareContext);

    const editFilter = (filter) => {
        setCurrentFilter(transformFromFilterDataObject(filter));
        setIsFilterOpen(true);
        setAnchorEl(null);
    }

    const applyFilter = (filter,account) => {
        const currentFilter = transformFromFilterDataObject(filter);
        updateFilter(Filter.new(
            chart,
            currentFilter.name,
            currentFilter.segment,
            currentFilter.dimensionFilters,
            currentFilter.metricFilters,
            currentFilter.id,
            account
        ))
        setAnchorEl(null)
    }

    return (
        <div style={{ marginBottom: "5px" }}>
            <div className="filter-dropdown"
                onClick={(e) => (chart.account || channel.isBlend) && setAnchorEl(e.currentTarget)}
                style={{ opacity: (chart.account || channel.isBlend) ? 1 : 0.6 }}>
                <Typography style={{ fontSize: "12px", fontWeight: "500", lineHeight: "16px", color: "#585969" }}>
                    Add Filter
                </Typography>
                <img src={Drop} alt="" />
            </div>

            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'left', top: "8px" }}
                transformOrigin={{ vertical: "bottom", horizontal: "left" }}
                PaperProps={{
                    style: {
                        width: "323px", left: "772px", boxShadow: "0px 12px 40px -16px rgba(0, 0, 0, 0.05);",
                        border: "0.5px solid #E0E3E5", borderRadius: "6px",
                    },
                }}
                sx={{
                    "& .MuiMenuItem-root": {
                        display: "flex",
                        justifyContent: "space-between"
                    }
                }}
            >
                <div style={{ maxHeight: "200px", overflow: "auto", paddingTop: "12px" }}>
                    {channel?.isBlend ? allFilterList.map(appliedFilter => {
                        return (
                            <div style={{ display: "flex", flexDirection: "column", background: "#FFF" }}>
                                <MenuItem onClick={() => {
                                    applyFilter(appliedFilter)
                                }} > {appliedFilter.name}
                                    {!shared && <button className="menu_btn" onClick={(e) => {
                                        e.stopPropagation();
                                        editFilter(appliedFilter)
                                    }}>
                                        <img src={External} alt="" /></button>}
                                </MenuItem>
                            </div>
                        )
                    }) : Object.entries(filterdList ?? {}).map(([accountId,filters]) => {
                           const name = filters[0]?.audiences[0]?.metaData?.title  ?? filters[0]?.audiences[0]?.metaData?.name ?? accountId;                          ;
                           const audience = filters[0]?.audiences[0];
                           return  <>
                                    <Typography style={{ fontSize: "16px",lineHeight: "12px", color: "#AEB6C4",margin: "10px 10px",fontWeight: 500 }}>
                                        { name } 
                                    </Typography>
                                    {filters.map((appliedFilter, index) => {
                                            return  <div style={{ display: "flex", flexDirection: "column", background: "#FFF" }} key={index}>
                                                        <MenuItem
                                                    
                                                        onClick={() => {
                                                            applyFilter(appliedFilter,audience)
                                                        }} > <Typography 
                                                     
                                                        noWrap 
                                                sx={{
                                                    width:"300px",
                                                }}
                                                    
                                                        >{appliedFilter.name} </Typography>
                                                            {!shared && <button className="menu_btn" onClick={(e) => {
                                                                e.stopPropagation();
                                                                editFilter(appliedFilter,audience)
                                                            }}>
                                                                <img src={External} alt="" /></button>}
                                                        </MenuItem>
                                                    </div>})}
                                    </> })
                }
                </div>

                {!shared && <div style={{ padding: "12px 16px", borderTop: "1px solid #F4F4F8" }}>
                    <Button
                        sx={{
                            justifyContent: "center",
                            color: "#333333",
                            border: "none",
                            borderRadius: "8px",
                            height: "40px",
                            width: "100%",
                            padding: "12px",
                            marginTop: "12px 16px",
                            background: "#E3EEFF",
                            "&:hover": {
                                border: "none",
                                background: "#E3EEFF"

                            }
                        }}
                        fullWidth
                        variant="outlined"
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={() => {
                            setCurrentFilter();
                            setIsFilterOpen(true)
                            setAnchorEl(null)
                        }}
                    >
                        <Typography style={{ fontSize: "14px", fontWeight: "500", color: "#0869FB", }} >
                            Create New Filter
                        </Typography>
                    </Button>
                </div>}
            </Popover>
        </div>
    )
}

export default FilterDrop;