import "./body.css";
import "antd/dist/antd.min.css";
import React, { useEffect } from "react";
import theme from "./Theme";
import { ThemeProvider } from "@mui/material/styles";
import MainLoader from "./components/Common/MainLoader";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateLoader } from "./api/user/action";
import AppErrorHandler from "./components/AppErrorHandler";
import useHotjar from "./utils/useHotjar";
import "./api/index"

const App = ({ children }) => {
    const loading = useSelector(state => state.user.loading);
    const dispatch = useDispatch();
    const hotjar = useHotjar();

    useEffect(() => {
        if (loading) {
            document.body.style.overflow = 'hidden'
            window.scrollTo(0, 0);
            setTimeout(() => {
                if (loading) {
                    dispatch(updateLoader(false))
                }
            }, 180 * 1000)
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [loading])

    return (
        <ThemeProvider theme={theme}>
            {loading && <MainLoader />}
            <AppErrorHandler>
                {children}
            </AppErrorHandler>
        </ThemeProvider>
    );
}

export default App;
