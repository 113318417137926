import { channelType } from "./transformer";

const INITIAL_STATE = {
    clientId: "",
    accountData: {},
    dimensions: [],
    metrics: [],
    currency: null,
    metricsUpdated: false,
};


const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case `${channelType}.GET_CLIENT_ID_SUCCESS`:
            return { ...state, clientId: action.payload.clientId };
        case `${channelType}.GET_CLIENT_ID_FAILURE`:
            return { ...state, clientId: "" };
        case `${channelType}.GET_ACCOUNT_SUMMARY_SUCCESS`:
            const accountList = action.payload.allAccounts.map(data => ({
                title: data.adAccountName ?? data.adAccountId,
                id: data.adAccountId,
                accountId: data.adAccountId,
                users: data.users,
                channelType
            }))
            return { ...state, accountData: { users: action.payload.allUsers, accountList: accountList } };
        case `${channelType}.START_LOADING`:
            return { ...state, accountData: { ...state.accountData, loading: true } };
        case `${channelType}.DISCONNECT_ACCOUNT_SUCCESS`:
        case `${channelType}.GET_ACCOUNT_SUMMARY_FAILURE`:
            return { ...INITIAL_STATE, clientId: state.clientId };
        case `${channelType}.GET_COLUMNS_SUCCESS`:
            const { dimensions, metrics } = action.payload.columns.reduce((group, col) => {
                col.type === "DIMENSION" ? group.dimensions.push(col) : group.metrics.push(col)
                return group;
            }, { dimensions: [], metrics: [] })
            return { ...state, dimensions: dimensions, metrics: metrics, metricsUpdated: true };
        case `${channelType}.GET_CURRENCY_SUCCESS`:
            return { ...state, currency: action.payload.currency }
        default:
            return state;
    }
};

export default reducer;
