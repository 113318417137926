import { Box, Button, Checkbox, FormControlLabel, Modal, Popover, Typography, } from "@mui/material";
import "../../styles/filterpopup.scss";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Filter } from "../../models/chart";
import { useSelector, useDispatch } from "react-redux";
import { GetChannel } from "../../models/channel";
import CloseIcon from "../../Assets/Icons/close-icon.svg";
import SelectSourceModal from "./SelectSourceModal";
import FilterPopup from "./filterpopup/FilterPopup";
import Scrollbars from "react-custom-scrollbars";
import { transformFromFilterDataObject } from "./Utils";
import { deleteFilter, getAllFilters } from "../../api/filter/action";
import DataSourceAccord from "./DataSourceAccord";
import AddIcon from '@mui/icons-material/Add';
import { ReactComponent as FilterIcon } from "../../Assets/Icons/filterIcon.svg";
import { ReactComponent as CheckedIcon } from "../../Assets/Icons/checked_icon.svg";
import { ReactComponent as UncheckedIcon } from "../../Assets/Icons/unchecked_icon.svg";
import SearchBar from "../Common/SearchBar";
import { ShareContext } from "../EditWidget/Sidebar";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "55%",
    // maxHeight: "82vh",
    bgcolor: "background.paper",
    borderRadius: "12px",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
    padding: "24px 32px 24px 32px"
};


export default function FilterListModal({ modal, setModal, chart, report, chartFilter, onChange, handleAllChartsForceReload, blendChannelCols,selectedAccounts }) {
    const { shared } = useContext(ShareContext) || {};
    const allFilterList = useSelector(state => state.filters.allFilters);
    const [selectChannels, setSelectChannels] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [filteredList, setFilteredList] = useState(allFilterList ?? []);
    const groupedData = useMemo(() => {
        return (
            //condition for multi account needed to revisit this check
            (typeof(chart?.account) !== "string" && chart?.channelType && !Array.isArray(chart?.account)) && !chart?.isBlends ? filteredList.reduce((group, filter) => {
                let source = filter.dataSource?.id ?? filter.dataSource;
                let account = chart?.account?.[filter.audiences?.[0]?.id]
                  ? filter.audiences?.[0]?.id
                  : null;
                if (account) {
                    return ({
                        ...group,
                        [source]: { ...group[source], [account]: (group[source]?.[account] ?? []).concat(filter) }
                    })
                }
                return group;
            }, {}) : chart?.channelType ? {
                        [chart.channelType?.id ?? chart.channelType]: {
                            [chart.account]: filteredList.filter(filter => !chart?.table?.id || filter.reportType === chart.table.id + (chart?.dataLevel ? " - " + chart?.dataLevel : ""))
                    }
                }
            : filteredList.reduce((group, filter) => {
                let source = filter.dataSource?.id ?? filter.dataSource;
                let account = filter.audiences?.[0]?.id;
                if (!Object.values(selectChannels).includes(true) || selectChannels[source]) {
                    return ({
                        ...group,
                        [source]: { ...group[source], [account]: (group[source]?.[account] ?? []).concat(filter) }
                    })
                }
                return group;
            }, {}))

    }, [filteredList, selectChannels]);
    console.log("groupedDataXYZ",groupedData)
    const authenticatedAccounts = useSelector(state => state.channel.authenticatedAccounts);
    const [nextModalOpen, setNextModalOpen] = React.useState(false);
    const [currentFilter, setCurrentFilter] = React.useState(null);
    const dispatch = useDispatch();
    const _getAllFilters = (_) => dispatch(getAllFilters(_));
    const _deleteFilter = (_) => dispatch(deleteFilter(_));

    const [updateList, setUpdateList] = useState(0);
    useEffect(() => {
        _getAllFilters({
            dataSource: chart?.channelType?.id ? { id: chart.channelType.id, type: "BLEND" } : chart?.channelType,
            audiences: report ? report.adAccounts?.map(acc => ({ type: "AD_ACCOUNT", id: acc.id, dataSource: acc.channelType }))
                : chart?.account?.id
                    ? [{ type: "AD_ACCOUNT", id: chart.account.id, dataSource: chart.account.channelType }]
                    : undefined
        });
    }, [modal, updateList])

    const editFilter = (appliedFilter) => {
        setCurrentFilter(appliedFilter);
        setNextModalOpen(true)
    }
    
    const applyFilter = (filter) => {
        const currentFilter = transformFromFilterDataObject(filter);
        onChange(Filter.new(
            chart,
            currentFilter.name,
            currentFilter.segment,
            currentFilter.dimensionFilters,
            currentFilter.metricFilters,
            currentFilter.id,
            currentFilter?.account
        ));
    }

    const deleteSelectFilter = (appliedFilter) => {
        _deleteFilter({ filterId: appliedFilter.id });
        handleAllChartsForceReload(appliedFilter.id);
        if (chart && chartFilter.id === appliedFilter.id) {
            onChange(Filter.new(chart))
        }
    }

    return (
        <div>
            <Modal
                open={modal}
                onClose={() => setModal(!modal)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Box style={{ width: "100%", height: "40px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography style={{ fontFamily: "Inter", fontSize: "24px", fontWeight: "600", lineHeight: "32px" }}>Available Filters</Typography>
                        <button style={{ background: "none", border: "none", cursor: "pointer" }} onClick={() => setModal(!modal)}> <img src={CloseIcon} alt="" /> </button>
                    </Box>
                    <Box>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "32px 0px 24px 0px" }}>
                            <div className="displayRowSpaced">
                                <SearchBar filteredData={allFilterList} setFilteredData={setFilteredList} />
                                {!chart?.channelType &&
                                    <div className="pointer border1 displayRowSpaced"
                                        style={{ color: "#7D899C", height: "40px", padding: "10px", width: "90px" }}
                                        onClick={(e) => setAnchorEl(e.currentTarget)}
                                    >
                                        Platforms
                                    </div>
                                }
                                {!shared && <Button onClick={() => { setCurrentFilter(null); setNextModalOpen(true) }}
                                    style={{ minWidth: "40px", maxWidth: "40px", height: "40px", borderRadius: "8px" }}
                                    variant="contained" >
                                    <AddIcon fontSize="small" />
                                </Button>}
                            </div>
                            <Popover id='channel-filter-popover'
                                open={Boolean(anchorEl)}
                                anchorEl={anchorEl}
                                sx={{ marginTop: "10px" }}
                                onClose={() => setAnchorEl(null)}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                            >
                                <div style={{ padding: "12px" }}>
                                    <Typography style={{ color: "#AEB6C4", fontWeight: 600, padding: "0 9px" }}>Filter with data sources</Typography>
                                    {authenticatedAccounts.map(acc => {
                                        const channel = GetChannel(acc);
                                        return (<FormControlLabel
                                            control={<Checkbox checked={selectChannels[acc]}
                                                checkedIcon={<CheckedIcon />} icon={<UncheckedIcon />}
                                                onChange={(e) => setSelectChannels({ ...selectChannels, [acc]: e.target.checked })}
                                            />}
                                            label={channel.title}
                                            sx={{
                                                display: "block", padding: "6px 0", margin: 0, borderRadius: "8px",
                                                "&:hover": { background: "#E3EEFF" },
                                            }}
                                        />)
                                    })}
                                </div>
                            </Popover>
                        </div>
                    </Box>

                    <Box style={{ height: "60vh" }}>
                        <Scrollbars autoHide>
                            {
                                filteredList.length > 0 && Object.values(groupedData)
                                    .map(channelFilters => {
                                        let subGroup = Object.values(channelFilters);
                                        console.log("subGroup",subGroup)
                                        return (
                                            <DataSourceAccord subGroup={subGroup}
                                                chart={chart} editFilter={editFilter}
                                                applyFilter={applyFilter} deleteFilter={deleteSelectFilter}
                                            />
                                        )
                                    })
                            }
                        </Scrollbars>
                    </Box>
                </Box>
            </Modal >
            {console.log("currentFilterX",currentFilter,selectedAccounts)}
            {
                nextModalOpen &&
                (
                    (currentFilter || chart?.channelType)
                        ? <FilterPopup
                            chart={chart ?? {
                                channelType: currentFilter.dataSource, table: { id: currentFilter.reportType?.split(" - ")?.[0] },
                                dataLevel: currentFilter.reportType?.split(" - ")?.[1] ?? null,
                                reportFilter: true
                            }}
                            chartFilter={chartFilter}
                            onClose={() => setNextModalOpen(false)}
                            currentFilter={currentFilter
                                ? transformFromFilterDataObject(currentFilter)
                                : Filter.new(chart)
                            }
                            onChange={chart ?
                                ((filter, reload) => {
                                    onChange(filter);
                                    reload && handleAllChartsForceReload?.(filter.id);
                                }) :
                                ((filter) => {
                                    handleAllChartsForceReload(filter.id);
                                    setUpdateList(prev => prev + 1);
                                })
                            }
                            blendChannelCols={blendChannelCols}
                            accounts={selectedAccounts}
                        />
                        : <SelectSourceModal
                            modal={nextModalOpen}
                            setModal={setNextModalOpen}
                            onClose={() => setNextModalOpen(false)}
                            blendChannelCols={blendChannelCols}
                            setUpdateList={setUpdateList}
                            report={report}
                        />
                )
            }
        </div >
    );
};
