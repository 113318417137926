import React, { Suspense, forwardRef, lazy, useEffect, useImperativeHandle, useMemo, useState } from "react";
import IconButton from "@mui/material/IconButton";
import { Box, Button, Menu, MenuItem, TextareaAutosize, Tooltip } from "@mui/material";
import Alert from "@mui/material/Alert";
import SixDots from "../../Assets/Images/logos_sixdots.svg";
import GraphLogo from "../../Assets/Images/logos_graph.svg";
import ThreeDots from "../../Assets/Images/logos_threedots.svg";
import TableLogo from "../../Assets/Images/logos_table.svg";
import DrilledTableLogo from "../../Assets/Images/logos_drilled_table.svg";
import AddChartLogo from "../../Assets/Images/logos_add_chart.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/Images/Delete.svg";
import { ReactComponent as FilterIcon } from "../../Assets/Icons/filterIcon.svg";
import { ReactComponent as NoAccountIcon } from "../../Assets/errorMsgImages/NoAccount.svg";
import { ReactComponent as SelectViewIdIcon } from "../../Assets/errorMsgImages/SelectViewId.svg";
import { ReactComponent as DefaultErrorIcon } from "../../Assets/errorMsgImages/DefaultError.svg";
import { ReactComponent as NoDataIcon } from "../../Assets/errorMsgImages/NoData.svg";
import { ReactComponent as SelectionErrorIcon } from "../../Assets/errorMsgImages/Selection.svg";
import { ReactComponent as MappingDeletedIcon } from "../../Assets/errorMsgImages/MappingDeleted.svg";
import { ReactComponent as TypeMissingIcon } from "../../Assets/errorMsgImages/TypeMissing.svg";
import { ReactComponent as AccountExpiredIcon } from "../../Assets/errorMsgImages/AccountExpired.svg";
import { ReactComponent as RedirectIcon } from "../../Assets/Icons/openFilter.svg";
import ChartLoader from "../Common/Loader";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import Vector from "../../Assets/Icons/Vector.svg";
import { getAccountBasedColumns, getWidgetData } from "../../api/channel/saga";
import { getBlendColumns, getBlendWidgetData } from "../../api/blends/saga";
import { useSelector, useDispatch } from "react-redux";
import { Chart, Filter } from "../../models/chart";
import { ChannelType, GetChannel } from "../../models/channel";
import { updateChart } from "../../api/report/action";
import { useHistory, useParams } from "react-router";
import { DateDisplayFormatter, dataBlendSource, ChartFilterSupport, GetColor, calComparedDate, StatusDimensionMap, IdDimensionMap, getMetrics, mappedAccountWithApiConfigKey, addFilterNameAggrMetrics } from "./ChartUtils";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as htmlToImage from "html-to-image";
import { getFilterDetails, getFilterObject } from "../Filteration/Utils";
import { Errorhandler } from "../ErrorHanlder/ErrorHandler";
import "../styles/charts.css";
import { buttonClickSaveAndUpdate } from "../../api/channel/action";
import { updateLoader } from "../../api/user/action";
import { ReactComponent as EditIcon } from "../../Assets/Icons/edit-icon.svg";
import { ReactComponent as ExportIcon } from "../../Assets/Icons/export-icon.svg";
import { ReactComponent as DeleteNewIcon } from "../../Assets/Icons/delete-icon.svg";
import DataBlendIcon from "../../Assets/Icons/DataBlend.svg";
import TreemapChartWrapper from "./TreeMapChartWrapper";
import { handleMixPanelData, EVENTS } from "../../utils/mixpanelV2";
import timeoutError from "../../Assets/Icons/timeout.svg";
import SearchToggle from "../Common/SearchToggle";
import { reportingCalendarUIWrapper } from "../../utils/calendarUtils";
import deletedColumnIcon from "../../Assets/Icons/deletdColumnIcon.svg";
import { filterdAccount } from "../EditWidget/util";

const TrendingCharts = lazy(() => import("./TrendingCharts"));
const TrendingCharts3D = lazy(() => import("./TrendingCharts3D"));
const GrouppingCharts = lazy(() => import("./GroupingCharts"));
const KPIChart = lazy(() => import("./KPIChart"));
const DrillDownTable = lazy(() => import("./DrillDownTable"));
const BubbleChart = lazy(() => import("./BubbleChart"));
const ScatterChart = lazy(() => import("./ScatterChart"));
const TreemapChart = lazy(() => import("./TreemapChart"));
const HeatmapChart = lazy(() => import("./HeatMap"));
const WaterfallChart = lazy(() => import("./WaterfallCharts"));
const WordCloudChart = lazy(() => import("./WordcloudChart"));
const Geochart = lazy(() => import("./GeoChart"));

const ChartWrapper = forwardRef((props, ref) => {
  const { chart: initialChart, handleToggleEditDrawer, closeEditDrawer, removeChart, report, reportAdAccs, dateFrequency, gridId, shared } = props;
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const _updateChart = (_) => dispatch(updateChart(_));
  // const { channel: reportId, tab: tabPath } = useParams();
  const query = new URLSearchParams(window.location.search);
  const reportId = query.get('report_id'); // this is id in report, not report_id
  const reportName = query.get('report_name');
  const tabId = query.get('tab_id');
  const tabName = query.get('tab_name');
  const [chart, setChart] = React.useState(initialChart);
  const [chartCopy, setChartCopy] = React.useState(chart);
  const [channel, setChannel] = React.useState(GetChannel(chart.channelType) ?? {});
  const [channelCols, setChannelCols] = React.useState(null);
  const wholeState = useSelector((state) => state);
  const [account, setAccount] = React.useState(reportAdAccs.find(acc => acc?.id === chart?.accounts?.[0]));
  const [account_R, setAccount_R] = React.useState({});
  const [accountCols, setAccountCols] = React.useState(Object.values(account_R)?.reduce((cc, account) => {
    cc[account.id] = wholeState.report.accountCols[account?.title]
    return cc;
  }, {}));
  const [updateDataCount, setUpdateDataCount] = React.useState(0);
  const [dateRange, setDateRange] = useState(null);
  const [compareDateRange, setCompareDateRange] = useState({})
  const authenticatedAccounts = useSelector((state) => state.channel.authenticatedAccounts);
  const selectedAccounts = useSelector((state) => state.channel.selectedAccounts);
  // const dateRange = useSelector((state) => state.dashboard.dateRange);
  // const compareDateRange = useSelector((state) => state.dashboard.compareDateRange);
  const state = useSelector((state) => channel?.getState?.(state)) ?? {};
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [title, setTitle] = React.useState(chart.title);
  const [chartStyle, setChartStyle] = React.useState(chart.chartStyle);
  const [isTable, setIsTable] = React.useState(false);
  const [isDrillTable, setIsDrillTable] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isCompareLoading, setIsCompareLoading] = React.useState(false);
  const [drillData, setDrillData] = React.useState({ drillIndex: 1, selected: [], });
  const [data, setData] = React.useState({ dimension: "", metrics: [], data: [], });
  const [filterText, setFilterText] = React.useState("");
  const [filterKey, setFilterKey] = React.useState("");
  // const [comparedData, setComparedData] = React.useState({
  //   dimension: [] || "",
  //   leftMetrics: [],
  //   rightMetrics: [],
  //   data: [],
  // });
  const [textFocus, setTextFocus] = React.useState(false);
  const [currentChartType, setCurrentChartType] = React.useState(chart.type);
  const [isAddChart, setIsAddChart] = React.useState(chart.type === "ADD");
  const [editChart, setEditChart] = React.useState(false);
  const [exportData, setExportData] = React.useState(false);
  const [getData, setGetData] = React.useState(true);
  const history = useHistory();
  const [table, setTable] = React.useState(chart.table);
  const [dataLevel, setDataLevel] = React.useState({
    id: chart.dataLevel,
    name: chart.dataLevel,
  });
  const baseMetrics = (channel?.isBlend
    ? channelCols?.metrics
    : Array.isArray(state.metrics)
      ? state.metrics
      : state.metrics?.[table?.id + (dataLevel?.id ? `:${dataLevel.id}` : "")])
    ?? [];
  const customMetrics = accountCols
    ? Object.values(accountCols).flatMap(acc => acc?.metrics)
    : Array.isArray(state.customMetrics)
      ? state.customMetrics
      : state.customMetrics?.[
      table?.id + (dataLevel?.id ? `:${dataLevel.id}` : "")
      ] ?? [];
  const metrics = baseMetrics.concat(customMetrics);
  const [isAnyMetricsDeleted, setIsAnymetricsDeleted] = useState(false);

  React.useEffect(() => {
    if (!chart.isEqual(initialChart)) {
      setChart(initialChart);
      setChannel(GetChannel(initialChart.channelType) ?? {});
      setAccount(reportAdAccs.find(acc => acc?.id === initialChart?.accounts?.[0]));
      setTitle(initialChart.title);
      setChartStyle(initialChart.chartStyle);
      updateChartFilters();
    }
  }, []);

  useEffect(() => {
    setAccount_R(reportAdAccs.reduce((accsObj, acc) => {
      const isThere = (chart?.accounts ?? [])?.find((chartAccount) => chartAccount === acc?.id)
      if (isThere) {
        accsObj[acc.id] = {
          ...acc,
          ["currency"]: wholeState.report.accountCols?.[acc?.title]?.currency
        };
      }
      return accsObj;
    }, {}))
  }, [wholeState.report.accountCols]);
  // useEffect(() => {
  //   setAccount(reportAdAccs?.find(acc => acc?.id === chart?.accounts?.[0]));
  // }, [initialChart?.accounts]);

  useEffect(() => {
    if (dateFrequency && dateFrequency.length) {
      const dateRanges = reportingCalendarUIWrapper(dateFrequency);
      setDateRange(dateRanges?.[0] ?? {});
      setCompareDateRange(dateRanges?.[1] ?? {});
    }
  }, [dateFrequency])

  React.useEffect(() => {
    if (
      chart.gridPosition.x !== initialChart.gridPosition.x || chart.gridPosition.y !== initialChart.gridPosition.y ||
      chart.gridPosition.w !== initialChart.gridPosition.w || chart.gridPosition.h !== initialChart.gridPosition.h
    ) {
      chart.gridPosition = initialChart.gridPosition;
    }
  }, [initialChart.gridPosition])

  React.useEffect(() => {
    if (!channel.isBlend && account_R && Object.keys(account_R).length) {
      // setAccountCols(wholeState.report.accountCols[account.title])
      setAccountCols(Object.values(account_R).reduce((cc, account) => {
        cc[account.id] = wholeState.report.accountCols[account?.title]
        return cc;
      }, {}))
      // getAccountBasedColumns(account, channel)
      //   .then((data) => {
      //     let columns = data?.columns ?? [];
      //     setAccountCols(columns.reduce((group, col) => {
      //       col.type === "DIMENSION" ? group.dimensions.push(col) : group.metrics.push(col)
      //       return group;
      //     }, { dimensions: [], metrics: [] }));
      //   })
      //   .catch((err) => {
      //     setAccountCols({ metrics: [], dimensions: [] });
      //   });
    } else {
      setAccountCols(null);
    }
  }, [account_R, wholeState.report.accountCols]);

  React.useEffect(() => {
    if (channel.isBlend) {
      getBlendColumns(channel.type)
        .then((data) => {
          let columns = (data?.columns ?? []).reduce((columns, column) => ({
            ...columns,
            [column.type.toLowerCase() + 's']: (columns[column.type.toLowerCase() + 's'] ?? [])
              .concat({
                ...column, name: column.uiName ?? column.name,
                icon: GetChannel(column.dataSource)?.icon ?? DataBlendIcon
              })
          }), { dimensions: [], metrics: wholeState.blends.blendCustomCols[channel.type] ?? [] });
          setChannelCols(columns);
        })
        .catch((err) => {
          setChannelCols({ metrics: [], dimensions: [] });
        });
    } else {
      setChannelCols({ metrics: [], dimensions: [] });
    }
  }, [channel]);

  const [reloadCount, setReloadCount] = React.useState(0);

  const [initialUpdate, setInitialUpdate] = React.useState(true);

  React.useEffect(() => {
    if (!initialUpdate) {
      if (
        ((channel.type === (chart.channelType?.id ?? chart.channelType) || chart.type === "TEXT") &&
          currentChartType === chart.type)
      ) {
        updateData();
      }
    } else {
      setInitialUpdate(false);
    }
  }, [
    state.metricsUpdated,
    compareDateRange,
    chart,
    reloadCount,
    drillData.drillIndex,
    isDrillTable,
  ]);

  React.useEffect(() => {
    setChartCopy(Chart.fromJSON(chart.toJSON()));
  }, [chart]);

  // React.useEffect(() => {
  //   if (
  //     chart.table &&
  //     state.metricsUpdated &&
  //     state.tablesUpdated &&
  //     !state.tablesUpdated[chart.table?.id]
  //   ) {
  //     dispatch(channel?.actions?.getColumns(chart.table));
  //   }
  // }, [chart.table]);

  React.useEffect(() => {
    if (
      getData &&
      (channel.type === (chart.channelType?.id ?? chart.channelType) || chart.type === "TEXT") &&
      currentChartType === chart.type
    ) {
      updateChartFilters();
    }
  }, [updateDataCount]);

  React.useEffect(() => {
    setDrillData({ drillIndex: 1, selected: [] });
  }, [isDrillTable]);

  const forceUpdate = () => {
    setDrillData({ drillIndex: 1, selected: [] });
    setIsAddChart(false);
    setUpdateDataCount((prev) => prev + 1);
  };

  const insertInNamingMap = (namingMap, metric) => {
    if (!metric) {
      return;
    }
    const key = metric.dataSource ?? chart.channelType;
    if (!namingMap.get(key)) {
      namingMap.set(key, new Map());
    }
    namingMap.get(key).set(metric.id, metric.name);
  };

  const updateFilter = (filter) => {
    if (chart?.isBlends) {
      filter = filter[0];
      handleChartChange(
        "filter",
        !filter || filter.error
          ? Filter.new({ channelType: chart.channelType, account: chart.account })
          : filter,
        true
      );
    } else {
      handleChartChange(
        "filters",
        filter?.reduce((acc, curr) => { acc[curr?.account?.id] = curr; return acc; }, {}),
        true
      );
    }
  };

  const updateMetricFilter = (filters) => {
    let updateChart = false;
    const leftMetrics = chart.leftMetrics.map((metric) => {
      const filterIds = Object.values(metric?.filter?.ids ?? []);
      const filter = chart?.isBlends ? metric.filter.id && filters.find((filter) => filter.id === metric.filter.id) : filters.filter((f) => filterIds.includes(f.id));
      if (!chart?.isBlends && filter?.length > 0) {
        metric.filter = filter[0]; //testing
        metric.filters = filter.reduce((acc, curr) => {
          acc[curr.account.id] = curr;
          return acc;
        }, {});
        // updateChart = true;
      } else if (chart?.isBlends && filter) {
        metric.filter = filter;
      } else {
        if (!updateChart && metric.filter.id) {
          updateChart = true;
        }
        metric.filter = Filter.new({ channelType: chart.channelType, account: chart.account });
      }
      return metric;
    });

    const rightMetrics = chart.rightMetrics?.map((metric) => {
      const filterIds = Object.values(metric?.filter?.ids ?? []);
      const filter = chart?.isBlends ? metric.filter.id && filters.find((filter) => filter.id === metric.filter.id) : filters.filter((f) => filterIds.includes(f.id));

      if (!chart?.isBlends && filter?.length > 0) {
        metric.filter = filter[0]; //testing
        metric.filters = filter.reduce((acc, curr) => {
          acc[curr.account.id] = curr;
          return acc;
        }, {});
        // updateChart = true;
      } else if (chart?.isBlends && filter && filter.id && !filter.error) {
        metric.filter = filter;
      } else {
        if (!updateChart && metric.filter.id) {
          updateChart = true;
        }
        metric.filter = Filter.new({ channelType: chart.channelType, account: chart.account });
      }
      return metric;
    });
    chart.leftMetrics = leftMetrics.filter((metric) => metric && metric.metric);
    chart.rightMetrics = rightMetrics?.filter((metric) => metric && metric.metric);

    const chartObj = chart.toJSON();
    setChart(Chart.fromJSON(chartObj));
    if (updateChart) {
      const paramKeys = ["leftMetrics", "rightMetrics"];
      const chartUpdateObj = chart.toJSONfiltered();
      _updateChart({
        report_id: reportId,
        tab_id: tabId,
        grid_id: gridId,
        updates: [
          {
            chart_id: chart.chartId,
            id: chart.id,
            update_type: "update",
            params: paramKeys
              .map((param) => ({ [param]: chartUpdateObj[param] ?? null }))
              .reduce((obj, curr) => ({ ...obj, ...curr }), {}),
          },
        ],
      });
    }
  };

  const updateChartFilters = () => {
    if (ChartFilterSupport(currentChartType)) {
      const filterIds = chart?.filter?.ids ? Object.values((chart?.filter?.ids ?? {})) : null;
      if (chart?.filter?.id || filterIds?.length > 0) {
        getFilterDetails(filterIds?.length > 0 ? filterIds : [chart.filter.id], channel.type, updateFilter);
      } else {
        updateData();
      }
    } else {
      const combinedMetrics = [
        ...chart.leftMetrics,
        ...(chart.rightMetrics ?? []),
      ].filter(m => metrics?.findIndex(metric => metric?.id === m?.metric?.id) >= 0);

      const allFilters = chart?.isBlends ? combinedMetrics.flatMap((metric) => metric.filter.id ?? []) : combinedMetrics.flatMap((metric) => Object.values(metric?.filter?.ids ?? []));

      if (chart?.filter?.id && chart?.isBlends) {
        getFilterDetails([chart?.filter?.id], channel.type, updateFilter);
      } else if (allFilters.length) {
        getFilterDetails(allFilters, channel.type, (filters) =>
          updateMetricFilter(filters)
        );
      } else {
        updateData();
      }
    }
  };

  const updateDrillTableData = (
    startDate,
    endDate,
    callback,
    callbackData,
    compare
  ) => {
    compare ? setIsCompareLoading(true) : setIsLoading(true);
    const combinedMetrics = [
      ...chart.leftMetrics,
      ...(chart.rightMetrics ?? []),
    ].filter(m => metrics?.findIndex(metric => metric?.id === m?.metric?.id) >= 0);;

    const combinedMetricsTemp = [...chart.leftMetrics, ...(chart.rightMetrics ?? [])];
    if (combinedMetrics.length === 0 || combinedMetricsTemp.length === 0) {
      callback({ ...callbackData, error: combinedMetricsTemp.length === 0 ? "No metrics are selected" : "Some columns used in the chart seems to be deleted, please remove them to populate the chart!" });
      compare ? setIsCompareLoading(false) : setIsLoading(false);
      return;
    }
    combinedMetricsTemp.length > combinedMetrics.length ? setIsAnymetricsDeleted(true) : setIsAnymetricsDeleted(false);
    const unitKey = channel.properties.unitKey ?? "dataType";
    // const namingMap = new Map();
    // for (const metric of combinedMetrics) {
    //   insertInNamingMap(namingMap, metric?.metric);
    // }
    // chart.dimensions?.forEach((dimension) =>
    //   insertInNamingMap(namingMap, dimension)
    // );


    const compareWithDateRange = chart.compareWith
      ? calComparedDate(chart.compareWith, dateRange)
      : compareDateRange.label ? compareDateRange : null;

    var promise;
    if (channel.isBlend) {
      promise = getBlendWidgetData(channel.type, [
        {
          startDate: startDate,
          endDate: endDate,
          compareWithDateRange: compareWithDateRange ? { ...compareWithDateRange, alignDates: true, } : null,
          ...channel.transformer.getApiObject(
            combinedMetrics
              .map((metric) => metric.metric)
              ?.filter((metric) => metric?.id),
            chart.dimensions
              ?.filter((dim) => dim?.id)
              .slice(0, drillData.drillIndex),
            null,
            chart.table
          ),
          ...getFilterObject(
            {
              ...chart.filter,
              dimensionFilters: [
                ...drillData.selected.flatMap((sel) => ([{
                  boolOperator: "AND",
                  filter: sel.dimKey,
                  operator: { type: "EQUALS" },
                  value: [sel.value],
                  removeCompareRange: !!sel.compareVal
                }].concat(sel.compareVal ? [{
                  boolOperator: "AND",
                  filter: sel.dimKey,
                  operator: { type: "EQUALS" },
                  value: [sel.compareVal],
                  removeReportRange: true
                }] : []))),
                ...chart.filter.dimensionFilters,
              ],
            },
            compare
          ),
        }],
        chart.dimensions
          .slice(0, drillData.drillIndex)
          .map((dimension) => ({
            fieldName: dimension.id,
            dataSource: dimension.dataSource,
            accountId: dimension.audiences?.[0]?.id,
            sortOrder: "ASC",
          })),
      );
    } else {
      //without blend api call
      const filters = Object.keys(chart?.filters ?? {}).length > 0 ? chart?.filters : {};
      promise = getWidgetData(
        `${user.org?.name || user.company_name || user.org?.id}|${report || reportId
        }|${tabName}|${chart.title || chart.id}`,
        [
          {
            startDate: startDate,
            endDate: endDate,
            compareWithDateRange: compareWithDateRange ? { ...compareWithDateRange, alignDates: true, } : null,
            ...channel.transformer.getAccountId(
              account_R ?? selectedAccounts[channel.type],
              true
            ),
            ...channel.transformer.getApiObject(
              combinedMetrics.map((metric) => metric.metric.id),
              chart.dimensions.map((dimension) => [dimension?.id]
                .concat(StatusDimensionMap?.[channel.type]?.[dimension?.id] ?? [])
                .concat(IdDimensionMap[dimension?.id] ?? [])
              ).slice(0, drillData.drillIndex).flatMap(d => d),
              Filter.new({ channelType: channel.type, account: chart.account }),
              chart.table,
              chart.dataLevel,
              chart.excludeDeleted
            ),
            "filters": {
              ...Object.entries((filters)).reduce((acc, curr) => {
                const [accountId, filter] = curr;
                acc[accountId] = {
                  ...getFilterObject(
                    {
                      ...filter,
                      dimensionFilters: [
                        ...drillData.selected.flatMap((sel) => ([{
                          boolOperator: "AND",
                          filter: sel.dimIdKey || sel.dimKey,
                          operator: { type: "EQUALS" },
                          value: [sel.idValue ?? sel.value],
                          removeCompareRange: !!sel.compareVal
                        }].concat(sel.compareVal ? [{
                          boolOperator: "AND",
                          filter: sel.dimKey,
                          operator: { type: "EQUALS" },
                          value: [sel.compareVal],
                          removeReportRange: true
                        }] : []))),
                        ...filter.dimensionFilters,
                      ],
                    },
                    compare
                  )
                }
                return acc;
              }, {})
            },
            orderBys: chart.dimensions
              .slice(0, drillData.drillIndex)
              .map((dimension) => ({
                fieldName: dimension.id,
                sortOrder: "ASC",
              })),
            dataSource: channel.type,
          },
        ]
      );
    }
    promise
      .then((data) => {
        const initialDateRange = " (" + startDate + " - " + endDate + ")";
        const compareDateRange = " (" + compareWithDateRange?.startDate + " - " + compareWithDateRange?.endDate + ")";
        const segmentIndex = data[0].columns.findIndex(
          (c) => c === "ga:segment"
        );
        const dataDimensions = chart.dimensions
          .map((dim) => ({
            ...dim,
            // id: dim.id + (dim?.audiences?.[0] ? ` (${dim?.audiences?.[0]?.id})` : ""),
            unqName: dim.name + (dim?.audiences?.[0] ? ` (${dim?.audiences?.[0]?.id})` : "")
          }))
          .slice(drillData.drillIndex - 1, drillData.drillIndex);
        let metricIndex = [];
        for (let metric of combinedMetrics) {
          metricIndex.push(
            data[0]?.columns.findIndex(
              (c, ci) =>
                (c === metric.metric.id || c === (metric.metric.id + initialDateRange)) &&
                data[0].dataSources?.[ci] === metric.metric.dataSource &&
                (!metric.metric.audiences?.[0]?.id || data[0].adAccounts?.[ci] === metric.metric.audiences?.[0]?.id)
            )
          );
        }
        const mappedData = data[0].values.map((value) => {
          const entry = new Map();
          data[0].columns.forEach((column, index) => {
            let dimCompare = false;
            let dimIndex = chart.dimensions.findIndex((d) => {
              if ((d.id + compareDateRange) === column) {
                dimCompare = true;
                return true;
              }
              return (d.id === column || (d.id + initialDateRange) === column) &&
                (!d.audiences?.[0]?.id || data[0].adAccounts?.[index] === d.audiences?.[0]?.id)
            });
            let metric = dimIndex === -1 && combinedMetrics[metricIndex.findIndex(m => m === index)]?.metric
            let dimension = dimIndex !== -1 && chart.dimensions[dimIndex];
            const displayName = dimIndex !== -1 ? dimension?.name + (dimension?.audiences?.[0] ? ` (${dimension?.audiences?.[0]?.id})` : "")
              : metric?.name + (metric?.audiences?.[0] ? ` (${metric?.audiences?.[0]?.id})` : "");

            // const displayName = namingMap
            //   .get(data[0].dataSources?.[index] ?? chart.channelType)
            //   .get(column);
            if (Object.values(StatusDimensionMap?.[chart.channelType] ?? {}).includes(column) || Object.values(IdDimensionMap).includes(column)) {
              entry.set(column, value[index]);
            }

            if (displayName === "Name") {
              const columnName = column
                .replaceAll("_", " ")
                .replaceAll(".", " ")
                .split(" ")
                .map((name) => name[0].toUpperCase() + name.slice(1))
                .join(" ");
              if (dimIndex !== -1) {
                const newDimIndex = dataDimensions.findIndex((d) => {
                  if ((d.id + compareDateRange) === column) {
                    dimCompare = true;
                    return true;
                  }
                  return (d.id === column || (d.id + initialDateRange) === column)
                });
                dataDimensions[newDimIndex].name = columnName;

              }
              entry.set(columnName + (dimCompare ? " - Compared" : ""), value[index]);
              if (compareWithDateRange && dimIndex === -1) {
                entry.set(columnName + " - Compared", value[index + 1]);
                entry.set(columnName + " - Change", value[index + 2]);
                entry.set(columnName + " - Change(%)", value[index + 3]);
              }
            } else if (index !== segmentIndex) {
              entry.set(displayName + (dimCompare ? " - Compared" : ""), value[index]);
              if (compareWithDateRange && dimIndex === -1) {
                entry.set(displayName + " - Compared", value[index + 1]);
                entry.set(displayName + " - Change", value[index + 2]);
                entry.set(displayName + " - Change(%)", value[index + 3]);
              }
            }
          });
          chart.dimensions.forEach((d) => {
            if (!data[0].columns.includes(d.id)) {
              let index = data[0].columns.findIndex(
                (c) => c === d.miscInfo?.base_dimension?.id
              );
              if (index !== -1) entry.set(d.name, value[index]);
            }
          });
          return Object.fromEntries(entry.entries());
        });
        const currency = account_R?.[account?.[0] ?? account?.id]?.currency ?? "";
        const dataObject = {
          ...callbackData,
          error: "",
          dimensions: dataDimensions,
          leftMetrics: combinedMetrics.map((metric) => ({
            id: metric.metric.id, //+ (metric.metric.audiences?.[0] ? ` (${metric.metric.audiences?.[0]?.id})` : ""),
            unqName: metric.metric.name + (metric.metric.audiences?.[0] ? ` (${metric.metric.audiences?.[0]?.id})` : ""),
            audiences: metric.metric.audiences,
            account: metric.metric.account,
            name: metric.metric.name,
            unit: metric.metric[unitKey],
            metricType: metric.metric.metricType,
            compare: compareWithDateRange ? " - Compared" : null,
            currency: !channel?.isBlend ? currency : (account
              ? (account?.currency ?? accountCols.currency)
              : state.currency
                ? state.currency
                : dataBlendSource(metric.metric.name)
                  ? wholeState[dataBlendSource(metric.metric.name)]?.currency
                  : undefined),
          })),
          data: mappedData,
          aggregatedValues: data[0]?.aggregatedValues ?? [],
          mappedAccountIdApiConfigKey: mappedAccountWithApiConfigKey(data[0]?.adAccounts ?? [], data[0]?.apiConfigKeys)
        };
        channel.transformer.transformData?.(dataObject);
        callback(dataObject);
      })
      .catch((err) => {
        let errorStatus = err?.data?.responses?.error?.status;
        let error = err?.data?.error ?? err?.data?.responses?.error;
        if (error?.additionalInfo?.detail?.includes?.("Invalid column")) {
          callback({ error: "Some columns used in the chart seems to be deleted, please remove them to populate the chart!" });
        } else if ([400, 422].includes(err.status) || errorStatus === 400 || errorStatus === 422) {
          if (error.additionalInfo.detail?.[0]?.msg?.includes("Both date ranges cannot be same")) {
            callback({ error: "Both Reporting date-range and Comparison date-range cannot be same." })
          } else if (error?.additionalInfo?.detail === "Selected fields are not campatible.") {
            callback({ error: "Selected fields are not campatible." })
          } else {
            callback({ error: "Invalid query" });
          }
        } else if (err.status === 500) {
          if (err.data === "Invalid Blend ID") {
            callback({ error: "This source is not supported anymore" });
          } else if (error?.message?.includes?.("No Data Mapping Found")) {
            callback({ error: "Mapping Does not exists for current accounts for added Custom Dimensions in this source. Please Refresh Your Mapping!", });
          } else if (error.message?.includes?.("No Custom Dimension Found")) {
            callback({ error: "Some of the Custom Dimensions added in this source are deleted. Please update source dimensions and keys!", });
          } else if (error.additionalInfo === "Invalid Ad Account selected") {
            callback({ error: "Account Token Expired!" });
          } else if (error.additionalInfo) {
            callback({ error: error.additionalInfo?.message ?? error.additionalInfo });
          } else {
            callback({ error: "Internal Server Error", canReload: true });
          }
        } else if (err.status === 524) {
          callback({ error: "Timeout! Please try again in some time.", canReload: true });
        } else {
          console.log("error1", err);
          callback({ error: "Something went wrong!", canReload: true });
        }
      })
      .finally(() => {
        compare ? setIsCompareLoading(false) : setIsLoading(false);
      });
  };

  const updateTableTypeData = (
    startDate,
    endDate,
    callback,
    callbackData,
    compare
  ) => {
    compare ? setIsCompareLoading(true) : setIsLoading(true);
    const combinedMetrics = [
      ...chart.leftMetrics,
      ...(chart.rightMetrics ?? []),
    ].filter(m => metrics?.findIndex(metric => metric?.id === m?.metric?.id) >= 0);

    const combinedMetricsTemp = [...chart.leftMetrics, ...(chart.rightMetrics ?? [])];

    if (combinedMetrics.length === 0 || combinedMetricsTemp.length === 0) {
      callback({ ...callbackData, error: combinedMetricsTemp.length === 0 ? "No metrics are selected" : "Some columns used in the chart seems to be deleted, please remove them to populate the chart!" });
      compare ? setIsCompareLoading(false) : setIsLoading(false);
      return;
    }
    combinedMetricsTemp.length > combinedMetrics.length ? setIsAnymetricsDeleted(true) : setIsAnymetricsDeleted(false);
    const unitKey = channel.properties.unitKey ?? "dataType";
    // const namingMap = new Map();
    // for (const metric of combinedMetrics) {
    //   insertInNamingMap(namingMap, metric?.metric);
    // }
    // chart.dimensions?.forEach((dimension) =>
    //   insertInNamingMap(namingMap, dimension)
    // );

    const compareWithDateRange = chart.compareWith
      ? calComparedDate(chart.compareWith, dateRange)
      : compareDateRange.label ? compareDateRange : null;

    var promise;
    if (channel.isBlend) {
      promise = getBlendWidgetData(channel.type, [
        {
          startDate: startDate,
          endDate: endDate,
          compareWithDateRange: compareWithDateRange ? { ...compareWithDateRange, alignDates: true, } : null,
          ...channel.transformer.getApiObject(
            combinedMetrics
              .map((metric) => metric.metric)
              ?.filter((metric) => metric?.id),
            chart.dimensions?.filter((dim) => dim?.id),
            chart.filter,
            chart.table
          ),
        },
      ],
        chart.dimensions.map((dimension) => ({
          fieldName: dimension.id,
          dataSource: dimension.dataSource,
          accountId: dimension.audiences?.[0]?.id,
          sortOrder: "ASC",
        })),
      );
    } else {
      const getFilters = (filters) => {
        return Object.entries(filters ?? {}).reduce((acc, curr) => {
          const [accountId, filter] = curr;
          acc[accountId] = { "account": filter.account, "filterId": filter.id };
          return acc;
        }, {});
      }
      //without blend api call
      promise = getWidgetData(
        `${user.org?.name || user.company_name || user.org?.id}|${report || reportId
        }|${tabName}|${chart.title || chart.id}`,
        [
          {
            startDate: startDate,
            endDate: endDate,
            compareWithDateRange: compareWithDateRange ? { ...compareWithDateRange, alignDates: true, } : null,
            ...channel.transformer.getAccountId(
              account_R ?? selectedAccounts[channel.type],
              true
            ),
            ...channel.transformer.getApiObject(
              combinedMetrics.map((metric) => metric.metric.id),
              chart.dimensions.flatMap((dimension) => [dimension?.id]
                .concat(StatusDimensionMap?.[channel.type]?.[dimension?.id] ?? [])
                .concat(IdDimensionMap[dimension?.id] ?? [])),
              chart.filter,
              chart.table,
              chart.dataLevel,
              chart.excludeDeleted,
              getFilters(chart?.filters),
              chart?.isBlends
            ),
            orderBys: chart.dimensions.map((dimension) => ({
              fieldName: dimension.id,
              sortOrder: "ASC",
            })),
            dataSource: channel.type,
          },
        ]
      );
    }
    promise
      .then((data) => {
        const initialDateRange = " (" + startDate + " - " + endDate + ")";
        const compareDateRange = " (" + compareWithDateRange?.startDate + " - " + compareWithDateRange?.endDate + ")";

        const segmentIndex = data[0].columns.findIndex(
          (c) => c === "ga:segment"
        );
        const dataDimensions = chart.dimensions.map(dim => ({
          ...dim,
          // id: dim.id + (dim?.audiences?.[0] ? ` (${dim?.audiences?.[0]?.id})` : ""),
          unqName: dim.name + (dim?.audiences?.[0] ? ` (${dim?.audiences?.[0]?.id})` : "")
        }));
        let metricIndex = [];
        for (let metric of combinedMetrics) {
          metricIndex.push(
            data[0]?.columns.findIndex(
              (c, ci) =>
                (c === metric.metric.id || c === (metric.metric.id + initialDateRange)) &&
                data[0].dataSources?.[ci] === metric.metric.dataSource &&
                (!metric.metric.audiences?.[0]?.id || data[0].adAccounts?.[ci] === metric.metric.audiences?.[0]?.id)
            )
          );
        }
        const mappedData = data[0].values.map((value) => {
          const entry = new Map();
          data[0].columns.forEach((column, index) => {
            let dimCompare = false;
            let dimIndex = chart.dimensions.findIndex((d) => {
              if ((d.id + compareDateRange) === column) {
                dimCompare = true;
              }
              return (d.id === column || (d.id + initialDateRange) === column || (d.id + compareDateRange) === column) &&
                (!d.audiences?.[0]?.id || data[0].adAccounts?.[index] === d.audiences?.[0]?.id)
            });
            let metric = dimIndex === -1 && combinedMetrics[metricIndex.findIndex(m => m === index)]?.metric
            let dimension = dimIndex !== -1 && chart.dimensions[dimIndex];
            const displayName = dimIndex !== -1 ? dimension?.name + (dimension?.audiences?.[0] ? ` (${dimension?.audiences?.[0]?.id})` : "")
              : metric?.name + (metric?.audiences?.[0] ? ` (${metric?.audiences?.[0]?.id})` : "") ?? column;

            // const displayName = namingMap
            //   .get(data[0].dataSources?.[index] ?? chart.channelType)
            //   .get(column);
            if (Object.values(StatusDimensionMap?.[chart.channelType ?? {}]).includes(column)) {
              entry.set(column, value[index]);
            }
            if (displayName === "Name") {
              const columnName = column
                .replaceAll("_", " ")
                .replaceAll(".", " ")
                .split(" ")
                .map((name) => name[0].toUpperCase() + name.slice(1))
                .join(" ");
              dataDimensions[dimIndex].name = columnName;
              entry.set(columnName + (dimCompare ? " - Compared" : ""), value[index]);
              if (compareWithDateRange && dimIndex === -1) {
                entry.set(columnName + " - Compared", value[index + 1]);
                entry.set(columnName + " - Change", value[index + 2]);
                entry.set(columnName + " - Change(%)", value[index + 3]);
              }

            } else if (index !== segmentIndex) {
              entry.set(displayName + (dimCompare ? " - Compared" : ""), value[index]);
              if (compareWithDateRange && dimIndex === -1) {
                entry.set(displayName + " - Compared", value[index + 1]);
                entry.set(displayName + " - Change", value[index + 2]);
                entry.set(displayName + " - Change(%)", value[index + 3]);
              }
            }
          });
          chart.dimensions.forEach((d) => {
            if (!data[0].columns.includes(d.id)) {
              let index = data[0].columns.findIndex(
                (c) => c === d.miscInfo?.base_dimension?.id
              );
              if (index !== -1) entry.set(d.name, value[index]);
            }
          });
          return Object.fromEntries(entry.entries());
        });
        const currency = account_R?.[account[0] ?? account?.id]?.currency ?? "";
        const dataObject = {
          dimensions: dataDimensions,
          leftMetrics: combinedMetrics.map((metric) => ({
            id: metric.metric.id,  // + (metric.metric.audiences?.[0] ? ` (${metric.metric.audiences?.[0]?.id})` : ""),
            unqName: metric.metric.name + (metric.metric.audiences?.[0] ? ` (${metric.metric.audiences?.[0]?.id})` : ""),
            name: metric.metric.name,
            unit: metric.metric[unitKey],
            metricType: metric.metric.metricType,
            audiences: metric.metric.audiences,
            account: metric.metric.account,
            compare: compareWithDateRange ? " - Compared" : null,
            currency: !channel?.isBlend ? currency : (account
              ? (account?.currency ?? Object.values(accountCols)?.[0]?.currency)
              : state.currency
                ? state.currency
                : dataBlendSource(metric.metric.name)
                  ? wholeState[dataBlendSource(metric.metric.name)]?.currency
                  : undefined),
          })),
          data: mappedData,
          aggregatedValues: data[0]?.aggregatedValues ?? [],
          mappedAccountIdApiConfigKey: mappedAccountWithApiConfigKey(data[0]?.adAccounts ?? [], data[0]?.apiConfigKeys)
        };
        channel.transformer.transformData?.(dataObject);
        callback(dataObject);
      })
      .catch((err) => {
        let errorStatus = err?.data?.responses?.error?.status;
        let error = err?.data?.error ?? err?.data?.responses?.error;
        if (error?.additionalInfo?.detail?.includes?.("Invalid column")) {
          callback({ error: "Some columns used in the chart seems to be deleted, please remove them to populate the chart!" });
        } else if ([400, 422].includes(err.status) || errorStatus === 400 || errorStatus === 422) {
          if (error.additionalInfo.detail?.[0]?.msg?.includes("Both date ranges cannot be same")) {
            callback({ error: "Both Reporting date-range and Comparison date-range cannot be same." })
          } else if (error?.additionalInfo?.detail === "Selected fields are not campatible.") {
            callback({ error: "Selected fields are not campatible." })
          } else {
            callback({ error: "Invalid query" });
          }
        } else if (err.status === 500) {
          if (err.data === "Invalid Blend ID") {
            callback({ error: "This source is not supported anymore" });
          } else if (error?.message?.includes?.("No Data Mapping Found")) {
            callback({ error: "Mapping Does not exists for current accounts for added Custom Dimensions in this source. Please Refresh Your Mapping!", });
          } else if (error.message?.includes?.("No Custom Dimension Found")) {
            callback({ error: "Some of the Custom Dimensions added in this source are deleted. Please update source dimensions and keys!", });
          } else if (error.additionalInfo === "Invalid Ad Account selected") {
            callback({ error: "Account Token Expired!" });
          } else if (error.additionalInfo) {
            callback({ error: error.additionalInfo?.message ?? error.additionalInfo });
          } else {
            callback({ error: "Internal Server Error", canReload: true });
          }
        } else if (err.status === 524) {
          callback({ error: "Timeout! Please try again in some time.", canReload: true });
        } else {
          console.log("error2", err);
          callback({ error: "Something went wrong!", canReload: true });
        }
      })
      .finally(() => {
        compare ? setIsCompareLoading(false) : setIsLoading(false);
      });
  };

  const filterDataOptions = useMemo(() => {
    return ([...(data?.dimensions ?? []), ...(data?.leftMetrics ?? [])])
  }, [data]);

  const filterData = useMemo(() => {
    if (filterKey && filterText) {
      let tempData = { ...data, error: "" };
      let filteredData = tempData?.data?.filter((dataItem) => {
        return dataItem?.[filterKey]?.toString()?.toLowerCase()?.includes(filterText?.toString().toLowerCase())
      }) ?? []
      tempData = { ...tempData, data: [...filteredData] }
      return tempData
    } else {
      return data
    }
  }
    , [filterKey, data, filterText])

  const updateData = () => {
    setData({ ...data, error: "" });
    setTitle(chart.title);
    setIsLoading(true);
    setEditChart(false);
    if (chart.type === "TEXT") {
      setIsAddChart(false);
      setIsLoading(false);
      return;
    }

    // check if channel is unauthenticated
    if (channel.isBlend) {
      if (
        !wholeState.blends.blends.find((blend) => blend.id === channel.type)
      ) {
        setData({
          error: "This source is not supported anymore",
          footerError: true,
        });
        setIsLoading(false);
        return;
      }
    } else {
      if (!authenticatedAccounts.find((type) => type === channel.type)) {
        setData({
          error: "No account connected",
          footerError: true,
          redirect: "/managesources",
        });
        setIsLoading(false);
        return;
      }
    }
    if (!dateRange) {
      return;
    }

    if (
      (channel.isBlend && !channelCols) ||
      (!channel.isBlend && account && !accountCols)
    ) {
      setTimeout(() => {
        setReloadCount((prev) => prev + 1);
      }, 5 * 1000);
      return;
    }

    const combinedMetrics = [
      ...chart.leftMetrics,
      ...(chart.rightMetrics ?? []),
    ].filter(m => metrics?.findIndex(metric => metric?.id === m?.metric?.id) >= 0);;

    const combinedMetricsTemp = [...chart.leftMetrics, ...(chart.rightMetrics ?? [])];
    if (combinedMetrics.length === 0 || combinedMetricsTemp.length === 0) {
      setData({ ...data, error: combinedMetricsTemp.length === 0 ? "No metrics are selected" : "Some columns used in the chart seems to be deleted, please remove them to populate the chart!" });
      setIsLoading(false);
      return;
    }

    if (!state.metricsUpdated) {
      setData({
        error:
          "Metrics not updated for the channel! Please wait for some time, or try refreshing the page!",
      });
      setIsLoading(false);
      return;
    }

    // if (state.tablesUpdated && !state.tablesUpdated[chart.table?.id]) {
    //   setData({
    //     error:
    //       "Metrics not updated for the selected table in this channel! Please wait for some time, or try refreshing the page!",
    //   });
    //   setIsLoading(false);
    //   return;
    // }

    // channel.updateGridFields(
    //   chart,
    //   state.tables,
    //   !Array.isArray(state.metrics)
    //     ? state.metrics
    //     : channel.isBlend
    //       ? channelCols.metrics
    //       : state.metrics.concat(
    //         accountCols ? accountCols.metrics : state.customMetrics ?? []
    //       ),
    //   !Array.isArray(state.dimensions)
    //     ? state.dimensions
    //     : channel.isBlend
    //       ? channelCols.dimensions
    //       : state.dimensions.concat(
    //         accountCols ? accountCols.dimensions : state.customDimensions ?? []
    //       )
    // );
    combinedMetricsTemp.length > combinedMetrics.length ? setIsAnymetricsDeleted(true) : setIsAnymetricsDeleted(false);
    setIsAddChart(false);
    handleClose();
    setEditChart(true);
    //check if required accounts selected for channels
    if (channel.isBlend) {
      if (account && Object.keys(account).length) {
        const customAccounts = channel.transformer.getAccountId(account, state);
        for (let acc in customAccounts) {
          if (!(acc in account)) {
            setData({
              error:
                "Either remove current custom selection or select all required account IDs for blend",
            });
            setIsLoading(false);
            return;
          }
        }
      }
      //  else {
      //   const accounts = channel.transformer.getAccountId(
      //     selectedAccounts,
      //     state
      //   );
      //   for (let account in accounts) {
      //     if (!(account in selectedAccounts)) {
      //       setData({ error: "Required account id has not been selected" });
      //       setIsLoading(false);
      //       return;
      //     }
      //   }
      // }
    } else if (!(channel.type in selectedAccounts) && !account_R && ((Array.isArray(account_R) && !account_R?.length) || !Object.keys(account_R).length)) {
      setData({
        error:
          'Please select Ad Account to populate the chart.',
      });
      setIsLoading(false);
      return;
    }

    if (chart.type === "ADD") {
      setData({ error: "Chart type not selected" });
      setIsLoading(false);
      return;
    }

    const unitKey = channel.properties.unitKey ?? "dataType";

    const namingMap = new Map();

    for (const metric of combinedMetrics) {
      if (!metric?.metric) {
        if (combinedMetrics.length === 1) {
          setData({
            error: `No field selected. ${channel.isBlend
              ? "Please check the mapping once within data blend source."
              : ""
              }`,
          });
        } else {
          setData({
            error: `Some selected metric fields doesn't exist. ${channel.isBlend
              ? "Please check the mapping once within data blend source."
              : "It may exist on some other account."
              }`,
          });
        }
        setIsLoading(false);
        return;
      }
      insertInNamingMap(namingMap, metric?.metric);
    }

    for (const [index, dimension] of (chart.dimensions ?? []).entries()) {
      if (!["PIE", "PIE3D", "DONUT", "DONUT3D", "FUNNEL", "KPI"].includes(currentChartType) && !dimension?.id) {
        setData({
          error: `Some selected dimension fields doesn't exist. ${channel.isBlend
            ? "Please check the mapping once within data blend source."
            : "It may exist on some other account."
            }`,
        });
        setIsLoading(false);
        return;
      }
      insertInNamingMap(namingMap, dimension);
    }
    chart.dimensions?.forEach((dimension) =>
      namingMap.set(dimension?.id, dimension?.name)
    );
    if (getData) {
      if (currentChartType === "TABLE" && isDrillTable) {
        updateDrillTableData(
          dateRange.startDate,
          dateRange.endDate,
          setData,
          data
        );
      } else if (ChartFilterSupport(currentChartType)) {
        updateTableTypeData(
          dateRange.startDate,
          dateRange.endDate,
          setData,
          data
        );
      } else {
        updateChartData(dateRange.startDate, dateRange.endDate, setData, data);
      }
    } else {
      setGetData(true);
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setExportData(false);
  };

  const handleRemoveChart = () => {
    dispatch(buttonClickSaveAndUpdate("REMOVE_CHART"));
    handleChartChange("removeChart", "ADD");
    handleClose();
    closeEditDrawer(chart);
  };

  const saveAs = (blob, fileName) => {
    var elem = window.document.createElement("a");
    elem.href = blob;
    elem.download = fileName;
    elem.style = "display:none;";
    (document.body || document.documentElement).appendChild(elem);
    if (typeof elem.click === "function") {
      elem.click();
    } else {
      elem.target = "_blank";
      elem.dispatchEvent(
        new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true,
        })
      );
    }
    URL.revokeObjectURL(elem.href);
    elem.remove();
  };

  const padding = 15;
  let top = 15;
  const elHeight = 80;
  const elWidth = 150;
  const maxColCount = 5;

  const createPdfData = async (doc, top) => {
    if (isTable || chart.type === "TABLE") {
      const headers = [
        ...drillData.selected.map((d) => d.dimKey.unqName),
        ...(data.dimensions ?? []).map((d) => d?.unqName ?? d),
        ...data.leftMetrics.map((m) => m.unqName + (m.filter ? " (" + m.filter + ")" : "")),
        ...(data.rightMetrics?.map((m) => m.unqName + (m.filter ? " (" + m.filter + ")" : "")) ?? []),
      ];
      for (let count = 0; count < headers.length; count = count + maxColCount) {
        if (headers.length > maxColCount) {
          doc.text(["Table:: Part " + (count / maxColCount + 1)], padding, top);
          top += 10;
        }
        const filteredHeaders = headers.filter(
          (h, index) => index >= count && index < count + maxColCount
        );
        var rows = data.data.map((row, rowIndex) => {
          let obj = [rowIndex + 1];
          for (let header of filteredHeaders) {
            obj.push(row[header]);
          }
          return obj;
        });
        doc.autoTable({
          startY: top,
          head: [["Sr. No.", ...filteredHeaders]],
          body: rows,
          columnStyles: {
            0: {
              columnWidth: 20,
              fontStyle: "bold",
              halign: "center",
            },
          },
        });
        top = doc.previousAutoTable.finalY + 10;
      }
    } else {
      const imgData = await htmlToImage.toPng(
        document.getElementById("Chart" + chart.id),
        { quality: 1.0, pixelRatio: 1 }
      );
      doc.addImage(
        imgData,
        "PNG",
        padding,
        top,
        elWidth,
        elHeight,
        `image${chart.id}`
      );
      top += elHeight + padding;
    }
    return top;
  };

  useImperativeHandle(ref, () => ({
    forceUpdateChart() {
      forceUpdate();
    },
    handleAllExport() {
      if (data && data?.data?.length > 0) {
        const title = ["Title:", `"${chart.title}"`];

        let date = [];
        const compareWithDateRange = chart.compareWith
          ? calComparedDate(chart.compareWith, dateRange)
          : compareDateRange.label ? compareDateRange : null;
        if (compareWithDateRange) {
          date.push([
            "Compare Date Range: ",
            DateDisplayFormatter(compareWithDateRange.startDate) +
            " - " +
            DateDisplayFormatter(compareWithDateRange.endDate),
          ])
        }

        const headers = [
          ...drillData.selected.flatMap((d) => data.data[0][d.dimKey.unqName + " - Compared"] ?
            [d.dimKey.unqName, d.dimKey.unqName + " - Compared"]
            : d.dimKey.unqName
          ),
          ...(data?.dimensions ?? []).flatMap((d) => data.data[0][(d?.unqName ?? d) + " - Compared"] ?
            [d?.unqName ?? d, (d?.unqName ?? d) + " - Compared"]
            : d?.unqName ?? d
          ),
          ...data.leftMetrics.flatMap((m) => m.compare
            ? [m.unqName + (m.filter ? " (" + m.filter + ")" : ""),
            m.unqName + (m.filter ? " (" + m.filter + ")" : "") + m.compare,
            m.unqName + (m.filter ? " (" + m.filter + ")" : "") + " - Change",
            m.unqName + (m.filter ? " (" + m.filter + ")" : "") + " - Change(%)"
            ]
            : m.unqName + (m.filter ? " (" + m.filter + ")" : "")
          ),
          ...(data.rightMetrics ?? []).flatMap((m) => m.compare
            ? [m.unqName + (m.filter ? " (" + m.filter + ")" : ""),
            m.unqName + (m.filter ? " (" + m.filter + ")" : "") + m.compare,
            m.unqName + (m.filter ? " (" + m.filter + ")" : "") + " - Change",
            m.unqName + (m.filter ? " (" + m.filter + ")" : "") + " - Change(%)"
            ]
            : m.unqName + (m.filter ? " (" + m.filter + ")" : "")
          ),
        ];
        const rows = data.data.map((row) => headers.map((h) => `"${row[h]}"`));
        return [title, ...date, [], headers.map(item => `"${item}"`), ...rows];
      }
    },
    async handleAllPdfExport(doc, top) {
      if (data && data?.data?.length > 0) {
        if (top > 200) {
          top = 15;
          doc.addPage();
        }
        var title = `Title:  ${chart.title}`;
        doc.text([title], padding, top);
        top += 10;
        top = await createPdfData(doc, top);
      }
      return top;
    },
    handleAllPngExport() {
      if (!data || !data.data || data.data.length === 0) return;
      onCapturePNG();
    },
    handleAllJpgExport() {
      if (!data || !data.data || data.data.length === 0) return;
      onCaptureJPG();
    },
  }));

  const onCapturePNG = () => {
    const name = chart.title
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .replaceAll(" ", "-")
      .toLowerCase();
    htmlToImage
      .toPng(document.getElementById("Chart" + chart.id), {
        quality: 1.0,
        pixelRatio: 1,
      })
      .then(function (dataUrl) {
        saveAs(dataUrl, `${name}.png`);
      });
    handleClose();
  };

  const onCaptureJPG = () => {
    const name = chart.title
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .replaceAll(" ", "-")
      .toLowerCase();
    htmlToImage
      .toJpeg(document.getElementById("Chart" + chart.id), {
        backgroundColor: "white",
        quality: 1.0,
        pixelRatio: 1,
      })
      .then(function (dataUrl) {
        saveAs(dataUrl, `${name}.jpeg`);
      });
    handleClose();
  };

  const handleExport = () => {
    dispatch(updateLoader(true));
    const title = ["Title:", `"${chart.title}"`];
    const date = [[
      "Date Range: ",
      DateDisplayFormatter(dateRange.startDate) +
      " - " +
      DateDisplayFormatter(dateRange.endDate),
    ]];

    const compareWithDateRange = chart.compareWith
      ? calComparedDate(chart.compareWith, dateRange)
      : compareDateRange.label ? compareDateRange : null;

    if (compareWithDateRange) {
      date.push([
        "Compare Date Range: ",
        DateDisplayFormatter(compareWithDateRange.startDate) +
        " - " +
        DateDisplayFormatter(compareWithDateRange.endDate),
      ])
    }

    const headers = [
      ...drillData.selected.flatMap((d) => data.data[0][d.dimKey.unqName + " - Compared"] ?
        [d.dimKey.unqName, d.dimKey.unqName + " - Compared"]
        : d.dimKey.unqName
      ),
      ...(data?.dimensions ?? []).flatMap((d) => data.data[0][(d?.unqName ?? d) + " - Compared"] ?
        [d?.unqName ?? d, (d?.unqName ?? d) + " - Compared"]
        : d?.unqName ?? d
      ),
      ...data.leftMetrics.flatMap((m) => m.compare
        ? [m.unqName + (m.filter ? " (" + m.filter + ")" : ""),
        m.unqName + (m.filter ? " (" + m.filter + ")" : "") + m.compare,
        m.unqName + (m.filter ? " (" + m.filter + ")" : "") + " - Change",
        m.unqName + (m.filter ? " (" + m.filter + ")" : "") + " - Change(%)"
        ]
        : m.unqName + (m.filter ? " (" + m.filter + ")" : "")
      ),
      ...(data.rightMetrics ?? []).flatMap((m) => m.compare
        ? [m.unqName + (m.filter ? " (" + m.filter + ")" : ""),
        m.unqName + (m.filter ? " (" + m.filter + ")" : "") + m.compare,
        m.unqName + (m.filter ? " (" + m.filter + ")" : "") + " - Change",
        m.unqName + (m.filter ? " (" + m.filter + ")" : "") + " - Change(%)"
        ]
        : m.unqName + (m.filter ? " (" + m.filter + ")" : "")
      ),
    ];
    const rows = data.data.map((row) => headers.map((h) => `"${row[h]}"`));
    const blob = new Blob([[title, ...date, [], headers.map(item => `"${item}"`), ...rows].join("\n")], {
      type: "text/csv",
    });
    const a = document.createElement("a");
    a.download = chart.title
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .replaceAll(" ", "-")
      .toLowerCase();
    a.href = window.URL.createObjectURL(blob);
    a.dispatchEvent(
      new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      })
    );
    a.remove();

    handleClose();
    dispatch(updateLoader(false));
  };

  const handlePdfExport = async () => {
    dispatch(updateLoader(true));
    const name = chart.title
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .replaceAll(" ", "-")
      .toLowerCase();
    const doc = new jsPDF({});
    var title = `Title:  ${chart.title}`;
    var date = `Date Range:  ${DateDisplayFormatter(
      dateRange.startDate
    )} - ${DateDisplayFormatter(dateRange.endDate)}`;
    doc.text([title, date], padding, top);
    top += 20;
    top = await createPdfData(doc, top);
    doc.save(`${name}.pdf`);
    handleClose();
    dispatch(updateLoader(false));
  };

  const updateChartData = (
    startDate,
    endDate,
    callback,
    callbackData,
    compare
  ) => {
    compare ? setIsCompareLoading(true) : setIsLoading(true);
    const combinedMetrics = [
      ...chart.leftMetrics,
      ...(chart.rightMetrics ?? []),
    ].filter(m => metrics?.findIndex(metric => metric?.id === m?.metric?.id) >= 0);

    const combinedMetricsTemp = [...chart.leftMetrics, ...(chart.rightMetrics ?? [])];

    if (combinedMetrics.length === 0 || combinedMetricsTemp.length === 0) {
      callback({ ...callbackData, error: combinedMetricsTemp.length === 0 ? "No metrics are selected" : "Some columns used in the chart seems to be deleted, please remove them to populate the chart!" });
      compare ? setIsCompareLoading(false) : setIsLoading(false);
      return;
    }

    combinedMetricsTemp.length > combinedMetrics.length ? setIsAnymetricsDeleted(true) : setIsAnymetricsDeleted(false);
    const unitKey = channel.properties.unitKey ?? "dataType";
    const namingMap = new Map();
    for (const metric of combinedMetrics) {
      insertInNamingMap(namingMap, metric?.metric);
    }
    chart.dimensions?.forEach((dimension) =>
      insertInNamingMap(namingMap, dimension)
    );

    const compareWithDateRange = chart.compareWith
      ? calComparedDate(chart.compareWith, dateRange)
      : compareDateRange.label ? compareDateRange : null;

    var promise;
    if (channel.isBlend) {
      promise = getBlendWidgetData(channel.type, [
        {
          startDate: startDate,
          endDate: endDate,
          compareWithDateRange: compareWithDateRange ? { ...compareWithDateRange, alignDates: true, } : null,
          ...channel.transformer.getApiObject(
            combinedMetrics
              .map((metric) => metric.metric)
              ?.filter((metric) => metric?.id),
            chart.dimensions?.filter((dim) => dim?.id),
            // combinedMetrics[0]?.filter,
            null,
            chart.table
          ),
          ...(chart.filter ? getFilterObject(chart.filter, compare) : {}),
        },
      ],
        chart.dimensions?.map((dimension) => ({
          fieldName: dimension?.id,
          dataSource: dimension.dataSource,
          accountId: dimension.audiences?.[0]?.id,
          sortOrder: "ASC",
        })) ?? [],
      );
    } else {
      const getMetricFilters = (filters) => {
        return Object.entries(filters ?? {}).reduce((acc, curr) => {
          const [accountId, filter] = curr;
          acc[accountId] = { "account": filter.account, "filterId": filter.id };
          return acc;
        }, {});
      }
      //without blend api call
      promise = getWidgetData(
        `${user.company_name || user.org?.id}|${report || reportId
        }|${tabName}|${chart.title || chart.id}`,
        combinedMetrics.map((metric) => ({
          startDate: startDate,
          endDate: endDate,
          compareWithDateRange: compareWithDateRange ? { ...compareWithDateRange, alignDates: true } : null,
          ...channel.transformer.getAccountId(
            account_R ?? selectedAccounts[channel.type],
            true
          ),
          ...channel.transformer.getApiObject(
            [metric.metric?.id],
            chart.dimensions
              ?.filter((d) => d)
              .flatMap((dimension) => [dimension?.id].concat(IdDimensionMap[dimension?.id] ?? [])) ?? [],
            metric.filter,
            chart.table,
            chart.dataLevel,
            chart.excludeDeleted,
            getMetricFilters(metric?.filters),
            chart?.isBlends
          ),
          orderBys: chart.dimensions?.map((dimension) => ({
            fieldName: dimension?.id,
            sortOrder: "ASC",
          })) ?? [],
          dataSource: channel.type,
        }))
      );
    }
    promise
      .then((chartData) => {
        const initialDateRange = " (" + startDate + " - " + endDate + ")";
        const compareDateRange = " (" + compareWithDateRange?.startDate + " - " + compareWithDateRange?.endDate + ")";
        const rightMetricsIndex = chart.leftMetrics.length;
        const totalMetrics = combinedMetrics.length;
        const dimensionIndex =
          chart.dimensions && chart.dimensions.length > 0
            ? chartData[0].columns.findIndex(
              (c) =>
                c === chart.dimensions[0]?.id ||
                c === (chart.dimensions[0]?.id + initialDateRange) ||
                c === chart.dimensions[0]?.miscInfo?.base_dimension?.id
            )
            : -1;
        const compDimensionIndex = (compareWithDateRange && chart.dimensions && chart.dimensions.length > 0)
          ? chartData[0].columns.findIndex((c) => c === (chart.dimensions[0]?.id + compareDateRange)) : -1;

        const totalRows = chartData.reduce(
          (prevValue, currentValue) =>
            Math.min(prevValue, currentValue.rowCount),
          chartData[0].rowCount
        );
        const totalMaxRows = chartData.reduce(
          (prevValue, currentValue) =>
            Math.max(prevValue, currentValue.rowCount),
          chartData[0].rowCount
        );
        const currency = account_R?.[account?.[0] ?? account?.id]?.currency ?? "";
        const dataLeftMetrics = chart.leftMetrics.map((metric, index) => ({
          id: metric.metric.id
            + (compareWithDateRange ? initialDateRange : "")
            + (metric.metric?.audiences?.[0] ? ` (${metric.metric?.audiences?.[0]?.id})` : ""),
          unqName: metric.metric.name + (metric.metric.audiences?.[0] ? ` (${metric.metric.audiences?.[0]?.id})` : "") + index,
          name: metric.metric.name,
          unit: metric.metric[unitKey],
          metricType: metric.metric.metricType,
          audiences: metric.metric.audiences,
          account: metric.metric.account,
          currency: !channel?.isBlend ? currency : account
            ? (account?.currency ?? accountCols?.currency)
            : state.currency
              ? state.currency
              : dataBlendSource(metric.metric.name) ? wholeState[dataBlendSource(metric.metric.name)]?.currency : undefined,
          filter: metric.filter.name,
          compare: compareWithDateRange ? " - Compared" : null,
          filters: metric.filters
        }));
        const dataRightMetrics = chart.rightMetrics?.map((metric, index) => ({
          id: metric.metric.id
            + (compareWithDateRange ? initialDateRange : "")
            + (metric.metric?.audiences?.[0] ? ` (${metric.metric?.audiences?.[0]?.id})` : ""),
          unqName: metric.metric.name + (metric.metric.audiences?.[0] ? ` (${metric.metric.audiences?.[0]?.id})` : "") + index,
          name: metric.metric.name,
          unit: metric.metric[unitKey],
          metricType: metric.metric.metricType,
          audiences: metric.metric.audiences,
          account: metric.metric.account,
          currency: !channel?.isBlend ? currency : account
            ? (account.currency ?? accountCols.currency)
            : state.currency
              ? state.currency
              : dataBlendSource(metric.metric.name) ? wholeState[dataBlendSource(metric.metric.name)]?.currency : undefined,
          filter: metric.filter.name,
          compare: compareWithDateRange ? " - Compared" : null,
          filters: metric.filters
        }));

        let mappedData = [];
        let metricIndex = [];
        for (let i = 0; i < totalMetrics; i++) {
          metricIndex.push(
            chartData[channel.isBlend ? 0 : i].columns.findIndex(
              (c, ci) =>
                (c === combinedMetrics[i].metric.id || c === (combinedMetrics[i].metric.id + initialDateRange)) &&
                chartData[channel.isBlend ? 0 : i].dataSources?.[ci] === combinedMetrics[i].metric.dataSource &&
                (!combinedMetrics[i].metric.audiences?.[0]?.id || chartData[channel.isBlend ? 0 : i].adAccounts?.[ci] === combinedMetrics[i].metric.audiences?.[0]?.id)
            )
          );
        }

        let dimWiseData = {};
        if (dimensionIndex >= 0 && totalRows !== totalMaxRows) {
          for (let [mIndex, metric] of chartData.entries()) {
            let colIndex = metric.columns.findIndex((c) => (c === combinedMetrics[mIndex].metric.id || c === (combinedMetrics[mIndex].metric.id + initialDateRange)));
            let column = metric.columns[colIndex];

            if (Object.keys(combinedMetrics[mIndex].filters ?? {}).length > 0) {
              const filterName = Object.keys(combinedMetrics[mIndex].filters ?? {}).join("-");
              //Updating name in chart metrics data only once
              if (mIndex >= rightMetricsIndex) {
                dataRightMetrics[mIndex - rightMetricsIndex].id += " (" + filterName + ")";
              } else {
                dataLeftMetrics[mIndex].id += " (" + filterName + ")";
              }
              column = column + " (" + filterName + ")";
            } else if (!combinedMetrics[mIndex].filter.isEmpty()) {
              const filterName = combinedMetrics[mIndex].filter.name;
              //Updating name in chart metrics data only once
              if (mIndex >= rightMetricsIndex) {
                dataRightMetrics[mIndex - rightMetricsIndex].id += " (" + filterName + ")";
              } else {
                dataLeftMetrics[mIndex].id += " (" + filterName + ")";
              }
              column = column + " (" + filterName + ")";
            }

            for (let value of metric.values) {
              if (dimWiseData[value[dimensionIndex]]) {
                dimWiseData[value[dimensionIndex]][column] = [value[colIndex], value[colIndex + 1], value[colIndex + 2], value[colIndex + 3]];
              } else {
                dimWiseData[value[dimensionIndex]] = {
                  "compare": compDimensionIndex >= 0 ? value[compDimensionIndex] : null,
                  [column]: [value[colIndex], value[colIndex + 1], value[colIndex + 2], value[colIndex + 3]],
                };
              }
            }
          }

          let dimName = namingMap.get(chartData[0].dataSources?.[dimensionIndex] ?? chart.channelType)
            .get(chartData[0].columns[dimensionIndex])
            ?? chart.dimensions[0].name;

          for (let row of Object.keys(dimWiseData)) {
            let entry = new Map();
            entry.set(dimName, row);
            compDimensionIndex >= 0 && entry.set(dimName + " - Compared", dimWiseData[row]["compare"])

            for (let mIndex = 0; mIndex < totalMetrics; mIndex++) {
              const index = metricIndex[mIndex];
              let metricData = chartData[channel.isBlend ? 0 : mIndex];
              let column = metricData.columns[index];
              let metricId = chartData[channel.isBlend ? 0 : mIndex].columns[index];
              let metric = mIndex >= rightMetricsIndex ? dataRightMetrics[mIndex - rightMetricsIndex] : dataLeftMetrics[mIndex];
              let metricName = metric.name + (metric?.audiences?.[0] ? ` (${metric?.audiences?.[0]?.id})` : "");
              // let metricName = namingMap.get(chartData[channel.isBlend ? 0 : mIndex].dataSources?.[index] ?? chart.channelType)
              //   .get(chartData[channel.isBlend ? 0 : mIndex].columns[index]);

              let filterName;
              if (Object.keys(combinedMetrics[mIndex].filters ?? {}).length > 0) {
                filterName = Object.keys(combinedMetrics[mIndex].filters).join("-");
                column += " (" + filterName + ")";
                metricId += " (" + filterName + ")"
              } else if (!combinedMetrics[mIndex].filter.isEmpty()) {
                filterName = combinedMetrics[mIndex].filter.name;
                column += " (" + filterName + ")";
                metricId += " (" + filterName + ")"
              }
              if (combinedMetrics[mIndex]?.audiences?.[0]?.id) {
                column += " (" + combinedMetrics[mIndex]?.audiences?.[0]?.id + ")";
                metricId += " (" + combinedMetrics[mIndex]?.audiences?.[0]?.id + ")"
              }
              let value = dimWiseData[row][column] ?? ["-"];
              entry.set(metricName + (filterName ? " (" + filterName + ")" : ""), value[0] ?? "-");
              entry.set(metricId, value[0] ?? "-");
              if (compareWithDateRange) {
                entry.set(metricName + (filterName ? " (" + filterName + ")" : "") + " - Compared", value[1] ?? "-");
                entry.set(metricName + (filterName ? " (" + filterName + ")" : "") + " - Change", value[2] ?? "-");
                entry.set(metricName + (filterName ? " (" + filterName + ")" : "") + " - Change(%)", value[3] ?? "-");
              }
            }
            mappedData.push(Object.fromEntries(entry));
          }
        } else {
          for (let row = 0; row < totalRows; row++) {
            let entry = new Map();

            if (dimensionIndex >= 0) {
              const value = chartData[0].values[row][dimensionIndex];
              if (value === "-") break;
              let dimName = namingMap.get(chartData[0].dataSources?.[dimensionIndex] ?? chart.channelType)
                .get(chartData[0].columns[dimensionIndex])
                ?? chart.dimensions[0].name;
              entry.set(dimName, value);
              compDimensionIndex >= 0 && entry.set(dimName + " - Compared", chartData[0].values[row][compDimensionIndex])
            }

            for (let mIndex = 0; mIndex < totalMetrics; mIndex++) {
              const index = metricIndex[mIndex];
              const value = chartData[channel.isBlend ? 0 : mIndex].values[row][index];
              const compareValue = chartData[channel.isBlend ? 0 : mIndex].values[row][index + 1];
              const changeValue = chartData[channel.isBlend ? 0 : mIndex].values[row][index + 2];
              const percentValue = chartData[channel.isBlend ? 0 : mIndex].values[row][index + 3];
              const metric = mIndex >= rightMetricsIndex ? dataRightMetrics[mIndex - rightMetricsIndex] : dataLeftMetrics[mIndex];
              const metricId = chartData[channel.isBlend ? 0 : mIndex].columns[index] + (metric?.audiences?.[0] ? ` (${metric?.audiences?.[0]?.id})` : "");
              const metricName = metric.name + (metric?.audiences?.[0] ? ` (${metric?.audiences?.[0]?.id})` : "");
              // namingMap.get(chartData[channel.isBlend ? 0 : mIndex].dataSources?.[index] ?? chart.channelType)
              //   .get(chartData[channel.isBlend ? 0 : mIndex].columns[index]);
              if (Object.keys(combinedMetrics[mIndex].filters ?? {}).length > 0) {
                const filterName = Object.keys(combinedMetrics[mIndex].filters ?? {}).join("-");
                //Upddating name only once
                if (row === 0) {
                  if (mIndex >= rightMetricsIndex) {
                    dataRightMetrics[mIndex - rightMetricsIndex].id += " (" + filterName + ")";
                  } else {
                    dataLeftMetrics[mIndex].id += " (" + filterName + ")";
                  }
                }
                entry.set(metricName + " (" + filterName + ")", value);
                entry.set(metricId + " (" + filterName + ")", value);
                if (compareWithDateRange) {
                  entry.set(metricName + " (" + filterName + ")" + " - Compared", compareValue);
                  entry.set(metricName + " (" + filterName + ")" + " - Change", changeValue);
                  entry.set(metricName + " (" + filterName + ")" + " - Change(%)", percentValue);
                }
              }
              // if metric has filter, insert using filter name
              else if (!combinedMetrics[mIndex].filter.isEmpty()) {
                const filterName = combinedMetrics[mIndex].filter.name;
                //Upddating name only once
                if (row === 0) {
                  if (mIndex >= rightMetricsIndex) {
                    dataRightMetrics[mIndex - rightMetricsIndex].id += " (" + filterName + ")";
                  } else {
                    dataLeftMetrics[mIndex].id += " (" + filterName + ")";
                  }
                }
                entry.set(metricName + " (" + filterName + ")", value);
                entry.set(metricId + " (" + filterName + ")", value);
                if (compareWithDateRange) {
                  entry.set(metricName + " (" + filterName + ")" + " - Compared", compareValue);
                  entry.set(metricName + " (" + filterName + ")" + " - Change", changeValue);
                  entry.set(metricName + " (" + filterName + ")" + " - Change(%)", percentValue);
                }
              } else {
                if (compareWithDateRange) {
                  entry.set(metricName + " - Compared", compareValue);
                  entry.set(metricName + " - Change", changeValue);
                  entry.set(metricName + " - Change(%)", percentValue);
                }
                entry.set(metricName, value);
                entry.set(metricId, value);
              }
            }
            mappedData.push(Object.fromEntries(entry));
          }
        }
        const dataObject = {
          dimensions: chart.dimensions?.map((d) => d?.name || ""),
          leftMetrics: dataLeftMetrics,
          rightMetrics: dataRightMetrics,
          data: mappedData,
          aggregatedValues: addFilterNameAggrMetrics(chartData?.reduce((acc, curr) => { return acc.concat(curr.aggregatedValues) }, []), (dataLeftMetrics?.concat(dataRightMetrics ?? []))) ?? [],
          mappedAccountIdApiConfigKey: mappedAccountWithApiConfigKey(chartData[0]?.adAccounts ?? [], chartData[0]?.apiConfigKeys)
        };
        channel.transformer.transformData?.(dataObject);

        callback(dataObject);
      })
      .catch((err) => {
        let errorStatus = err?.data?.responses?.error?.status;
        let error = err?.data?.error ?? err?.data?.responses?.error;
        if (error?.additionalInfo?.detail?.includes?.("Invalid column")) {
          callback({ error: "Some columns used in the chart seems to be deleted, please remove them to populate the chart!" });
        } else if ([400, 422].includes(err.status) || errorStatus === 400 || errorStatus === 422) {
          if (error.additionalInfo.detail?.[0]?.msg?.includes("Both date ranges cannot be same")) {
            callback({ error: "Both Reporting date-range and Comparison date-range cannot be same." })
          } else if (error?.additionalInfo?.detail === "Selected fields are not campatible.") {
            callback({ error: "Selected fields are not campatible." })
          } else {
            callback({ error: "Invalid query" });
          }
        } else if (err.status === 500) {
          if (err.data === "Invalid Blend ID") {
            callback({ error: "This source is not supported anymore" });
          } else if (error?.message?.includes?.("No Data Mapping Found")) {
            callback({ error: "Mapping Does not exists for current accounts for added Custom Dimensions in this source. Please Refresh Your Mapping!", });
          } else if (error.message?.includes?.("No Custom Dimension Found")) {
            callback({ error: "Some of the Custom Dimensions added in this source are deleted. Please update source dimensions and keys!", });
          } else if (error.additionalInfo === "Invalid Ad Account selected") {
            callback({ error: "Account Token Expired!" });
          } else if (error.additionalInfo) {
            callback({ error: error.additionalInfo?.message ?? error.additionalInfo });
          } else {
            callback({ error: "Internal Server Error", canReload: true });
          }
        } else if (err.status === 524) {
          callback({ error: "Timeout! Please try again in some time.", canReload: true });
        } else {
          console.log("error3", err);
          callback({ error: "Something went wrong!", canReload: true });
        }
      })
      .finally(() => {
        compare ? setIsCompareLoading(false) : setIsLoading(false);
      });
  };

  // const updateCompareWithDate = (value) => {
  //   if (!value) {
  //     setComparedData({
  //       data: [],
  //       leftMetrics: [],
  //       rightMetrics: [],
  //       dimensions: [],
  //     });
  //   } else if (value.indexOf("_") > -1) {
  //     const dates = value.split("_");
  //     const startDate = dates[0];
  //     const endDate = dates[1];

  //     if (chart.type === "TABLE" && isDrillTable)
  //       updateDrillTableData(
  //         startDate,
  //         endDate,
  //         setComparedData,
  //         comparedData,
  //         true
  //       );
  //     else if (chart.type === "TABLE")
  //       updateTableTypeData(
  //         startDate,
  //         endDate,
  //         setComparedData,
  //         comparedData,
  //         true
  //       );
  //     else
  //       updateChartData(
  //         startDate,
  //         endDate,
  //         setComparedData,
  //         comparedData,
  //         true
  //       );
  //   } else {
  //     const startDate = moment(dateRange.startDate, "YYYY-MM-DD")
  //       .subtract(1, value)
  //       .format("YYYY-MM-DD");
  //     const endDate = moment(dateRange.endDate, "YYYY-MM-DD")
  //       .subtract(1, value)
  //       .format("YYYY-MM-DD");

  //     if (chart.type === "TABLE" && isDrillTable)
  //       updateDrillTableData(
  //         startDate,
  //         endDate,
  //         setComparedData,
  //         comparedData,
  //         true
  //       );
  //     else if (chart.type === "TABLE")
  //       updateTableTypeData(
  //         startDate,
  //         endDate,
  //         setComparedData,
  //         comparedData,
  //         true
  //       );
  //     else
  //       updateChartData(
  //         startDate,
  //         endDate,
  //         setComparedData,
  //         comparedData,
  //         true
  //       );
  //   }
  // };

  const handleChartChange = (property, value, addChart) => {
    var updated = false;
    var paramKeys = [];
    switch (property) {
      case "compareWith":
        if (chart.compareWith !== value) {
          chart.compareWith = value;
        }
        break;
      case "title":
        if (chart.title !== value) {
          chart.title = value;
          chartCopy.title = value;
          setTitle(value);
        }
        break;
      case "dimensions":
        if (
          chart.dimensions?.length === value.length &&
          chart.dimensions?.every((dimension, index) =>
            Chart.isDimensionEqual(dimension, value[index])
          )
        ) {
        } else {
          chart.dimensions = value;
        }
        break;
      case "leftMetrics":
        if (
          // chart.leftMetrics.length === value.length &&
          // chart.leftMetrics.every((metric, index) =>
          //   metric.isEqual(value[index])
          // )
          false
        ) {
        } else {
          chart.leftMetrics = value;
        }
        break;
      case "rightMetrics":
        if (!chart.rightMetrics) {
          break;
        }
        if (
          // chart.rightMetrics.length === value.length &&
          // chart.rightMetrics.every((metric, index) =>
          //   metric.isEqual(value[index])
          // )
          false
        ) {
        } else {
          chart.rightMetrics = value;
        }
        break;
      case "filter":
        if (!chart?.filter || !chart.filter.isEqualAll(value)) {
          if (addChart && (!chart?.filter || !chart.filter.isEqual(value))) {
            paramKeys = ["filter"];
          }
          chart.filter = value;
        }
        updated = !!addChart;
        break;
      case "filters":
        /*
          value should be { "accountId1": filterObject,"accountId2": filterObject }
        */
        chart.filters = value;
        chart.filter.ids = Object.entries((value ?? {})).reduce((acc, curr) => {
          const [accountId, filter] = curr;
          if (!accountId) return acc; //need to handle 
          acc[accountId] = filter.id;
          return acc;
        }, {});
        updated = !!addChart;
        break;
      case "table":
        if (chart.table?.id !== value?.id) {
          chart.copyValues(
            Chart.new(
              chart.channelType,
              chart.title,
              chart.type,
              value,
              chart.gridPosition,
              [],
              undefined,
              undefined,
              undefined,
              chart.account
            )
          );
        }
        break;
      case "removeChart":
        if (chart.type !== value) {
          setCurrentChartType(value);
          if (value === "ADD") {
            setIsAddChart(true);
            chart.copyValues(
              Chart.new(
                ChannelType.Default,
                "",
                value,
                null,
                { ...chart.gridPosition, minW: 3, minH: 1 }
              )
            );
            const chartObj = chart.toJSON();
            delete chartObj.id;
            delete chartObj.gridPosition;
            paramKeys = Object.keys(chartObj);
            updated = true;
          }
        }
        break;
      case "type":
        if (chart.type !== value) {
          setCurrentChartType(value);
          if (value === "ADD" || isAddChart || addChart) {
            setIsAddChart(true);
            chart.copyValues(
              Chart.new(
                chart.channelType,
                "",
                value,
                null,
                {
                  ...chart.gridPosition,
                  w: (!["ADD", "KPI", "TEXT"].includes(value) && chart.gridPosition.w < 6 ? 6 : chart.gridPosition.w),
                  h: (!["ADD", "KPI", "TEXT"].includes(value) && chart.gridPosition.h < 2 ? 2 : chart.gridPosition.h),
                  minW: ["ADD", "KPI", "TEXT"].includes(value) ? 3 : 6,
                  minH: ["ADD", "KPI", "TEXT"].includes(value) ? 1 : 2
                },
                [],
                undefined,
                undefined,
                undefined,
                chart.account
              )
            );
            if (value === "ADD") {
              const chartObj = chart.toJSON();
              delete chartObj.id;
              delete chartObj.channel;
              // delete chartObj.gridPosition;
              paramKeys = Object.keys(chartObj);
              updated = true;
            }
          } else {
            chart.type = value;
            chartCopy.type = value;
            paramKeys = ["type"];
          }
        }
        break;
      case "channel":
        if (
          chart.channelType !== value.type &&
          value.type !== chart.channelType.id
        ) {
          setIsAddChart(true);
          setChannel(value);
          setAccount(null);
          chart.copyValues(
            Chart.new(
              value.isBlend
                ? { id: value.type, name: value.title }
                : value.type,
              "",
              chart.type,
              null,
              chart.gridPosition
            )
          );
        }
        break;
      case "account":
        if (
          (typeof chart.channelType !== "object") || //have removed this check chart.account?.title !== value?.title need to revisit this check
          (typeof chart.channelType === "object" &&
            (Object.keys(value ?? {}).length !==
              Object.keys(chart.account ?? {}).length ||
              !Object.keys(value ?? {}).every(
                (dataSource) =>
                  value[dataSource]?.title ===
                  chart.account?.[dataSource]?.title
              )))
        ) {
          // setIsAddChart(true);
          setAccount(channel?.isBlend ? null : Object.keys(value));
          chart.account = value;
          chart.accountsTemp = value;
          setAccount_R(value);
          // chart.copyValues(
          // Chart.new(
          //   chart.channelType, "", chart.type, null, chart.gridPosition,
          //   [], undefined, undefined, undefined, value
          // )
          // );
        }
        break;
      case "chartStyle":
        if (chart.chartStyle !== value) {
          chart.chartStyle = { ...chart.chartStyle, ...value };
          chartCopy.chartStyle = { ...chart.chartStyle, ...value };
          setChartStyle(chart.chartStyle);
        }
        break;
      case "dataLevel":
        if (chart.dataLevel !== value) {
          chart.dataLevel = value;
        }
        break;
      case "excludeDeleted":
        if (chart.excludeDeleted !== value) {
          chart.excludeDeleted = value;
        }
        break;
      case "saveChanges":
        const chartObj = chart.toJSON();
        delete chartObj.id;
        if (chart.gridPosition.w === chartCopy.gridPosition.w && chart.gridPosition.h === chartCopy.gridPosition.h) {
          delete chartObj.gridPosition;
        }
        delete chartObj.gridId;
        paramKeys = Object.keys(chartObj);
        updated = true;
        break;
      case "close":
        paramKeys = [];
        if (chart.title !== initialChart.title) {
          paramKeys.push("title");
        }
        if (
          Object.keys(chart.chartStyle).some(
            (key) => JSON.stringify(chart.chartStyle[key]) !== JSON.stringify(initialChart.chartStyle[key])
          )
        ) {
          paramKeys.push("chartStyle");
        }
        if (!chart.isEqual(chartCopy)) {
          setGetData(false);
          chart.copyValues(Chart.fromJSON(chartCopy.toJSON()));
          setIsAddChart(chartCopy.type === "ADD");
          setChart(Chart.fromJSON(chart.toJSON()));
          setCurrentChartType(chartCopy.type);
          setChannel(GetChannel(chartCopy.channelType) ?? {});
          setAccount(reportAdAccs.find(acc => acc?.id === chartCopy?.accounts?.[0]));
        }
        break;
      default:
        break;
    }
    chart.validate();
    if (updated) {
      chartCopy.copyValues(Chart.fromJSON(chart.toJSON()));
      setChart(Chart.fromJSON(chart.toJSON()));
      setDrillData({ drillIndex: 1, selected: [] });
    }

    if (paramKeys.length > 0) {
      const chartObj = chart.toJSONfiltered();
      _updateChart({
        report_id: reportId,
        tab_id: tabId,
        grid_id: gridId,
        updates: [
          {
            chart_id: chart.chartId,
            id: chart.id,
            update_type: "update",
            params: paramKeys
              .map((param) => ({ [param]: chartObj[param] ?? null }))
              .reduce((obj, curr) => ({ ...obj, ...curr }), {}),
          },
        ],
      });
    }
  };

  const getErrorImage = (error) => {
    switch (error) {
      case "No account connected":
      case "Both Reporting date-range and Comparison date-range cannot be same.":
        return <NoAccountIcon />;
      case 'Please select Ad Account to populate the chart.':
      case "Required account id has not been selected":
      case "No field selected. ":
      case "No field selected. Please check the mapping once within data blend source.":
        return <SelectViewIdIcon />;
      case "Either remove current custom selection or select all required account IDs for blend":
      case "Some selected metric fields doesn't exist. Please check the mapping once within data blend source.":
      case "Some selected dimension fields doesn't exist. Please check the mapping once within data blend source.":
      case "Some selected metric fields doesn't exist. It may exist on some other account.":
      case "Some selected dimension fields doesn't exist. It may exist on some other account.":
        return <SelectionErrorIcon />;
      case "Some of the custom dimensions added in this source are deleted. Please update source dimensions and keys!":
        return <MappingDeletedIcon />;
      case "Chart type not selected":
        return <TypeMissingIcon />;
      case "Account Token Expired!":
        return <AccountExpiredIcon style={{ width: "120px" }} />
      case "Timeout! Please try again in some time.":
        return <img src={timeoutError} />
      default:
        return <DefaultErrorIcon />;
    }
  };

  const redirect_connect_source = (data) => {
    history.push(data.redirect);
  };

  const getChartObject = (type, data) => {
    data = { ...data, "leftMetrics": getMetrics(metrics, data.leftMetrics), "rightMetrics": getMetrics(metrics, data.rightMetrics) }
    try {
      if (!data.data || data.error) {
        return (
          <div
            style={{
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            {getErrorImage(data.error)}
            <h3 className="lato " style={{ margin: "10px" }}>
              {data.error}
            </h3>
            {(data.canReload || data.redirect) && (
              <Button
                style={{
                  textTransform: "none",
                  borderRadius: "6px",
                  color: "#0869FB",
                  textDecoration: data.redirect ? "underline" : "none",
                }}
                startIcon={
                  data.canReload ? <AutorenewIcon /> : <RedirectIcon />
                }
                onClick={() => {
                  data.redirect ? redirect_connect_source(data) : forceUpdate();
                }}
              >
                {data.canReload ? "Refresh" : "Connect Data Sources"}
              </Button>
            )}
          </div>
        );
      } else if (
        (currentChartType === "GEO" &&
          (!chart.dimensions?.[0] ||
            (!chart.dimensions[0].name.toLowerCase().includes("country") && chart.channelType !== "appsflyer")
            || (chart.channelType === "appsflyer" && !chart.dimensions[0].name.toLowerCase().includes("geo"))
          )
        )
      ) {
        return (
          <div
            style={{
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <MappingDeletedIcon />
            <h3 className="lato" style={{ margin: "10px" }}>
              First Dimension must be country field for GEO chart.
            </h3>
          </div>
        );
      }

      if (chart.type !== "TEXT" && drillData.drillIndex <= 1 && data.data.length === 0) {
        return (
          <div
            style={{
              margin: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <NoDataIcon />
            <h3 className="lato" style={{ margin: "10px" }}>
              Nothing to be displayed on this view id
            </h3>
          </div>
        );
      }

      switch (type) {
        case "TEXT":
          return <TextareaAutosize minRows={2} placeholder="Enter some text here...."
            value={title}
            onChange={(e) => handleChartChange("title", e.target.value)}
            onFocus={() => { setTextFocus(true) }}
            onBlur={() => { setTextFocus(false); handleChartChange("close") }}
            style={{
              height: "100%", width: "100%", resize: "none", borderRadius: "8px",
              padding: "16px", outline: "none", borderColor: textFocus ? "#0869FB" : "#EAEAEC",
              fontSize: chart.chartStyle.titleStyle.fontSize,
              lineHeight: (8 + Number(chart.chartStyle.titleStyle.fontSize)) + "px",
              fontFamily: chart.chartStyle.titleStyle.font,
              fontWeight: chart.chartStyle.titleStyle.fontFormat.includes("bold") && "bold",
              fontStyle: chart.chartStyle.titleStyle.fontFormat.includes("italic") && "italic",
              textDecorationLine: chart.chartStyle.titleStyle.fontFormat.includes("underline") && "underline",
              color: chart.chartStyle.titleStyle.color,
              overflow: "auto",
            }} />
        default:
        case "LINE":
        case "BAR":
        case "STACKBAR":
        case "LINEBAR":
        case "AREA":
        case "COLUMN":
          return <TrendingCharts data={data} chartStyle={chartStyle} type={type} />;
        case "BAR3D":
        case "STACKBAR3D":
          return <TrendingCharts3D data={data} chartStyle={chartStyle} type={type} />;
        case "PIE":
        case "PIE3D":
        case "DONUT":
        case "DONUT3D":
        case "FUNNEL":
          return <GrouppingCharts data={data} chartStyle={chartStyle} type={type} />;
        case "KPI":
          return <KPIChart data={data} chartStyle={chartStyle} type={channel.type} />;
        case "TABLE":
          return <DrillDownTable data={filterData} drillData={drillData} setDrillData={setDrillData} chartStyle={chartStyle} type={channel.type} isTable={isTable} isDrillTable={isDrillTable} chartDimensions={chart.dimensions} />;
        case "BUBBLE":
          return <BubbleChart data={data} chartStyle={chartStyle} type={channel.type} />
        case "SCATTER":
          return <ScatterChart data={data} chartStyle={chartStyle} type={channel.type} />
        case "WORDCLOUD":
          return <WordCloudChart data={data} chartStyle={chartStyle} type={channel.type} />
        case "TREEMAP":
          return (
            <TreemapChartWrapper>
              {({ HighchartsInstance }) => (
                <TreemapChart data={data} chartStyle={chartStyle} type={channel.type} HighchartsInstance={HighchartsInstance} />
              )}
            </TreemapChartWrapper>
          )
        case "HEATMAP":
          return <HeatmapChart data={data} chartStyle={chartStyle} type={channel.type} />
        case "WATERFALL":
          return <WaterfallChart data={data} chartStyle={chartStyle} type={channel.type} />
        case "GEO":
          return <Geochart data={data} chartStyle={chartStyle} type={channel.type} />
        case "ADD":
          return <div />;
      }
    } catch (e) {
      return <Alert severity="error">Error loading chart</Alert>;
    }
  };

  let titleHeight = chart.chartStyle.titleStyle.fontSize <= 40 ? 40 : (8 + Number(chart.chartStyle.titleStyle.fontSize));
  const audiences = channel?.isBlend && state.audiences;


  return (
    <>
      {!isAddChart ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: "0",
              height: titleHeight,
            }}
          >
            {(currentChartType !== "KPI" && currentChartType !== "TEXT") ? (
              <Tooltip title={title} placement="top-start">
                <p
                  style={{
                    fontSize: chart.chartStyle.titleStyle.fontSize,
                    lineHeight: (8 + Number(chart.chartStyle.titleStyle.fontSize)) + "px",
                    fontFamily: chart.chartStyle.titleStyle.font,
                    fontWeight: chart.chartStyle.titleStyle.fontFormat.includes("bold") && "bold",
                    fontStyle: chart.chartStyle.titleStyle.fontFormat.includes("italic") && "italic",
                    textDecorationLine: chart.chartStyle.titleStyle.fontFormat.includes("underline") && "underline",
                    color: chart.chartStyle.titleStyle.color,
                    width: "45%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "left",
                    margin: 0
                  }}
                >
                  {title}
                </p>
              </Tooltip>
            ) : (
              <div style={{ width: "45%" }} />
            )}

            <div className="drag-handle" style={{ width: "10%" }}>
              <img src={SixDots} alt="dots" />
            </div>

            <div
              style={{
                display: "flex",
                width: "45%",
                justifyContent: "flex-end",
                paddingBottom: "10px",
              }}
            >
              {(currentChartType !== "KPI" && currentChartType !== "TEXT") && (
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                  aria-label="settings"
                >
                  {currentChartType === "TABLE" ? <div className="search_box" style={{ height: "32px" }}>
                    <SearchToggle inputProps={{ style: { height: "32px", padding: "0px" } }} setFilterText={setFilterText} filterDataOptions={filterDataOptions} filterText={filterText} filterKey={filterKey} setFilterKey={setFilterKey} />
                  </div> : ""}
                  <div
                    onClick={() => {
                      if (currentChartType === "TABLE") {
                        setIsDrillTable(!isDrillTable);
                      }
                      else if (currentChartType !== "KPI") {
                        setIsTable(!isTable)
                      }
                      else {
                        setIsTable(isTable)
                      }

                    }}
                    style={{
                      display: "flex",
                      border: "1px solid #EAEAEC",
                      padding: 5,
                      borderRadius: 4,
                      height: "30px",
                    }}
                  >
                    <img
                      height={18}
                      src={
                        currentChartType === "TABLE" ? (isDrillTable ? DrilledTableLogo : (!isTable ? TableLogo : "")) : (currentChartType !== "KPI" ? (isTable ? GraphLogo : TableLogo) : "")
                      }
                      alt="table"
                      className={
                        (currentChartType !== "TABLE" && isTable) ||
                          (currentChartType === "TABLE" && isDrillTable)
                          ? "selected-svg"
                          : "selected-svg"
                      }
                    />
                  </div>
                </div>
              )}

              <div data-tut="edit_chart">
                <div
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  style={{
                    display: "flex",
                    height: "30px",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #eaeaec",
                    width: "30px",
                    borderRadius: 4,
                    cursor: "pointer",
                  }}
                >
                  <IconButton
                    disableRipple
                    style={{ padding: 0, height: "100%" }}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    <img src={ThreeDots} alt="dots" />
                  </IconButton>
                </div>

                <Menu
                  id="menu-appbar"
                  className="smooth-shadow"
                  anchorEl={anchorEl}
                  keepMounted
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  sx={{
                    marginTop: "10px",
                    "& 		.MuiMenu-paper": {
                      borderRadius: "8px",
                      padding: "8px",
                      minWidth: "168px",
                      gap: "12px",
                    },
                    "& .MuiMenuItem-root": {
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "20px",
                    },
                  }}
                >
                  {!exportData ? (
                    <>
                      <Tooltip
                        title={
                          !(editChart || !isLoading)
                            ? "Wait for the chart to load"
                            : ""
                        }
                        placement="top"
                        arrow
                      >
                        <div>
                          <MenuItem
                            data-tut="edit_chart_options"
                            onClick={() => {
                              dispatch(
                                buttonClickSaveAndUpdate("UPDATE_CHART")
                              );
                              handleToggleEditDrawer(chart, handleChartChange);
                              handleClose();
                              shared ? handleMixPanelData(EVENTS.edit_data_shared_report, { report, chart }) : handleMixPanelData(EVENTS.click_edit_widget, { report_name: props?.report });
                            }}
                            disabled={!(editChart || !isLoading)}
                          >
                            <EditIcon
                              style={{
                                marginRight: "12px",
                                width: "20px",
                                height: "20px",
                              }}
                            />{" "}
                            Edit Widget
                          </MenuItem>
                        </div>
                      </Tooltip>
                      <MenuItem onClick={handleRemoveChart}>
                        <DeleteNewIcon
                          style={{
                            marginRight: "12px",
                          }}
                        />
                        Remove Chart
                      </MenuItem>
                      <MenuItem
                        onClick={() => setExportData(true)}
                        disabled={!data || !data.data || data.data.length === 0}
                      >
                        <ExportIcon
                          style={{
                            marginRight: "12px",
                          }}
                        />{" "}
                        Export
                      </MenuItem>
                    </>
                  ) : (
                    <>
                      <MenuItem
                        style={{
                          paddingLeft: "10px",
                          color: "#00000099",
                          fontSize: "14px",
                        }}
                        onClick={() => setExportData(false)}
                      >
                        <ArrowBackIcon style={{ width: "16px" }} />
                        <p style={{ margin: "auto 5px", fontWeight: "bold" }}>
                          {" "}
                          Export
                        </p>{" "}
                      </MenuItem>
                      {/* <MenuItem
                        style={{ paddingLeft: "30px" }}
                        onClick={handlePdfExport}
                      >
                        PDF Format
                      </MenuItem> */}
                      <MenuItem
                        style={{ paddingLeft: "30px" }}
                        onClick={onCapturePNG}
                      >
                        PNG Format
                      </MenuItem>
                      <MenuItem
                        style={{ paddingLeft: "30px" }}
                        onClick={onCaptureJPG}
                      >
                        JPG Format
                      </MenuItem>
                      <MenuItem
                        style={{ paddingLeft: "30px" }}
                        onClick={handleExport}
                      >
                        CSV Format
                      </MenuItem>
                    </>
                  )}
                </Menu>
              </div>
            </div>
          </div>
          {isLoading || isCompareLoading ? (
            <ChartLoader />
          ) : (
            <div
              id={"Chart" + chart.id}
              style={{
                height: `calc(100% - ${titleHeight + 20}px)`,
                width: "auto",
                display: "flex",
                overflow: "hidden",
              }}
            >
              <Errorhandler errorMessage="Error Loading Chart">
                <Suspense fallback={<ChartLoader />}>
                  {getChartObject(isTable ? "TABLE" : currentChartType, data)}
                </Suspense>
              </Errorhandler>
            </div>
          )}
          {currentChartType !== "TEXT" && <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {!data.footerError && (
                  <Tooltip
                    placement="bottom-start"
                    PopperProps={{
                      sx: {
                        "& .MuiTooltip-arrow": {
                          color: "white",
                        },
                        "& .MuiTooltip-tooltip": {
                          backgroundColor: "white",
                          color: "black",
                          marginBottom: "20px",
                          filter: "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))",
                          padding: "10px 25px",
                        },
                      },
                    }}
                    title={
                      !channel.isBlend ? (
                        <Box display="flex" flexDirection="column">

                          <Box display="flex" alignItems="center" pb=".5rem">  <img src={channel.icon} style={{ width: "16px", height: "16px", marginRight: "5px" }} /> {channel?.title} ({Object.values(account_R).length}) </Box>
                          {Object.values(account_R).map(acc => <li>{acc?.title}({acc?.id})</li>)}
                        </Box>
                      ) : (
                        <Box>
                          {audiences?.map(
                            (audience, index) => {
                              audience = audience[0];
                              const channel = GetChannel(audience?.dataSource);
                              let metaData = audience?.metaData ?? {};
                              return (
                                <div
                                  style={{ borderRadius: "4px", padding: "3px" }}
                                  key={channel?.type ?? ""}
                                  value={channel?.title ?? ""}
                                >

                                  <span style={{ paddingLeft: "15px" }}>
                                    <img src={channel.icon} style={{ width: "16px", height: "16px", marginRight: "15px" }} />
                                    {metaData?.name ?? metaData?.title ?? ""} ({metaData?.id})
                                  </span>
                                </div>
                              );
                            }
                          )}
                        </Box>
                      )
                    }
                    arrow
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img src={channel?.icon} height="20" width="20" style={{ marginLeft: "10px" }} />
                      <p className="inter bold account-footer">
                        {!channel.isBlend
                          ? Object.values(account_R).map(acc => acc?.title).join(", ")
                          : state?.name ?? ""}
                      </p>
                    </div>
                  </Tooltip>
                )}
              </div>
              {(isAnyMetricsDeleted || data?.error === "Some columns used in the chart seems to be deleted, please remove them to populate the chart!") && <img src={deletedColumnIcon} height="20" width="20" />}
            </div>
            {!data.footerError &&
              (!!chart.filter?.id || Object?.values(chart?.filters ?? {}).length > 0 ||
                chart.leftMetrics.filter(
                  (metric) => (metric.metric && metric.filter?.name) || Object.keys(metric.filters ?? {}).length > 0
                ).length > 0 ||
                chart.rightMetrics?.filter(
                  (metric) => (metric.metric && metric.filter?.name) || Object.keys(metric.filters ?? {}).length > 0
                )?.length > 0) ? (
              <Tooltip
                PopperProps={{
                  sx: {
                    "& .MuiTooltip-arrow": { color: "white" },
                    "& .MuiTooltip-tooltip": {
                      backgroundColor: "white",
                      color: "black",
                      marginBottom: "20px",
                      filter: "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))",
                      padding: "10px 25px",
                    },
                  },
                }}
                title={
                  <Box sx={{ borderRadius: "10px", }}>
                    {(ChartFilterSupport(currentChartType) || channel.isBlend) ? (
                      <Box display="flex" flexDirection="column">
                        <span style={{ color: "#787878" }}>Filter Applied </span>

                        {channel?.isBlend ?

                          <Box display="flex">
                            <li
                              style={{
                                fontSize: "12px",
                                fontFamily: "Inter",
                                fontWeight: "400",
                              }}
                            >
                              {chart?.filter?.name}
                            </li>

                          </Box>
                          : Object.values(chart?.filters ?? {})?.map((item) => {

                            return <Box display="flex">
                              <li
                                style={{
                                  fontSize: "12px",
                                  fontFamily: "Inter",
                                  fontWeight: "400",
                                }}
                              >
                                {item?.name} ({item?.account?.title ? item?.account?.title : item?.account?.metaData?.title})
                              </li>

                            </Box>
                          })}
                      </Box>

                    ) : (<>
                      {
                        (chart.leftMetrics.concat(chart.rightMetrics ?? []))?.map((metric, index) => {
                          if ((metric.metric && metric.filter?.name) || Object.keys(metric.filters ?? {}).length > 0) {
                            return <Box pb=".5rem">
                              <Box display="flex" alignItems="center">
                                <span style={{ color: "#787878" }}>Filter Applied on Metric: </span>
                                <span style={{ fontSize: "12px", fontFamily: "Inter", fontWeight: "400", color: GetColor(index, chart?.chartStyle.palette) }}> {" " + metric?.metric?.name} </span>
                              </Box>
                              {
                                Object.values(metric?.filters ?? {})?.map(item => {
                                  return <li>
                                    {/* <span style={{ color: "#787878" }}>Filter: </span> */}
                                    <span style={{ fontSize: "12px", fontFamily: "Inter", fontWeight: "400", color: GetColor(index, chart?.chartStyle.palette) }}> {item?.name} ({item?.name} ({item?.account?.title ? item?.account?.title : item?.account?.metaData?.title})) </span>
                                    <br />
                                  </li>
                                })
                              }
                            </Box>

                          } else {
                            return "";
                          }
                        })
                      }
                    </>
                    )}
                  </Box>
                }
                arrow
                placement="top-end"
              >
                <FilterIcon style={{ padding: "2px" }} />
              </Tooltip>
            ) : null}
          </div>}
        </>
      ) : (
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={removeChart}
            style={{ position: "absolute", right: "1.5vw", top: "2vh" }}
          >
            <DeleteIcon />
          </IconButton>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch(buttonClickSaveAndUpdate("CREATE_NEW_CHART"));
              handleChartChange("type", "ADD");
              handleToggleEditDrawer(chart, handleChartChange);
            }}
          >
            <img src={AddChartLogo} alt="Add Chart" />
            <br />
            <h4 style={{ marginTop: "10px" }} className="inter">
              Add Chart
            </h4>
          </div>
        </div>
      )}
    </>
  );
});

export default ChartWrapper;
