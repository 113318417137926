import { Accordion, AccordionDetails, AccordionSummary, IconButton, Typography, } from "@mui/material";
import { ArrowBackIosNew, Clear, ExpandMore, } from "@mui/icons-material";
import React, { useContext } from "react";
import { Filter, Metric } from "../../models/chart";
import TextFieldElement from "./TextFieldElement";
import MetricList from "./MetricList";
import { useSelector } from "react-redux";
import { ReactComponent as FilterIcon } from "../../Assets/Icons/openFilter.svg";
import FilterListModal from "../Filteration/FilterListModal";
import FilterPopup from "../Filteration/filterpopup/FilterPopup";
import { useDispatch } from "react-redux";
import { updateFilterChartMap } from "../../api/dashboard/action";
import FilterDrop from "./FilterDrop";
import { ShareContext } from "./Sidebar";
import { filterCurrentAccount } from "./util";

const FilterCompo = ({ shared,setIsFilterOpen,filterString,setFilterString,setMetricFilter,chart,onChange,currentMetric,accountId,setCurrentFilter,updateMetricFilter,axisFilters,selectedAccounts,filter,isBlend = false }) => {
  const filterAccount = filter?.account?.name ?? filter?.account?.title ?? filter?.account?.metaData?.name ?? filter?.account?.metaData?.title ?? filter?.account?.id;
  return <div
          style={{
            marginTop: "15px",
            marginBottom: "5px",
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <div
            className="filter-list"
            onClick={() => {
              if(!shared && !chart?.isBlends){
                setCurrentFilter();
                setIsFilterOpen(true);
              }else if(!shared){
                setIsFilterOpen(true);
              }
            }}
            style={{ cursor: !shared && "pointer" }}
          >
            {!isBlend && <span style={{ fontSize: "12px",display: "block",color: "#ABACB4" }}>{selectedAccounts?.length > 1 && filterAccount}</span>}
            {filterString}
          </div>
          <IconButton
            onClick={() => {
              if(accountId){
                const tempFilters = JSON.parse(JSON.stringify(axisFilters));
                const metricKey = Object.keys(axisFilters)[0];
                delete tempFilters[metricKey][accountId];
                updateMetricFilter([tempFilters]);
              }else {
                setFilterString(null);
                setMetricFilter(Filter.new(chart));
                onChange(Metric.new(chart, currentMetric.metric));
              }
            }}
            disableRipple
            disableFocusRipple
            aria-label="delete"
          >
            <Clear />
          </IconButton>
      </div>
}

export default function SingleMetricSettings({
  chart,
  channel,
  currentMetric,
  dimension,
  isKPI,
  onChange,
  onClose,
  handleChartChange,
  updateFilter,
  handleAllChartsForceReload,
  accountCols,
  channelCols,
  selectedAccounts,
  axis,
  axisFilters,
  setAxisFilters,
  currentIndex
}) {
  const { shared } = useContext(ShareContext);
  const [isFilterOpen, setIsFilterOpen] = React.useState(false);
  const [table, setTable] = React.useState(chart.table);
  const state = useSelector((state) => channel.getState(state));
  const tables = state.tables;
  const dataLevels = (state.dataLevels?.[table?.id] ?? []).map((level) => ({
    id: level,
    name: level,
  }));
  const [dataLevel, setDataLevel] = React.useState({
    id: chart.dataLevel,
    name: chart.dataLevel,
  });
  const baseMetrics = channel.isBlend
    ? channelCols.metrics
    : Array.isArray(state.metrics)
      ? state.metrics
      : state.metrics?.[table?.id + (dataLevel?.id ? `:${dataLevel.id}` : "")] ??
      [];
  const customMetrics = accountCols
    ? accountCols.metrics
    : Array.isArray(state.customMetrics)
      ? state.customMetrics
      : state.customMetrics?.[
      table?.id + (dataLevel?.id ? `:${dataLevel.id}` : "")
      ] ?? [];
  const metrics = baseMetrics.concat(customMetrics);
  const dispatch = useDispatch();
  const _updateFilterChartMap = (_) => dispatch(updateFilterChartMap(_));
  const filterChartMap = useSelector((state) => state.dashboard.filterChartMap);
  const allFilterList = useSelector(state => state.filters.allFilters);

  const [filterListOpen, setFilterListOpen] = React.useState(false);
  const [metricFilter, setMetricFilter] = React.useState(currentMetric.filter);
  const [currentFilter, setCurrentFilter] = React.useState(null);
  const [filterString, setFilterString] = React.useState(
    metricFilter.toString()
  );
  const metricFilters = React.useMemo(() => {
    const metricKey = Object.keys(axisFilters)?.[0];
    if(metricKey){
      const filtersMapped = Object.keys(axisFilters[metricKey]).reduce((acc,curr) => {
        acc[curr] = curr;
        return acc;
      },{});
      return filtersMapped;
    }
    return {};
},[axisFilters]);

  const updateMetricFilter = (filter) => {
    if(channel.isBlend){
      updateFilter(filter);
      setMetricFilter(filter);
      setCurrentFilter(null);
      setFilterString(filter.toString());
      if (!filter.id) return;
      let allCharts = filterChartMap[filter.id] ?? [];
      if (!allCharts.includes(chart.id)) {
        _updateFilterChartMap({
          ...filterChartMap,
          [filter.id]: (filterChartMap[filter.id] ?? []).concat(chart.id),
        });
      }
    }else {
      setCurrentFilter(null);
      /* when we delete filter */
      if(Array.isArray(filter)){
        const tempFilters = filter[0];
        setAxisFilters((prev) => {
          const axisFilters = { ...prev[axis], ...tempFilters };
          const tempFilter = { ...prev, [axis]: { ...axisFilters}} ;
          updateFilter(tempFilters);
          return tempFilter;
        });
      }else {
        /* when we add filter */
        const account = filter?.currentAccount?.id;
        const filtersTemp = JSON.parse(JSON.stringify(axisFilters));
        const metricKey = Object.keys(axisFilters)[0];
        if(metricKey){
          filtersTemp[metricKey][account] = { ...filter };
        }else {
          const key = currentMetric?.metric?.id+"###"+axis+"###"+currentIndex;
          filtersTemp[key] = {};
          filtersTemp[key][account] = filter;
        }
        setAxisFilters((prev) => {
          const axisFilters = { ...prev[axis], ...filtersTemp };
          const tempFilter = { ...prev, [axis]: { ...axisFilters}};
          updateFilter(filtersTemp);
          return tempFilter;
        });
      }
    }
  };

  React.useEffect(() => {
    if (table && table !== chart.table) {
      handleChartChange("table", table);
      {
        channel.type === "tiktok-ads" &&
          setDataLevel(dataLevels[table?.id]?.[0] ?? { id: "", name: "" });
      }
      onChange(Metric.new(chart));
      // if (!metrics.length) {
      //   dispatch(channel.actions?.getColumns(table));
      // }
    }
  }, [table]);

  React.useEffect(() => {
    if (
      channel.type !== chart.channelType &&
      channel.type !== chart.channelType.id
    ) {
      setTable(null);
      onChange(Metric.new(chart));
    }
  }, [channel]);

  React.useEffect(() => {
    if (chart.dataLevel !== dataLevel.id) {
      handleChartChange("dataLevel", dataLevel?.id);
    }
  }, [dataLevel]);

  return (
    <div>
      {!isKPI && (
        <IconButton
          onClick={onClose}
          sx={{ marginRight: "100px", marginLeft: "10px" }}
        >
          <ArrowBackIosNew fontSize="small" />
        </IconButton>
      )}
      <Accordion
        defaultExpanded={true}
        sx={{
          boxShadow: "none",
          "& .Mui-expanded": { marginBottom: "0px" },
          marginBottom: "10px",
          width: "100%",
        }}
      >
        <AccordionSummary
          sx={{ maxHeight: "30px", width: "100%", padding: "0px" }}
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            style={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "500",
              color: "#000000",
            }}
          >
            Edit Metric
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ padding: "10px 0px" }}>
          {/* report type */}
          {isKPI && tables && (
            <div>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#666666",
                }}
              >
                Report type
              </Typography>
              <MetricList
                channel={channel}
                metric={table}
                metrics={tables}
                isEditable={true}
                onClick={setTable}
                defaultTitle="Add Report Type"
              />
              <br />
            </div>
          )}

          {isKPI && tables && channel.type === "tiktok-ads" && (
            <div>
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#666666",
                }}
              >
                Data level
              </Typography>
              <MetricList
                channel={channel}
                metric={dataLevel}
                metrics={dataLevels}
                isEditable={true}
                onClick={setDataLevel}
                defaultTitle="Add Data Level"
              />
              <br />
            </div>
          )}

          {dimension && (
            <div>
              <h4 className="inter bold" style={{ marginBottom: "10px" }}>
                Dimension
              </h4>
              <TextFieldElement channel={channel} defaultValue={dimension} />
            </div>
          )}

          <div style={{ marginTop: "15px" }}>
            <Typography
              style={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "500",
                color: "#666666",
              }}
            >
              Metric
            </Typography>
            <MetricList
              key={channel + metrics?.length}
              channel={channel}
              metric={currentMetric?.metric}
              metrics={metrics}
              isEditable={true}
              onClick={(metric) => onChange(Metric.new(chart, metric))}
              isColumnThere={currentMetric?.metric ? (metrics?.find(m => m.id === currentMetric?.metric?.id) ?? false) : true}
              selectedAccounts={selectedAccounts}
            />
          </div>

          {(!channel.isBlend || isKPI) && (
            <div style={{ marginTop: "15px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "15px",
                  width: "92%",
                  marginBottom: "8px",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#666666",
                    margin: "auto 0px",
                  }}
                >
                  Filters
                </Typography>
                {chart.account && <Typography
                  style={{ color: "#0869FB", margin: "auto 0px", cursor: "pointer" }}
                  onClick={() => {
                    setFilterListOpen(true);
                  }}
                >
                  <FilterIcon /> See All
                </Typography>}
              </div>
              {!channel?.isBlend ? Object.entries(axisFilters ?? {}).map((metric) => {
                    const [_,filterObj] = metric;
                    return Object.entries(filterObj).map(([accountId,filter]) => {
                       return <FilterCompo 
                                setFilterString={setFilterString}
                                setMetricFilter={setMetricFilter}
                                onChange={onChange}
                                filterString={filter.name}
                                setIsFilterOpen={setIsFilterOpen}
                                shared={shared}
                                accountId={accountId}
                                chart={chart}
                                setCurrentFilter={() => setCurrentFilter(filter)}
                                updateMetricFilter={updateMetricFilter}
                                axisFilters={axisFilters}
                                selectedAccounts={selectedAccounts}
                                filter={filter}
                                isBlend={channel?.isBlend}
                              />
                    })
                  }): <FilterCompo 
                        setFilterString={setFilterString}
                        setMetricFilter={setMetricFilter}
                        onChange={onChange}
                        filterString={filterString}
                        setIsFilterOpen={setIsFilterOpen}
                        shared={shared}
                        chart={chart}
                      />}
                {selectedAccounts?.length !==
                    Object.keys(Object.values(axisFilters)?.[0] ?? {})
                      ?.length && (
                    <FilterDrop
                      setCurrentFilter={setCurrentFilter}
                      updateFilter={updateMetricFilter}
                      chart={chart}
                      channel={channel}
                      setIsFilterOpen={setIsFilterOpen}
                      selectedAccounts={selectedAccounts}
                      chartFilters={metricFilters}
                      allFilterList={allFilterList}
                    />
                  )}
            </div>
          )}
        </AccordionDetails>
      </Accordion>
      {isFilterOpen && (
        <FilterPopup
          accounts={
            channel?.isBlend ? (metricFilter.account ? [] : [chart.account]) :
            currentFilter ? filterCurrentAccount(selectedAccounts, currentFilter) : 
            selectedAccounts?.filter((account) => !metricFilters[account.id])
          }
          accountCols={{ [chart.account?.title]: accountCols }}
          blendChannelCols={channelCols}
          chart={chart}
          onClose={() => setIsFilterOpen(false)}
          currentFilter={currentFilter ?? metricFilter}
          chartFilter={metricFilter}
          onChange={(filter) => {
            updateMetricFilter(filter);
            handleAllChartsForceReload(filter.id);
          }}
        />
      )}
      {(!channel.isBlend || isKPI) && (
        <FilterListModal
          modal={filterListOpen}
          setModal={setFilterListOpen}
          chart={chart}
          chartFilter={metricFilter}
          onChange={(filter) => {
            updateMetricFilter(filter);
            setFilterListOpen(false);
          }}
          handleAllChartsForceReload={handleAllChartsForceReload}
          blendChannelCols={channelCols}
          selectedAccounts={ selectedAccounts }
        />
      )}
    </div>
  );
}
