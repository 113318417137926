import { Button, Grid, Link, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { connect } from "react-redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import CardComponent from "./CardComponent";
import { useHistory } from "react-router-dom";
import { checkAuth } from "../../api/channel/action";
import { AdsList, AttributionList, GetChannel } from "../../models/channel";
import { getUser } from "../../api/user/action";
import ShopifyConnectModal from "../Modal/ShopifyConnectModal";
import { sendBingCode } from "../../api/bing-ads/action";
import CloseIcon from "../Common/CloseIcon";
import { getAllReports } from "../../api/report/action";
import SelectingChannelErrorModal from "../Modal/SelectingChannelErrorModal";
import { sendLinkedlnCode } from "../../api/linkedin-ads/action";
import { sendTiktokCode } from "../../api/tiktok-ads/action";
import AppsflyerConnectModal from "../Modal/AppsflyerConnectModal";
import { setIsOnboarded } from "../../api/auth/action";
import AdjustConnectModal from "../Modal/AdjustConnectModal";

function TabPanel(props) {
    const { value, index, list } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && (
                <Box sx={{ p: 4 }}>
                    <Grid container
                        spacing={2}
                        alignItems="center"
                        justifyContent="center">
                        {list.map((item, i) =>
                            <CardComponent key={i} channel={item} tabSelected={value} />
                        )}
                    </Grid>
                </Box>
            )}
        </div>
    );
}

function Channel(props) {
    const query = new Map(props.location.search.replace("?", "").split("&").map(q => q.split("=")));
    const { authenticatedAccounts, checkAuth, getUser, getAllReports, sendBingCode, sendLinkedlnCode, sendTiktokCode, setIsOnboarded } = props;
    const [value, setValue] = React.useState(query.get("channel") && GetChannel(query.get("channel")) && AttributionList.find(c => c.type === query.get("channel")) ? 1 : 0);
    const [errorModal, setErrorModal] = React.useState(query.has("failure"));

    const history = useHistory();

    useEffect(() => {
        checkAuth();
        getUser();
        getAllReports();
        getBingCode();
        getLinkedlnCode();
        getTiktokCode();
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getBingCode = () => {
        const splitted = window.location.href.split("code=");
        if (splitted.length === 2) {
            const code = splitted[1].split("&state=")[0];
            sendBingCode({
                redirect_url: "selectchannels",
                code: code,
            });
        };
    }

    const getTiktokCode = () => {
        const splitted = window.location.href.split("&code=");
        if (splitted.length === 2 && splitted[1].endsWith("&state=tiktok-ads")) {
            const authCode = splitted[0].split("?auth_code=")[1];
            sendTiktokCode({
                redirect_url: "selectchannels",
                code: authCode
            });
        }
    }

    const getLinkedlnCode = () => {
        const splitted = window.location.href.split("code=");
        if (splitted.length === 2 && splitted[1].endsWith("&state=linkedin-ads")) {
            const code = splitted[1].split("&state=linkedin-ads")[0];
            sendLinkedlnCode({
                redirect_url: "selectchannels",
                code: code
            });
        }
    }

    return (
        <div>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{ backgroundColor: "#ffff", minHeight: "100vh" }}>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '10px', height: '30px', paddingTop: '20px' }}>
                    <CloseIcon />
                </Grid>
                <Grid item container xs={12} >
                    <div style={{ display: 'flex', justifyContent: 'center', minHeight: "85vh" }}>
                        <div style={{ width: value === 0 ? "55%" : "75%" }}>
                            <Grid
                                container
                                spacing={0}
                                justifyContent="center"
                                style={{ maxWidth: "100%" }}>
                                <Grid sx={{ height: '50px' }} item xs={12} align="center">
                                    <p style={{ fontSize: '30px', fontWeight: '600', margin: 0 }}>
                                        Select Channel for your report
                                    </p>
                                </Grid>
                                <Grid item xs={12} align="center">
                                    <Box sx={{ width: "100%", padding: '0px' }}>
                                        <Tabs style={{ margin: "4vh 0px 2vh 0px" }} value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                                            <Tab style={{ textTransform: "none", fontWeight: 600 }} label="Ads channels">Ads channels</Tab>
                                            <Tab style={{ textTransform: "none", fontWeight: 600 }} label="Attribution" >Attribution</Tab>
                                        </Tabs>
                                        <TabPanel value={value} index={0} list={AdsList} />
                                        <TabPanel value={value} index={1} list={AttributionList} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12} style={{ marginBottom: '20px' }}>
                                    <Typography style={{ textAlign: "center" }}>Finding difficulty in connecting your data sources? <Link onClick={() => history.push("/personalized-demo")}>Get Help</Link></Typography>
                                </Grid>
                            </Grid>

                        </div>
                    </div>
                </Grid>

                {authenticatedAccounts.length > 0 &&
                    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                            <Button
                                style={{ textTransform: 'none', fontSize: "16px", height: '40px', marginRight: '50px', marginBottom: '10px', minWidth: '120px', borderRadius: "8px", padding: "0 20px", background: "#0769FB" }}
                                onClick={() => { setIsOnboarded(); history.push("/managesources", { prevPath: history.location.pathname }) }}
                                variant="contained"
                                color="primary"
                            >
                                Choose your data sources
                            </Button>
                        </div>
                    </div>}
            </Grid>
            <SelectingChannelErrorModal modal={errorModal} setModal={setErrorModal} />
            <ShopifyConnectModal />
            <AppsflyerConnectModal />
            <AdjustConnectModal />
        </div >
    );
}

const mapStateToProps = state => ({
    authenticatedAccounts: state.channel.authenticatedAccounts
});

const mapDispatchToProps = dispatch => ({
    checkAuth: () => dispatch(checkAuth()),
    getUser: () => dispatch(getUser()),
    getAllReports: () => dispatch(getAllReports()),
    sendBingCode: (_) => dispatch(sendBingCode(_)),
    sendLinkedlnCode: (_) => dispatch(sendLinkedlnCode(_)),
    sendTiktokCode: (_) => dispatch(sendTiktokCode(_)),
    setIsOnboarded: () => dispatch(setIsOnboarded())
});

export default (connect(mapStateToProps, mapDispatchToProps)(Channel));
