import {
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import LeftBar from "../LeftBar/LeftBar";
import AccountModal from "../Modal/AccountModal";
import InfoIcon from "../../Assets/Icons/info_icon.svg";
import ThreeDots from "../../Assets/Images/logos_threedots.svg";
import { ReactComponent as AddSourceIcon } from "../../Assets/Icons/add_source_icon.svg";
import { ReactComponent as UserIcon } from "../../Assets/Icons/user_plus.svg";
import { ReactComponent as DeleteIcon } from "../../Assets/Icons/delete-icon.svg";
import { ReactComponent as DuplicateIcon } from "../../Assets/Icons/duplicate_icon.svg";
import { ReactComponent as NoReports } from "../../Assets/errorMsgImages/NoAuditData.svg";
import Arrow from "../../Assets/Icons/blue_right_arrow.svg";
import { connect } from "react-redux";
import "./ReportManagement.css";
import ShareModal from "../Modal/ShareModal";
import DeleteModal from "../Modal/DeleteModal";
import { useHistory } from "react-router-dom";
import { checkAuth } from "../../api/channel/action";
import { deleteReport, getAllReports } from "../../api/report/action";
import { getUser } from "../../api/user/action";
import SearchBar from "../Common/SearchBar";
import { GetChannel } from "../../models/channel";
import Loader from "../Common/Loader";
import SuccessModal from "../Modal/SuccessModal";
import FailureModal from "../Modal/FailureModal";
import moment from "moment";
import { handleMixPanelData, EVENTS } from "../../utils/mixpanelV2";

const ReportManagement = ({
  allReports,
  checkAuth,
  getAllReports,
  getUser,
  deleteReport,
}) => {
  const [isopen, setIsOpen] = useState(false);
  const [share, setIsShare] = useState(false);
  const [headerModal, setHeaderModal] = useState(false);
  const [filteredReports, setFilteredReports] = useState(allReports);
  const [reportsLoading, setReportsLoading] = useState(true);
  const [reportGeneration, setReportGeneration] = useState({ message: "none", error: "none", operation: "none" });
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => {
    setAnchorEl(null);
    setHeaderModal(false);
  };

  const handleOpen = () => setIsOpen(true);
  const handleOpen2 = () => setIsShare(true);

  useEffect(() => {
    if (!isopen) {
      setActiveReport(null);
    }
  }, [isopen]);

  useEffect(() => {
    checkAuth();
    getAllReports({ loading: () => setReportsLoading(false) });
    getUser();
  }, []);

  useEffect(() => {
    setFilteredReports(allReports);
  }, [allReports]);

  const handleReportDelete = (report) => {
    setReportsLoading(true)
    deleteReport({
      id: report.id, reportId: report.report_id, reload: false, callback: () => {
        setReportsLoading(false);
      }
    });
  };

  const containerRef = useRef(null);
  const [activeReport, setActiveReport] = useState(null);
  const [moreAccs, setMoreAccs] = useState(
    activeReport?.audiences?.length > 5
      ? activeReport.audiences.length - 5
      : 0
  );
  const selectedAccounts = (activeReport?.audiences ?? []).reduce(
    (channels, account) => {
      channels[account.channelType ?? account.source] = (
        channels[account.channelType ?? account.source] ?? []
      ).concat(account);
      return channels;
    },
    {}
  );

  useEffect(() => {
    const container = containerRef.current;
    if (container && container.scrollHeight > container.clientHeight) {
      setMoreAccs(moreAccs + 1);
    }
  }, [moreAccs]);

  useEffect(() => {
    setMoreAccs(
      activeReport
        ? activeReport?.audiences?.length > 5
          ? activeReport.audiences.length - 5
          : 0
        : -1
    );
  }, [activeReport]);

  const reportCallback = ({ message, error, operation = "Creation" }) => {
    setIsShare(false);
    setReportGeneration({ message, error, operation });
  }

  useEffect(() => {
    handleMixPanelData(EVENTS.view_reports_mg);
  }, []);

  return (
    <>
      <LeftBar />
      <div className="report_container">
        <div className="header">
          <Typography className="heading">Report Manager</Typography>
          <Button
            className="button"
            variant="contained"
            disableRipple
            onClick={() => {
              setActiveReport(null);
              handleOpen();
              handleMixPanelData(EVENTS.Click_create_report_rm);
            }}
          >
            Create New Report
          </Button>
        </div>
        <div className={`gallery`}>
          {allReports.length > 0 && (
            <div style={{ width: "20%", marginBottom: "24px" }}>
              <SearchBar
                filteredData={allReports}
                setFilteredData={setFilteredReports}
                keys={["report_name"]}
              />
            </div>
          )}
          {reportsLoading ? (
            <Loader />
          ) : allReports.length && filteredReports.length ? (
            <Grid
              xs={12}
              spacing={2}
              rowSpacing={2}
              container
              style={{ height: "calc(100% - 80px)", overflow: "auto" }}
              className="scrollBar0"
            >
              {filteredReports.map((report, index) => (
                <Grid item xs={3}>
                  <Box
                    className="block"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      minHeight: "290px",
                    }}
                  >
                    <div className="displayRowSpaced">
                      <div style={{ display: "flex", alignItems: "center", width: "calc(100% - 34px)" }}>
                      <Tooltip title={report.report_name} placement="top-start">
                        <Typography
                          style={{
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontWeight: "600",
                            fontStyle: "normal",
                            lineHeight: "22px",
                            marginRight: "6px"
                          }}
                        >
                          {report.report_name?.length > 19 ? report.report_name?.substring(0,19)+"..." : report.report_name}
                        </Typography>
                        </Tooltip>
                        <Tooltip arrow title={<>
                          <span>Created By {report.created_by} at {moment(report.CreatedAt).format('DD MMM h:mm A')}</span><br />
                          <span>Last Updated By {report.last_updated_by} at {moment(report.UpdatedAt).format('DD MMM h:mm A')}</span>
                        </>
                        }>
                          <img src={InfoIcon} alt="" />
                        </Tooltip>
                      </div>
                      <div
                        onClick={(e) =>
                          setAnchorEl({
                            target: e.currentTarget,
                            report,
                          })
                        }
                        style={{
                          display: "flex",
                          height: "24px",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "1px solid #eaeaec",
                          width: " 24px",
                          borderRadius: 4,
                          cursor: "pointer",
                          // position: "absolute",
                          // right: "11px",
                        }}
                      >
                        <IconButton
                          disableRipple
                          style={{
                            height: "24px",
                            width: "24px",

                            // top: "20px",
                            // height: "5%",
                          }}
                          onClick={(e) => {
                            setAnchorEl({
                              target: e.currentTarget,
                            });
                            handleMixPanelData(EVENTS.click_3_dots_reports_mg);
                          }}
                        >
                          <img src={ThreeDots} alt="dots" />
                        </IconButton>
                      </div>
                      <Menu
                        id="menu-appbar"
                        // className="smooth-shadow"
                        anchorEl={anchorEl?.target}
                        keepMounted
                        getContentAnchorEl={null}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={Boolean(anchorEl?.target)}
                        onClose={handleClose}
                        sx={{
                          marginTop: "10px",
                          "& .MuiMenu-paper": {
                            borderRadius: "8px",
                            padding: "4px 12px",
                            border: "1px solid #E0E3E5",
                            boxShadow: "none",
                          },
                          "& .MuiMenuItem-root": {
                            fontFamily: "Inter",
                            fontSize: "14px",
                            padding: "10px 16px",
                            borderRadius: "8px",
                          },
                          "& .MuiMenuItem-root:hover": {
                            background: "#E3EEFF",
                          },
                          boxShadow: "none",
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            setActiveReport(anchorEl.report);
                            handleOpen();
                            handleClose();
                            handleMixPanelData(EVENTS.add_sources_rm, { report_name: report?.report_name });
                          }}
                        >
                          <AddSourceIcon
                            style={{
                              marginRight: "12px",
                              width: "20px",
                              height: "20px",
                            }}
                          />
                          {""}
                          Add Sources
                        </MenuItem>
                        {/* <MenuItem
                                                    onClick={() => {
                                                        handleClose();
                                                    }}
                                                >
                                                    <DuplicateIcon
                                                        style={{
                                                            marginRight: "12px",
                                                            width: "20px",
                                                            height: "20px",
                                                        }}
                                                    />
                                                    Duplicate
                                                </MenuItem> */}
                        <MenuItem
                          onClick={() => {
                            setHeaderModal(true);
                          }}
                        >
                          <DeleteIcon
                            style={{
                              marginRight: "12px",
                              width: "20px",
                              height: "20px",
                            }}
                          />{" "}
                          Delete
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            // setShare(true);
                            handleOpen2(true);
                            handleClose();
                            setActiveReport(anchorEl.report);
                            handleMixPanelData(EVENTS.click_share_rm, { report_name: report.report_name });
                          }}
                        >
                          <UserIcon
                            style={{
                              marginRight: "12px",
                              width: "20px",
                              height: "20px",
                            }}
                          />{" "}
                          Share
                        </MenuItem>
                      </Menu>
                    </div>
                    <div
                      className={`flip-card ${activeReport?.id === report.id ? "flipped" : ""}`}
                      style={{ marginTop: "16px", marginBottom: "8px" }}
                      onMouseEnter={() => setActiveReport(report)}
                      onMouseLeave={() => setActiveReport(null)}
                    >
                      {activeReport?.id !== report.id ? (
                        <div className="flip-card-front">
                          <img
                            src={require(`../../Assets/Images/demo_graph${(index % 8) + 1}.svg`)}
                            alt="Demo Graph"
                            style={{ width: "100%", maxHeight: "180px" }}
                          />
                        </div>
                      ) : (
                        <div className="flip-card-back">
                          {/* <img src={DemoGraph} alt="Selected Info" style={{ width: "100%" }} /> */}
                          <Typography
                            style={{ fontFamily: "Inter", fontSize: "12px" }}
                          >
                            Connected Sources
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "4px",
                              margin: "8px 0px",
                            }}
                          >
                            {Object.keys(selectedAccounts).map(
                              (channelType, index) => {
                                const channel = GetChannel(channelType) ?? {};
                                return (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      background: "#F6F7F8",
                                      borderRadius: "4px",
                                      height: "24px",
                                      width: "24px",
                                    }}
                                  >
                                    <img
                                      style={{ height: 16, width: 16 }}
                                      src={channel.icon}
                                      alt={channel.title}
                                    />
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <Typography
                            style={{ fontFamily: "Inter", fontSize: "12px" }}
                          >
                            Connected Ad Accounts
                          </Typography>
                          <Tooltip
                            arrow
                            placement="right-start"
                            PopperProps={{
                              sx: {
                                "& .MuiTooltip-arrow": { color: "white" },
                                "& .MuiTooltip-tooltip": {
                                  backgroundColor: "white",
                                  color: "black",
                                  marginBottom: "20px",
                                  filter:
                                    "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))",
                                  padding: "20px 25px",
                                },
                              },
                            }}
                            title={
                              Object.keys(selectedAccounts).length ? (
                                <Box
                                  sx={{
                                    borderRadius: "12px",
                                    width: "277px",
                                    height: "auto",
                                    maxHeight: "56vh",
                                    overflow: "auto",
                                  }}
                                >
                                  {Object.keys(selectedAccounts).map(
                                    (channelType, index) => {
                                      const channel = GetChannel(channelType) ?? {};
                                      return (
                                        <div
                                          key={channel.type}
                                          value={channel.name}
                                        >
                                          <div
                                            style={{
                                              marginTop:
                                                index === 0 ? "0px" : "10px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "10px",
                                                marginBottom: "10px",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  justifyContent: "center",
                                                  background: "#F6F7F8",
                                                  borderRadius: "4px",
                                                  height: "32px",
                                                  width: "32px",
                                                }}
                                              >
                                                <img
                                                  style={{
                                                    height: 18,
                                                    width: 18,
                                                  }}
                                                  src={channel.icon}
                                                  alt={channel.title}
                                                />
                                              </div>
                                              <Typography
                                                style={{
                                                  fontFamily: "Inter",
                                                  fontSize: "14px",
                                                  fontWeight: "700",
                                                }}
                                              >
                                                {channel.title} (
                                                {
                                                  selectedAccounts[channelType]
                                                    .length
                                                }
                                                )
                                              </Typography>
                                            </div>
                                            <ul style={{ paddingLeft: "20px" }}>
                                              {selectedAccounts[
                                                channelType
                                              ].map((acc) => (
                                                <>
                                                  <li
                                                    style={{
                                                      fontSize: "12px",
                                                      fontFamily: "Inter",
                                                      marginBottom: "5px",
                                                    }}
                                                  >
                                                    {acc.title}
                                                    <Typography
                                                      style={{
                                                        fontSize: "10px",
                                                        fontFamily: "Inter",
                                                        color: "#585969",
                                                      }}
                                                    >
                                                      {acc.subtitle}
                                                    </Typography>
                                                  </li>
                                                </>
                                              ))}
                                            </ul>
                                          </div>
                                          {Object.keys(selectedAccounts)
                                            .length !==
                                            index + 1 && (
                                              <p
                                                style={{
                                                  borderBottom:
                                                    "1px solid #ECEEF1",
                                                  width: "90%",
                                                  marginTop: "10px",
                                                }}
                                              ></p>
                                            )}
                                        </div>
                                      );
                                    }
                                  )}
                                </Box>
                              ) : (
                                ""
                              )
                            }
                          >
                            <div
                              ref={containerRef}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                gap: "8px",
                                height: "80px",
                                margin: "8px 0px",
                                padding: "8px 10px",
                                background: "#F6F7F8",
                                borderRadius: "8px",
                                overflow: "hidden",
                              }}
                            >
                              {(activeReport?.audiences ?? [])
                                .slice(0, moreAccs > 0 ? -moreAccs : undefined)
                                .map((account, index) => {
                                  return (
                                    <div
                                      style={{
                                        background: "#FFF",
                                        borderRadius: "40px",
                                        padding: "4px 8px",
                                        maxWidth:
                                          index > 0
                                            ? "calc(100% - 80px)"
                                            : "100%",
                                        height: "fit-content",
                                      }}
                                    >
                                      <Typography
                                        className="inter ellipseText"
                                        style={{
                                          color: "#5C6674",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {account.title}
                                      </Typography>
                                    </div>
                                  );
                                })}
                              {moreAccs > 0 && (
                                <div style={{ padding: "4px 8px" }}>
                                  <Typography
                                    style={{
                                      color: "#0869FB",
                                      fontFamily: "Inter",
                                      fontSize: "12px",
                                    }}
                                  >
                                    + {moreAccs} More
                                  </Typography>
                                </div>
                              )}
                            </div>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        disableRipple
                        style={{
                          height: "30px",
                          textTransform: "none",
                          padding: "8px 16px",
                        }}
                        onClick={() => {
                          handleMixPanelData(EVENTS.click_view_reports);
                          history.push(
                            `/dashboard?report_id=${report.id}&report_name=${report.report_name}`
                          );
                        }}
                      >
                        View{" "}
                        <img src={Arrow} style={{ marginLeft: "8px" }} alt="" />
                      </Button>
                    </div>
                  </Box>
                </Grid>
              ))}
            </Grid>
          ) : (
            <div className="displayColCenter" style={{ height: "inherit" }}>
              <NoReports style={{ width: "50%" }} />
              <Typography style={{ fontSize: "16px", fontWeight: 600 }}>
                No Report{" "}
                {allReports.length && !filteredReports.length
                  ? "with given Filters is"
                  : ""}{" "}
                Available
              </Typography>
              <Typography style={{ color: "#5C6674", marginTop: "8px" }}>
                There are no reports
                {allReports.length && !filteredReports.length
                  ? " with given Filters."
                  : ""}{" "}
                Please create a new report
              </Typography>
            </div>
          )}
        </div>
      </div>
      {isopen && (
        <AccountModal
          isopen={isopen}
          setIsOpen={setIsOpen}
          report={activeReport}
          reportCallback={reportCallback}
        />
      )}
      {share && <ShareModal isopen={share} setIsOpen={setIsShare} report={activeReport} reportCallback={reportCallback} />}
      <DeleteModal
        modal={headerModal}
        setModal={handleClose}
        message={`Do you really want to delete this Report - ${anchorEl?.report?.report_name}? The access will be revoked from all invited users. `}
        onDelete={() => {
          handleReportDelete(anchorEl.report);
        }}
      />
      <SuccessModal
        modal={reportGeneration.message === "Success"}
        setModal={() => setReportGeneration({ message: "none", error: "none", operation: "none" })}
        message={`Report ${reportGeneration.operation} is Successful`}
      />

      <FailureModal
        modal={reportGeneration.message === "Fail"}
        setModal={() => setReportGeneration({ message: "none", error: "none", operation: "none" })}
        message={reportGeneration?.error?.length > 0 ? "" : `Report ${reportGeneration.operation} Failed`}
        error={reportGeneration.error}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  allReports: state.report.allReports ?? [],
});

const mapDispatchToProps = (dispatch) => ({
  checkAuth: () => dispatch(checkAuth()),
  getUser: () => dispatch(getUser()),
  getAllReports: (_) => dispatch(getAllReports(_)),
  deleteReport: (_) => dispatch(deleteReport(_)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportManagement);
