import { useEffect } from "react";
import Hotjar from '@hotjar/browser';

const siteId = process.env.REACT_APP_HOTZAR_SITE_ID;
const hotjarVersion = 6;

const useHotjar = () => {
   useEffect(() => {
    Hotjar.init(siteId, hotjarVersion, { debug: true });
   },[]);
};

export default useHotjar;
