import { getFilter } from "../../api/filter/saga";
import { Filter } from "../../models/chart";
import { checkDate } from "../Charts/ChartUtils";

const getSubFilters = (filterList) => {
    let subFilters = [];
    var startIndex = 0;
    for (let i = 0; i < filterList.length; i++) {
        if (filterList[i].boolOperator === "AND") {
            subFilters.push(filterList.slice(startIndex, i + 1));
            startIndex = i + 1;
        }
    }
    if (startIndex < filterList.length) { subFilters.push(filterList.slice(startIndex)); }
    return subFilters;
}

function getFilterGroupingObj(filters) {
    return ({
        operator: "AND",
        filterGroups: getSubFilters(filters).map(subFilters => ({
            operator: "OR",
            filters: subFilters.map(filter => ({
                fieldName: filter.filter.id,
                dataSource: filter.filter.dataSource,
                account: filter.filter.account ?? filter.filter.audiences?.[0]?.metaData,
                operator: filter.operator.type.startsWith("NOT_") ? filter.operator.type.substring(4) : filter.operator.type,
                not: filter.operator.type.startsWith("NOT_") ? true : false,
                expressions: Array.isArray(filter.value) ? filter.value : [filter.value],
                forCurrentDateRange: !filter.removeReportRange,
                forCompareWithDateRange: !filter.removeCompareRange
            })),
        })),
    })
}

export function getFilterObject(filter, compare) {
    let filterObject = {};
    const filters = [...filter.dimensionFilters, ...filter.metricFilters].filter(filter => !compare || !checkDate(filter.filter));

    if (filters.length) {
        filterObject.filterGrouping = getFilterGroupingObj(filters);
    }

    if (filter.segment) {
        if (!Array.isArray(filter.segment)) {
            filterObject.segments = ([filter.segment.id]).filter(val => val)
        } else if (filter.segment.length) {
            const attribution = filter.segment.flat().filter(attr => attr).map(attr => attr.id);
            if (attribution.length > 0) { filterObject.actionAttributionWindows = attribution; }
            // if (filter.segment[2]) { filterObject.useUnifiedAttributionSetting = filter.segment[2].value; }
        }
    }

    return filterObject;
}

export const transformToFilterDataObject = (filter) => {
    const filters = [...filter.dimensionFilters, ...filter.metricFilters];
    const { users, ...filterMetadata } = filter.account || {};

    let filterObject = {
        filterName: filter.name,
        dataSource: filter.channelType?.id ? { id: filter.channelType.id, type: "BLEND" } : filter.channelType,
        audiences: filter.account ? [{
            type: "AD_ACCOUNT", id: filter.account.id,
            dataSource: filter.channelType, metaData: filterMetadata
        }] : undefined,
        reportType: filter.tableId,
        filterId: filter.id,
        filterGrouping: {},
        additionalFilters: []
    }

    if (filters.length) {
        filterObject.filterGrouping = getFilterGroupingObj(filters);
    }

    if (filter.segment) {
        if (!Array.isArray(filter.segment)) {
            let segmentObj = { name: "segments" }
            segmentObj.values = [filter.segment.id];
            filterObject.additionalFilters.push(segmentObj)
        } else if (filter.segment.length) {
            let segmentObj = { name: "actionAttributionWindows" }
            const attribution = filter.segment.flat().map(attr => attr?.id);
            if (attribution && attribution.length > 0) { segmentObj.values = attribution; }
            // if (filter.segment[2]) {
            //     segmentObj.unify_data = {
            //         "field_name": "useUnifiedAttributionSetting",
            //         "value": filter.segment[2].value ?? filter.segment.id.toString()
            //     }
            // }
            filterObject.additionalFilters.push(segmentObj)
        }
    }

    return filterObject;
}

export const transformFromFilterDataObject = (currentFilter) => {
    console.log("currentFilterX",currentFilter)
    let segments = []
    const segmentFilter = currentFilter.additionalFilters[0]
    if (segmentFilter) {
        if (segmentFilter.name === "segments") {
            segments = { id: segmentFilter.values.toString() }
        } else if (segmentFilter.name === "actionAttributionWindows") {
            const views = currentFilter.additionalFilters[0]?.values?.map(val => (val ? ({ id: val }) : null))
            if (views && views.length) segments.push(...views.slice(0, 2), views.slice(2));
            // const unify_data = currentFilter.additionalFilters[0]?.unify_data
            // if (unify_data) {
            //     segments[2] = { id: unify_data.value.toString(), value: unify_data.value }
            // }
        }
    }
    let mapFilter = {
        id: currentFilter.id,
        channelType: currentFilter.dataSource ?? currentFilter?.account?.channelType,
        account: currentFilter.audiences?.[0]?.metaData ?? currentFilter?.account,
        tableId: currentFilter.reportType,
        name: currentFilter.name ?? "",
        segment: segments ?? [],
        dimensionFilters: currentFilter.filterGroups.reduce((allFilters, filterGroup, groupIndex) => {
            return allFilters.concat(filterGroup.filters.map((filter, index) => {
                let newFilter = {
                    boolOperator: filterGroup.filters.length === (index + 1)
                        ? (currentFilter.filterGroups.length === (groupIndex + 1) ? null : currentFilter.operator)
                        : filterGroup.operator,
                    filter: {
                        id: filter.fieldName,
                        dataSource: filter.dataSource,
                        account: filter.account
                    },
                    operator: {
                        type: filter.not ? `NOT_${filter.operator}` : filter.operator,
                    },
                    value: filter.expressions,
                    urns: ""
                }
                return newFilter;
            }))
        }, []) ?? [],
        metricFilters: currentFilter.additionalFilters?.find(filter => filter.name === "Metrics")?.values ?? []
    }
    return mapFilter;
}

export const getFilterDetails = (filterIds, channelType, updateFilter) => {
    getFilter(filterIds).then(data => {
        const filters = data.responses.map(filter => filter.error ? Filter.new({ channelType }) : Filter.copy(transformFromFilterDataObject(filter?.filterGrouping)))
        updateFilter(filters)
    }).catch(err => {
        console.log("~ getFilter ~ err", err)
        if (err?.data?.err === "Filter not found") {
            updateFilter([Filter.new({ channelType })])
        }
    });
}

export const FilterOperators = [
    { label: "Equal", type: "EQUALS", dataType: ["DIMENSION", "METRIC"] },
    { label: "Not Equal", type: "NOT_EQUALS", dataType: ["DIMENSION", "METRIC"] },
    { label: "Greater Than", type: "GREATER_THAN", dataType: ["METRIC"] },
    { label: "Greater Than or Equal", type: "GREATER_THAN_OR_EQUAL", dataType: ["METRIC"] },
    { label: "Less Than", type: "LESS_THAN", dataType: ["METRIC"] },
    { label: "Less Than or Equal", type: "LESS_THAN_OR_EQUAL", dataType: ["METRIC"] },
    { label: "Contains", type: "CONTAINS", dataType: ["DIMENSION"] },
    { label: "Not Contains", type: "NOT_CONTAINS", dataType: ["DIMENSION"] },
    { label: "In", type: "IN_LIST", dataType: ["DIMENSION", "METRIC"] },
    { label: "Not In", type: "NOT_IN_LIST", dataType: ["DIMENSION", "METRIC"] },
    { label: "Starts With", type: "STARTS_WITH", dataType: ["DIMENSION"] },
    { label: "Ends With", type: "ENDS_WITH", dataType: ["DIMENSION"] }
]