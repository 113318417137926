import { useState, useEffect } from "react";

export const useMixpanelSessionTime = (data,sessionCallback) => {
  const [totalSessionTime, setTotalSessionTime] = useState(0); 

  const [sessionStartTime, setSessionStartTime] = useState(null);

  const startSession = () => {
    setSessionStartTime(Date.now());
  };

  const endSession = () => {
    if (sessionStartTime) {
      const sessionDuration = (Date.now() - sessionStartTime) / 1000;
      setTotalSessionTime(prevTotalTime => prevTotalTime + sessionDuration); 
      setSessionStartTime(null);
    }
  };

  useEffect(() => {
    startSession();
    return () => {
      endSession();
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      endSession();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [data]);
  
  return {
    totalSessionTime
  }; 
};

export default useMixpanelSessionTime;
