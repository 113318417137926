import {
  Autocomplete,
  Box,
  createFilterOptions,
  FormControl,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import React from "react";
import TextFieldElement from "./TextFieldElement";
import { v4 as uuidv4 } from "uuid";
import closeIcon from "../../Assets/Icons/close_icon.svg";
import { getFormula } from "../../utils/FormulaBasedMetrics";

const filterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (option) => (option.name ?? option.title) + option.subtitle,
});

const renderInvalidColumn = (type) => (
  <span style={{color:"#E91B16",fontSize:"12px",transform:"translateY(5px)",display:"inline-block",marginBottom:"5px"}}>
    <img src={closeIcon} />
    {type === "INVALID_COLUMN" && " Cannot find this column in the selected source."}
    {type === " The selected columns are incompatible"}
  </span>
)


export default function MetricList({
  channel,
  metric,
  metrics,
  isEditable,
  defaultTitle = "Add New Metric",
  onClick,
  onDelete,
  draggableProvided,
  value,
  disabled, msg,
  isColumnThere = true,
  selectedAccounts
}) {

  const [open, setOpen] = React.useState(false);
  console.log("chartMetrics_other",isColumnThere,metric?.metric?.name,selectedAccounts)
  return (
    <FormControl
      sx={{
        marginTop: "10px",
        display: "flex",
        flexDirection: "row",
        width: "100%",
        background: "#FFFFFF",
      }}
    >
      {isEditable || !metric ? (
        <Tooltip arrow placement="bottom-end"
          PopperProps={{
            sx: {
              "& .MuiTooltip-arrow": {
                color: "white",
                left: "-50px !important",
              },
              "& .MuiTooltip-tooltip": {
                backgroundColor: "white",
                borderRadius: "12px",
                color: "black",
                filter: "drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))",
                padding: "20px",
                fontSize: "12px", fontWeight: 400
              },
            },
          }}
          title={disabled ? msg : ""}
        >
          <Autocomplete
            id="grouped-dimensions"
            sx={{
              width: "93%",
              opacity: disabled ? 0.6 : 1,

            }}
            slotProps={{
paper:{
  sx:{
    "& .MuiAutocomplete-groupLabel":{
      lineHeight:"20px"
    }
  }
}
            }}
           
            open={open}
            onOpen={() => { !disabled && setOpen(true) }}
            onClose={() => setOpen(false)}
            value={metric}
            options={metrics}
            size="small"
            className="bg-read-only metric-input"
            groupBy={(metric) => metric.group}
            getOptionLabel={(metric) => metric.name ?? metric.title}
            getOptionDisabled={(option) => option.disabled}
            disabled={disabled}
            filterOptions={filterOptions}
            renderOption={(props, option, { selected }) => {
              const accountID = option.account?.id ?? option.audiences?.[0]?.metaData?.id;
              const formula = getFormula(channel?.type, option?.id);
              return (
                <li
                  style={{
                    background: selected ? "#EBF3FF" : "",
                    margin: "12px",
                    borderRadius: "8px",
                    paddingLeft: "12px",
                  }}
                  {...props}
                  key={uuidv4()}
                >
                  {option.icon && (
                    <img
                      height="20px"
                      width="20px"
                      style={{ marginLeft: "10px" }}
                      src={option.icon}
                      alt={option.id}
                    />
                  )}

                  <p
                    style={{
                      margin: "0px 5px",
                      paddingLeft: "10px",
                      minWidth: "70%",
                    }}
                  >
                    {option.name ?? option.title}
                    {option.subtitle && (
                      <h6 style={{ padding: "5px 0px" }}> {option.subtitle}</h6>
                    )}
                    {option.audiences?.[0] && (
                      <h6 style={{ padding: "5px 0px" }}> {option.audiences[0].metaData?.title} ({option.audiences[0].metaData?.id}) </h6>
                    )}
                    {/* {selectedAccounts?.length > 1 &&
                        (option.group === "Customized" || accountID) && (
                          <p
                            style={{
                              fontFamily: "Inter",
                              fontWeight: "400",
                              fontSize: "12px",
                              transform: "translateY(-4px)",
                              // padding: "0px 5px",
                            }}
                          >
                            {option.account.name ?? option.account.id}
                          </p>
                    )} */}
                  </p>
                  {(option.group === "Customized" || option.group?.includes("Custom Metric") || formula) && (
                    <Tooltip arrow
                      placement="bottom"
                      PopperProps={{
                        sx: {
                          '& .MuiTooltip-arrow': { color: 'white' },
                          "& .MuiTooltip-tooltip": {
                            backgroundColor: "#FFF",
                            borderRadius: "6px",
                            filter: 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))',
                            padding: "8px",
                            marginRight: "50px"
                          }
                        }
                      }}
                      title={
                        <Box sx={{ width: "277px", display: "flex", flexDirection: "column", gap: "8px" }}>
                          <Typography style={{ fontFamily: "Inter", fontSize: "14px", fontWeight: "600", color: "#000000" }}>{option.name}</Typography>
                          <Typography style={{ fontFamily: "Inter", fontSize: "12px", fontWeight: "600", color: "#585969" }}>Custom Formula: <span style={{ fontFamily: "Inter", fontSize: "12px", fontWeight: "400", color: "#585969" }}>{option.formula ?? formula}</span></Typography>
                          <Typography style={{ fontFamily: "Inter", fontSize: "12px", fontWeight: "600", color: "#585969" }}>Data Type: <span style={{ fontFamily: "Inter", fontSize: "12px", fontWeight: "400", color: "#585969" }}>{option.dataType}</span></Typography>
                          {(!formula && accountID) && <Typography style={{ fontFamily: "Inter", fontSize: "12px", fontWeight: "600", color: "#585969" }}>Account ID: <span style={{ fontFamily: "Inter", fontSize: "12px", fontWeight: "400", color: "#585969" }}>{accountID}</span></Typography>}
                          <Typography style={{ fontFamily: "Inter", fontSize: "12px", fontWeight: "600", color: "#585969" }}>Metric Type: <span style={{ fontFamily: "Inter", fontSize: "12px", fontWeight: "400", color: "#585969" }}> {option.metricType} </span></Typography>
                          <Typography style={{ fontFamily: "Inter", fontSize: "12px", fontWeight: "600", color: "#585969" }}>Note: <span style={{ fontFamily: "Inter", fontSize: "12px", fontWeight: "400", color: "#585969" }}>Calculated metrics will are aggregated according to the formula.</span></Typography>
                        </Box>
                      }
                    >
                      <span style={{
                        fontFamily: "Inter", fontWeight: "500", fontSize: "12px",
                        backgroundColor: "#F6F7F8",
                        color: "#5C6674",
                        padding: "3px 8px",
                        textAlign: "right",
                        display: "inline-block",
                        borderRadius: "4px"
                      }}
                      >
                        CAL
                      </span>
                    </Tooltip>
                  )}
                </li>
              );
            }}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            disableClearable={!onDelete}
            renderInput={(params) => (
              <>
                <TextFieldElement
                  channel={channel}
                  draggableProvided={draggableProvided}
                  metric={value ?? metric}
                  label={metric ? channel.title : defaultTitle}
                  isEmpty={!metric}
                  {...params}
                />
                {!isColumnThere && renderInvalidColumn("INVALID_COLUMN")}
              </>
            )}
            onChange={(event, newValue) => onClick(newValue)}
          />
        </Tooltip>
      ) : (
        <div style={{display:"flex",flexDirection:"column",width:"100%"}}>
          <TextFieldElement
            channel={channel}
            draggableProvided={draggableProvided}
            metric={value}
            value={metric.metric ? metric.metric.name : metric.name ?? metric.title}
            onClick={() => onClick(metric)}
          />
          {!isColumnThere && renderInvalidColumn("INVALID_COLUMN")}
        </div>
      )}

      {metric && onDelete && (
        <IconButton
          disableRipple
          disableFocusRipple
          sx={{ padding: "0",marginTop: !isColumnThere ? "-25px" : "0px" }}
          aria-label="delete"
          onClick={onDelete}
        >
          <Clear />
        </IconButton>
      )}
    </FormControl>
  );
}
