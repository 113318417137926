import React from "react";
import { Box } from "@mui/material";
import { NEW_FILTER } from "../../../models/chart";
import InputSelect from "../../Common/InputSelect";

export default function AccountsBox({
  blend,
  accounts = [],
  currAccount,
  setCurrAccount,
  accountError,
  dimensionFiltersRef,
}) {
  console.log("currAccountM", currAccount);
  return (
    <Box sx={{ marginTop: "20px", padding: "5px 10px 10px 0px", width: "33%" }}>
      <p style={{ marginBottom: 8, fontSize: "14px" }}>Ad Account</p>
      <InputSelect
        value={blend ?? currAccount?.metaData ?? currAccount ?? undefined}
        options={blend ?? accounts}
        multiple={blend}
        readOnly={blend}
        onChange={(event, newValue) => {
          if (!blend) {
            dimensionFiltersRef.current = [NEW_FILTER];
            setCurrAccount(newValue);
          }
        }}
        title={""}
        error={accountError}
        selectBg={blend ? "none" : ""}
      />
    </Box>
  );
}
