import { useState } from "react";
import { IconButton, Snackbar } from "@mui/material";
import CopyIcon from "../../Assets/Icons/copyicon.svg";
import { EVENTS,handleMixPanelData } from "../../utils/mixpanelV2";

const CopyToClipboardButton = ({ shareUrl,report }) => {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        handleMixPanelData(EVENTS.click_copy_url, { report_name: report.report_name });
        setOpen(true);
        navigator.clipboard.writeText(shareUrl);
    };

    return (
        <>
            <IconButton disableRipple onClick={handleClick} color="primary">
                <img src={CopyIcon} alt="" />
            </IconButton>
            <Snackbar
                message="Link Copied to clipboard"
                anchorOrigin={{ vertical: "top", horizontal: "left" }}
                autoHideDuration={2000}
                onClose={() => setOpen(false)}
                open={open}
                style={{ borderRadius: "12px", bottom: "210px", left: "190px" }}
            />
        </>
    );
};

export default CopyToClipboardButton;