import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Checkbox, Chip, List } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import CloseIcon from "@mui/icons-material/Close";
import { CheckBox } from "@mui/icons-material";

const InputSelect2 = ({
  value,
  multiple,
  readOnly,
  options,
  title,
  error,
  selectBg,
  onDelete,
  handleDelete,
  handleAdd,
  ...props
}) => {
  return (
    <Autocomplete
      sx={{
        "& .hover": {
          border: "none !important",
        },
        "& .MuiOutlinedInput-root": {
          borderRadius: "0",
          padding: "0",
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          border: "1px solid #dddddd!important",
          borderRadius: "6px !important",
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            borderColor: "none !important",
          },
      }}
      disableClearable={readOnly}
      multiple={multiple}
      options={options}
      style={{ width: "95%", background: "white" }}
      getOptionLabel={(option) => option.name ?? option.type ?? option.title}
      groupBy={(option) => option.group}
      value={
        !multiple || !options.length
          ? value
          : value
              ?.map((val) => options.find((op) => op.id === val?.id) ?? null)
              .filter((op) => op) ?? []
      }
      isOptionEqualToValue={(option, value) => option?.id === value?.id}
      renderOption={(props, option, { selected }) => {
        return (
          <li
            style={{
              // background: selected ? selectBg ?? "#FFF" : "",
              background: "none",
              borderRadius: "8px",
              marginBottom: "10px",
              padding: "0px",
            }}
            {...props}
            key={uuidv4()}
            onClick={() => handleAdd(option)}
          >
            <Checkbox checked={selected} />
            {option.icon && (
              <img
                style={{ width: "20px", height: "20px" }}
                src={option.icon}
                alt={option.id}
              />
            )}

            <p style={{ margin: "0px ", paddingLeft: "10px" }}>
              {option.name ?? option.type ?? option.title}
              <span style={{ display: "block",color:"#5C6674",fontSize:"11px" }}>{option?.id}</span>
              {option.subtitle && (
                <h6 style={{ padding: "5px 0px" }}> {option.subtitle}</h6>
              )}
              {option.audiences?.[0] && (
                <h6 style={{ padding: "5px 0px" }}>
                  {" "}
                  {option.audiences[0].metaData?.title} (
                  {option.audiences[0].metaData?.id}){" "}
                </h6>
              )}
            </p>
          </li>
        );
      }}
      renderTags={(value, getTagProps) => (
        <div
          className="chipBlock"
          style={
            value[0]?.title
              ? { maxHeight: "140px" }
              : { maxHeight: "60px", overflow: "scroll" }
          }
        >
          {value.map((option, index) => {
            return (
              <Chip
                sx={{
                  borderRadius: "6px",
                  background: "#f7f7f7",
                  border: "1px solid #EAEAEC",
                  marginRight: "10px",
                }}
                label={<span>{option.name ?? option.title}</span>}
                size="small"
                deleteIcon={<CloseIcon />}
                onDelete={() => handleDelete(option)}
              />
            );
          })}
        </div>
      )}
      renderInput={(params) => {
        params.label = title ?? "New segment";
        return (
          <TextField
            error={error}
            sx={{
              "& > div": { flexWrap: "nowrap !important" },
              "& input": {
                fontSize: "14px",
                minWidth: readOnly ? "0 !important" : "30px",
              },
              "& label": { fontSize: "14px", maxWidth: "75%" },
              "& fieldset": { borderColor: "#E0E3E5" },
            }}
            {...params}
            size="small"
            placeholder={
              readOnly ? "" : value?.length === 0 ? "Select Account" : ""
            }
          />
        );
      }}
      {...props}
    />
  );
};
export default InputSelect2;
