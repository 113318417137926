export const mergeColumnsBasedUponAccountId = (accountColsMapped,accounts={}) => {
    if(Object.keys(accountColsMapped).length > 0){
        let [ metrics,dimensions ] = [ [], [] ];
        Object.entries((accountColsMapped)).forEach(([accountId, value]) => {
            let name = "";
            if(value?.metrics?.length > 0) name = value?.metrics[0]?.account?.name ?? value?.metrics[0]?.account?.title ?? accounts[accountId]?.name ?? accounts[accountId]?.title ?? accountId;
            const updatedMetric = value?.metrics?.map((metric) => {
                if(metric?.group === "Customized") metric.group = `Custom Metric (${name})`;
                if(metric?.group === "Offline Attribution Metric") metric.group = `Offline Attribution Metric (${name})`;
                return metric;
            });
            accountColsMapped[accountId]["metrics"] = updatedMetric ?? [];
        });
        Object.entries(accountColsMapped).forEach(([ _, value]) => {
            metrics = metrics.concat(value.metrics);
            dimensions = dimensions.concat(value.dimensions);
        });
        metrics = metrics.sort((a, b) => {
            if (a.group < b.group) return -1;
            if (a.group > b.group) return 1;
            return 0;
        });
        return { metrics, dimensions };
    }
    return null;
}

export const filterdAccount = (reportAdAccs, accounts) => {
    return reportAdAccs.filter((acc) => {
              const isThere =  accounts?.find((chartAccount) => chartAccount === acc?.id)
              if(isThere) return acc;
          }).filter(Boolean);
}

export const filterCurrentAccount = (accounts,currentFilter) => {
    const accountId = currentFilter?.account?.id ?? currentFilter?.account?.id;
    return accounts?.filter((account) => account?.id  === accountId)
}