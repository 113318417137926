import { ChannelType } from "../../models/channel";

export const channelType = ChannelType.Bing;

export function getAccountId(accounts,isEditWidget = false) {
    console.log("getAccountId bing-ads",accounts);
    if(isEditWidget){
        return { "accountIds":  Array.isArray(accounts) ? accounts.map((account) => ({ "accountId": account.accountId?.toString() })) : Object.entries(accounts).map(([key,value]) => ({ "accountId": value.accountId.toString() })) }
    }
    return { accountId: accounts?.accountId?.toString() };
}

export function getFilterObject(filter,filters, isBlends = true) {
    if(!isBlends){
        return {
          "filterGroupings": filters
        }
    }
    if (filter.id) {
        return {
            filterGrouping: {
                filterId: filter.id
            }
        }
    }
    const filterArray = [...filter.dimensionFilters];
    const newFilter = [];
    filterArray.forEach((f) => {
        if (f.filter.filterable) {
            newFilter.push({
                fieldName: f.filter.id,
                values: f.value.split(",")
            })
        }
    });

    return {
        filters: newFilter
    };
}

export function getApiObject(metrics, dimensions, filter, table, dataLevel, excludeDeleted, filters, isBlends) {
    return {
        metrics: metrics,
        dimensions: dimensions,
        ...getFilterObject(filter,filters, isBlends),
    };
}

const convertFloats = ["Ctr"]

export function transformData(data) {
    [...data.leftMetrics, ...(data.rightMetrics ?? [])].forEach((m) => {
        convertFloats.find((f) => {
            if (f === m.id) {
                data.data.forEach((d) => {
                    const floatValue = d[m.name];
                    d[m.name] = floatValue;
                });
            }
        });
    });
}


export const properties = {
    dateDimension: { id: "Date", name: "Date" },
    filterOperators: [
        { label: "Equal", type: "EQUALS", dataType: ["CURRENCY", "INTEGER", "STRING", "ENUM"] },
        { label: "Not Equal", type: "NOT_EQUALS", dataType: ["CURRENCY", "INTEGER", "STRING", "ENUM"] },
        { label: "Greater Than", type: "GREATER_THAN", dataType: ["CURRENCY", "INTEGER", "FLOAT"] },
        { label: "Greater Than or Equal", type: "GREATER_THAN_OR_EQUAL", dataType: ["CURRENCY", "INTEGER", "FLOAT"] },
        { label: "Less Than", type: "LESS_THAN", dataType: ["CURRENCY", "INTEGER", "FLOAT"] },
        { label: "Less Than or Equal", type: "LESS_THAN_OR_EQUAL", dataType: ["CURRENCY", "INTEGER", "FLOAT"] },
        { label: "Contains", type: "CONTAINS", dataType: "STRING" },
        { label: "Not Contains", type: "NOT_CONTAINS", dataType: "STRING" },
        { label: "In", type: "IN_LIST", dataType: ["STRING", "ENUM"] },
        { label: "Not In", type: "NOT_IN_LIST", dataType: ["STRING", "ENUM"] },
        { label: "Starts With", type: "STARTS_WITH", dataType: "STRING" },
        { label: "Ends With", type: "ENDS_WITH", dataType: "STRING" }
        // { label: "In", type: "IN", dataType: ["ENUM"] },
    ],
    // validFilterBoolOperators: []
}
