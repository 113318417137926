import { ChannelType } from "../../models/channel";

export const channelType = ChannelType.LinkedInAds;


export function getAccountId(accounts, isEditWidget = false) {
    console.log("getAccountId linkedin-ads",accounts);
    if(isEditWidget){
        return {
            "accountIds":  Array.isArray(accounts) ? accounts.map((account) => ({ "accountId": account.accountId })) : Object.entries(accounts).map(([key,value]) => ({ "accountId": value.accountId }))
        }
    }
    return {
        accountId: accounts?.accountId,
        facets: [
            {
                key: "accounts",
                urns: [accounts?.accountId]
            }
        ]
    }
}

export function getFilterObject(filter,filters, isBlends = true) {
    if(!isBlends){
        return {
            "filterGroupings": filters
        }
    }
    if (filter.id) {
        return {
            filterGrouping: {
                filterId: filter.id
            }
        }
    }
    const filterArray = [...filter.dimensionFilters];
    const newFilter = [];
    filterArray.forEach((f) => {
        newFilter.push({
            key: f.value,
            urns: [f.urns]
        })
    });

    if (newFilter.length === 0) {
        return {};
    }

    return {
        facets: newFilter
    };
}

export function getApiObject(metrics, dimensions, filter, table, dataLevel, excludeDeleted, filters, isBlends) {
    return {
        metrics: metrics,
        dimensions: dimensions,
        ...getFilterObject(filter,filters, isBlends),
    };
}

export const properties = {
    dateDimension: { id: "date", name: "Date" },
    validKeys: ["accounts", "campaigns", "creatives", "campaignGroups"],
    filterOperators: [
        { label: "Equal", type: "EQUALS", dataType: ["CURRENCY", "INTEGER", "STRING"] },
        { label: "Not Equal", type: "NOT_EQUALS", dataType: ["CURRENCY", "INTEGER", "STRING"] },
        { label: "Greater Than", type: "GREATER_THAN", dataType: ["CURRENCY", "INTEGER", "FLOAT"] },
        { label: "Greater Than or Equal", type: "GREATER_THAN_OR_EQUAL", dataType: ["CURRENCY", "INTEGER", "FLOAT"] },
        { label: "Less Than", type: "LESS_THAN", dataType: ["CURRENCY", "INTEGER", "FLOAT"] },
        { label: "Less Than or Equal", type: "LESS_THAN_OR_EQUAL", dataType: ["CURRENCY", "INTEGER", "FLOAT"] },
        { label: "Contains", type: "CONTAINS", dataType: "STRING" },
        { label: "Not Contains", type: "NOT_CONTAINS", dataType: "STRING" },
        { label: "In", type: "IN_LIST", dataType: "STRING" },
        { label: "Not In", type: "NOT_IN_LIST", dataType: "STRING" },
        { label: "Starts With", type: "STARTS_WITH", dataType: "STRING" },
        { label: "Ends With", type: "ENDS_WITH", dataType: "STRING" }
    ],
    // validFilterBoolOperators: ["AND"]
}