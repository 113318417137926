import { ChannelType } from "../../models/channel";

export const channelType = ChannelType.FacebookAds;

export function getAccountId(accounts, isEditWidget = false) {
    console.log("getAccountId facebook-ads",accounts);
    if(isEditWidget){
        return { accountIds: Array.isArray(accounts) ? accounts.map((account) => ({ "accountId": account.id })) : Object.entries(accounts).map(([key,value]) => ({ "accountId": key })) };
    }
    return { accountId: accounts?.accountId };
}

function getSubFilters(filterList) {
    let subFilters = [];
    var startIndex = 0;
    for (let i = 0; i < filterList.length; i++) {
        if (filterList[i].boolOperator === "AND") {
            subFilters.push(filterList.slice(startIndex, i + 1));
            startIndex = i + 1;
        }
    }
    if (startIndex < filterList.length) { subFilters.push(filterList.slice(startIndex)); }
    return subFilters;
}

export function getFilterObject(filter,filters, isBlends = true) {
    if(!isBlends){
        return {
          "filterGroupings": filters
        }
    }
    let filterObject;
    if (filter.id) {
        filterObject = {
            filterGrouping: {
                filterId: filter.id
            }
        }
    } else {
        const filters = [...filter.dimensionFilters, ...filter.metricFilters];

        filterObject = filters.length === 0 ? {} : {
            filterGrouping: {
                operator: "AND",
                filterGroups: getSubFilters(filters).map(subFilters => ({
                    operator: "OR",
                    filters: subFilters.map(filter => ({
                        fieldName: filter.filter.id,
                        operator: filter.operator.type.startsWith("NOT_") ? filter.operator.type.substring(4) : filter.operator.type,
                        not: filter.operator.type.startsWith("NOT_") ? true : false,
                        expressions: Array.isArray(filter.value) ? filter.value : [filter.value]
                    })),
                })),
            },
        }

        const attribution = filter.segment.slice(0, 2).filter(attr => attr).map(attr => attr.id);
        if (attribution.length > 0) { filterObject.actionAttributionWindows = attribution; }
        if (filter.segment[2]) { filterObject.useUnifiedAttributionSetting = filter.segment[2].value; }
    }
    return filterObject;
}

export function getApiObject(metrics, dimensions, filter, table, dataLevel, excludeDeleted, filters, isBlends) {
    console.log("filters, isBlends",filters, isBlends)
    return {
        metrics: metrics,
        dimensions: dimensions,
        ...getFilterObject(filter,filters, isBlends),
        hadDelivery: true //!!excludeDeleted
    };
}

export function validateFilter(filter) {
    if (!filter.segment) { filter.segment = [null, null, null]; }
}

export const properties = {
    dateDimension: { id: "day", name: "Day" },
    filterOperators: [
        { label: "Equal", type: "EQUALS", dataType: ["Number", "Price", "String"] },
        { label: "Not Equal", type: "NOT_EQUALS", dataType: ["Number", "Price", "String"] },
        { label: "Greater Than", type: "GREATER_THAN", dataType: ["Number", "Price", "Percent"] },
        { label: "Greater Than or Equal", type: "GREATER_THAN_OR_EQUAL", dataType: ["Number", "Price", "Percent"] },
        { label: "Less Than", type: "LESS_THAN", dataType: ["Number", "Price", "Percent"] },
        { label: "Less Than or Equal", type: "LESS_THAN_OR_EQUAL", dataType: ["Number", "Price", "Percent"] },
        // { label: "In range", type: "IN_RANGE", dataType: ["Number", "Price", "Percent"] },
        // { label: "Not in range", type: "NOT_IN_RANGE", dataType: ["Number", "Price", "Percent"] },
        { label: "Contains", type: "CONTAINS", dataType: "String" },
        { label: "Not Contains", type: "NOT_CONTAINS", dataType: "String" },
        { label: "In", type: "IN_LIST", dataType: "String" },
        { label: "Not In", type: "NOT_IN_LIST", dataType: "String" },
        { label: "Starts With", type: "STARTS_WITH", dataType: "String" },
        { label: "Ends With", type: "ENDS_WITH", dataType: "String" },
        // [ ANY, ALL, AFTER, BEFORE, ON_OR_AFTER, ON_OR_BEFORE, NONE, TOP ],
    ],
    // validFilterBoolOperators: [ "AND" ],
    segmentBoxTitle: "Parameters",
}