import React, { useEffect, useRef } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  TextareaAutosize,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import MetricList from "./MetricList";
import { Metric } from "../../models/chart";
import { GetChannel, SupportedChannels } from "../../models/channel";
import SwitchChart from "./SwitchChart";
import EditChartType3 from "./EditChartType3";
import EditChartType2 from "./EditChartType2";
import EditChartType1 from "./EditChartType1";
import EditChartType4 from "./EditChartType4";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import { ChartFilterSupport } from "../Charts/ChartUtils";
import {
  getAccountBasedColumns,
  getChartCurrency,
} from "../../api/channel/saga";
import CustomLoader from "../Common/CustomLoader";
import Scrollbars from "react-custom-scrollbars";
import { getBlendColumns } from "../../api/blends/saga";
import { ReactComponent as NoResultsIcon } from "../../Assets/errorMsgImages/NoResults.svg";
import { buttonClickSaveAndUpdate } from "../../api/channel/action";
import { useDispatch } from "react-redux";
import InputSelect from "../Common/InputSelect";
import DataBlendIcon from "../../Assets/Icons/DataBlend.svg";
import { handleMixPanelData, EVENTS } from "../../utils/mixpanelV2";
import { updateReportAdAccCols } from "../../api/report/action";
import {  mergeColumnsBasedUponAccountId,filterdAccount } from "./util";
import InputSelect2 from "../Common/InputSelect2";

export function BlendsAllAccountsSelected(channel, selectedAccounts) {
  const state = useSelector((state) => channel.getState(state));
  const accounts = channel.transformer.getAccountId(selectedAccounts, state);
  for (let account in accounts) {
    if (!(account in selectedAccounts)) {
      return false;
    }
  }
  return true;
}

function EditChart({
  handleAllChartsForceReload,
  editDrawerState,
  reportAdAccs,
  newChart = false,
  setChartType,
  blends,
  authenticatedAccounts,
  report
}) {

  const isChannelAuthenticated = (channel) => !!authenticatedAccounts.find((type) => type === channel?.type);
  const reportAdAccsGrouped = (reportAdAccs ?? []).reduce(
    (channels, account) => {
      channels[account.channelType ?? account.source] = (
        channels[account.channelType ?? account.source] ?? []
      ).concat(account);
      return channels;
    }, {}
  );

  const allChannelsList = (Object.keys(reportAdAccsGrouped)).flatMap((key) => {
    const channel = GetChannel(key);
    return isChannelAuthenticated(channel) ? channel : []
  }).concat(blends.map(GetChannel))
    .map((channel) => ({
      id: channel.type,
      name: channel.title,
      icon: channel.icon,
      isBlend: channel.isBlend,
      // disabled: !isChannelAccountSelected(channel)
    }));

  const { chart, handleChartChange } = editDrawerState;
  const [leftMetrics, setLeftMetrics] = React.useState(
    chart.leftMetrics?.concat([null])
  );
  const [rightMetrics, setRightMetrics] = React.useState(
    chart.rightMetrics?.concat([null])
  );
  const [chartMetrics, setChartMetrics] = React.useState([null]);
  const [chartDimensions, setChartDimensions] = React.useState(
    chart.dimensions?.concat([null])
  );
  const [table, setTable] = React.useState(chart.table);
  const [dataLevel, setDataLevel] = React.useState({
    id: chart.dataLevel,
    name: chart.dataLevel,
  });
  const blendChannelData =
    chart.channelType?.id &&
    blends.find((blend) => blend.id === chart.channelType.id);
  const [channel, setChannel] = React.useState(
    GetChannel(blendChannelData ?? chart.channelType)
  );
  const [channelCols, setChannelCols] = React.useState({
    metrics: [],
    dimensions: [],
  });
  const blendsState = useSelector((state) => state.blends);
  const state = useSelector((state) => channel?.getState(state)) ?? {};
  const accountList = reportAdAccsGrouped[channel?.type] ?? [];

  const [account, setAccount] = React.useState(reportAdAccs.find(acc => acc?.id === chart?.accounts?.[0]));

  /* multi account */
  const [account_R, setAccount_R] = React.useState(filterdAccount(reportAdAccs,chart?.accounts));
  const [mappedAccount_R,setMappedAccount_R] = React.useState({});
  const [selectedAccounts_R,setSelectedAccounts_R] = React.useState({});
  const selectedAccountsArr_R = React.useMemo(() => Object.values(selectedAccounts_R),[selectedAccounts_R]);
  const [accountColsMapped_R, setAccountColsMapped_R] = React.useState({});
  const accountCols_R = React.useMemo(() => mergeColumnsBasedUponAccountId(accountColsMapped_R, selectedAccounts_R), [accountColsMapped_R]);
  /* multi account end*/

  const [accountCols, setAccountCols] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [renderNew, setRenderNew] = React.useState(false);
  const dispatch = useDispatch();

  const tables = state.tables ?? [];
  const baseMetrics = channel?.isBlend
    ? channelCols.metrics
    : Array.isArray(state.metrics)
      ? state.metrics
      : state.metrics?.[table?.id + (dataLevel?.id ? `:${dataLevel.id}` : "")] ??
      [];
  const customMetrics = accountCols
    ? accountCols.metrics
    : Array.isArray(state.customMetrics)
      ? state.customMetrics
      : state.customMetrics?.[
      table?.id + (dataLevel?.id ? `:${dataLevel.id}` : "")
      ] ?? [];
  const metrics = baseMetrics.concat(customMetrics);
  const leftPanelRef = useRef(null);

  // React.useEffect(() => {
  //   if (channel && !channel.isBlend && account) {
  //     setLoading(true);
  //     getAccountBasedColumns(account, channel)
  //       .then((data) => {
  //         let columns = data?.columns ?? [];
  //         const accCols = columns.reduce((group, col) => {
  //           col.type === "DIMENSION" ? group.dimensions.push(col) : group.metrics.push(col)
  //           return group;
  //         }, { dimensions: [], metrics: [] })
  //         setAccountCols(accCols);
  //         dispatch(updateReportAdAccCols({ [account.title]: accCols }))
  //       })
  //       .catch((err) => {
  //         console.log(
  //           "🚀 ~ file: EditChart.js:67 ~ getAccountBasedColumns ~ err:",
  //           err
  //         );
  //         setAccountCols({ metrics: [], dimensions: [] });
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //     getChartCurrency(account, channel)
  //       .then((data) => {
  //         handleChartChange("account", {
  //           ...account,
  //           currency: data.currency ?? null,
  //         });
  //       })
  //       .catch((err) => {
  //         console.log(
  //           "🚀 ~ file: EditChart.js:67 ~ getChartCurrency ~ err:",
  //           err
  //         );
  //         handleChartChange("account", { ...account, currency: null });
  //       })
  //       .finally(() => {
  //         setRenderNew(!renderNew);
  //       });
  //   } else {
  //     setLoading(false);
  //     handleChartChange("account", account);
  //     setAccountCols(null);
  //     setRenderNew(!renderNew);
  //   }
  // }, [account]);

  const [currentMetricState, setCurrentMetricState] = React.useState(
    chart.type === "KPI"
      ? {
        isOpen: true,
        metric: leftMetrics[0] ?? Metric.new(chart),
        axis: "left",
        index: 0,
      }
      : {
        isOpen: false,
        metric: Metric.new(chart),
        axis: "left",
        index: -1,
      }
  );
  const [currentChartType, setCurrentChartType] = React.useState(chart.type);
  React.useEffect(() => {
    handleChartChange("type", currentChartType);
  }, [currentChartType]);

  const updateBlendCols = () => {
    if (channel?.isBlend) {
      setLoading(true);
      getBlendColumns(channel.type)
        .then((data) => {
          let columns = (data?.columns ?? []).reduce((columns, column) => ({
            ...columns,
            [column.type.toLowerCase() + 's']: (columns[column.type.toLowerCase() + 's'] ?? [])
              .concat({
                ...column, name: column.uiName ?? column.name,
                icon: GetChannel(column.dataSource)?.icon ?? DataBlendIcon
              })
          }), { dimensions: [], metrics: blendsState.blendCustomCols[channel.type] ?? [] });
          setChannelCols(columns);
        })
        .catch((err) => {
          setChannelCols({ metrics: [], dimensions: [] });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setChannelCols({ metrics: [], dimensions: [] });
    }
  };

  React.useEffect(() => {
    if (
      channel?.type !== chart.channelType &&
      channel?.type !== chart.channelType.id
    ) {
      handleChartChange("channel", channel);
      setAccount(null);
      setAccount_R(null);
      setTable(null);
      setLeftMetrics([null]);
      setRightMetrics(chart.rightMetrics ? [null] : null);
      setChartDimensions([null]);
      setChartType(chart.type);
    }
    updateBlendCols();
  }, [channel]);

  useEffect(() => {
    setTimeout(() => {
      if (editDrawerState.isOpen && editDrawerState.chart.type !== "ADD") {
        const editWigetScroll = document.querySelector("#editWigetScroll");
        if (leftPanelRef.current) {
          leftPanelRef.current.scrollTop(editWigetScroll?.offsetTop ?? 600);
        }
      }
    }, 0);
  }, [editDrawerState, renderNew]);

  /* multi account */
  const makeApiCallBasedOnAccounId = async (account, channel, mappedAccount) => {
    setLoading(true);
    try {
        const promises  = [getAccountBasedColumns(account, channel.type),getChartCurrency(account, channel)];
        const [dataColumns, dataChartCurrency] = await Promise.allSettled(promises);
        let columns = dataColumns?.value?.columns ?? [];
        const accCols = columns.reduce(
          (group, col) => {
            col.type === "DIMENSION"
              ? group.dimensions.push(col)
              : group.metrics.push(col);
            return group;
          },
          { dimensions: [], metrics: [] }
        );
        setAccountColsMapped_R((prev) => ({...prev, [account.id] : { ...accCols }}));
        setLoading(false);
        mappedAccount[account.id] = { ...mappedAccount[account.id], "currency": dataChartCurrency.value?.currency }
    } catch (error) {
        setLoading(false);
    }
    return { mappedAccount };
  }

  const setAccountDataInitialLoading = async (accountsTemp, mappedAccount) => {
    if(Object.keys(accountsTemp ?? {}).length > 0){
      setSelectedAccounts_R({ ...accountsTemp });
      const values  = Object.entries(accountsTemp);
      for(let i = 0; i < values.length; i++){
        const { mappedAccount: mappedAccountTemp } = await makeApiCallBasedOnAccounId(values[i][1], channel, mappedAccount);
        mappedAccount = mappedAccountTemp;
      }
    }
    setMappedAccount_R(mappedAccount);
    handleChartChange("account", mappedAccount);
  }
  
  React.useEffect(() => {
    if (channel && !channel.isBlend && account_R?.length > 0) {
      const mappedAccount = account_R?.reduce((acc,curr) => { if(curr.id) acc[curr.id] = curr; return acc; }, {}) ?? {};
      setAccountDataInitialLoading(chart.accountsTemp, mappedAccount);
    } else {
      setLoading(false);
      handleChartChange("account", account_R);
      setAccountCols(null); //revisit
      setRenderNew(!renderNew);
    }
  }, [account_R]);

  const handleAccount = async (option) => {
    if(!selectedAccounts_R[option.id]){
      const selectedAccountsTemp = { ...selectedAccounts_R, [option.id] : { ...option }};
      setSelectedAccounts_R(selectedAccountsTemp);
      const { mappedAccount: mappedAccountTemp } = await makeApiCallBasedOnAccounId(option, GetChannel(option.channelType), selectedAccountsTemp);
      setMappedAccount_R(mappedAccountTemp);
      handleChartChange("account", mappedAccountTemp);
    }else {
      const selectedAccountsTemp = { ...selectedAccounts_R };
      const mappedAccountsWithColumn = { ...accountColsMapped_R };
      const mappedAccountChart = { ...mappedAccount_R };
      delete selectedAccountsTemp[option.id];
      delete mappedAccountsWithColumn[option.id];
      delete mappedAccountChart[option.id];
      setSelectedAccounts_R({ ...selectedAccountsTemp });
      setAccountColsMapped_R({ ...mappedAccountsWithColumn });
      setMappedAccount_R({ ...mappedAccountChart });
      handleChartChange("account", mappedAccountChart);
    }
  }

  useEffect(() => {
    setAccount_R(filterdAccount(reportAdAccs,chart?.accounts));
  },[reportAdAccs]);

  const updateFilterOnAccountChange = () => {
    
  }
  
  // console.log("selectedAccountsArr_R",selectedAccountsArr_R,chart);
  // console.log("chartX",chart);
  console.log("metricsXYZ", chart,channel,blendChannelData ?? chart.channelType)
  /* multi account end */

  return (
    <div>
      <Scrollbars
        autoHide
        style={{
          height: "calc(100vh - 355px)",
          overflow: "hidden",
        }}
        ref={leftPanelRef}
      >
        {!currentMetricState.isOpen ||
          (chart.type === "KPI" && newChart) ? (
          <Accordion
            defaultExpanded={true}
            sx={{
              boxShadow: "none",
              '&:before': {
                display: 'none',
              },
            }}
            disableGutters
            elevation={0}
          >
            <AccordionSummary
              sx={{
                maxHeight: "30px",
                marginBottom: "0px",
                width: "100%",
                padding: "0px",
              }}
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#000000",
                }}
              >
                Chart Type
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ width: "100%", padding: "0px" }}>
              <SwitchChart
                key={channel + newChart}
                chartMetrics={chartMetrics}
                chartDimensions={chartDimensions}
                chartType={currentChartType}
                setChartType={
                  newChart && setChartType
                    ? setChartType
                    : setCurrentChartType
                }
                newChart={newChart}
              />
            </AccordionDetails>
          </Accordion>
        ) : null}

        {chart.type === "TEXT" ?
          <Accordion
            defaultExpanded={true}
            sx={{
              boxShadow: "none", width: "100%",
              "& .Mui-expanded": { marginBottom: "0px" },
              '&:before': { display: 'none', },
            }}
            disableGutters
            elevation={0}
          >
            <AccordionSummary
              sx={{ maxHeight: "30px", width: "100%", padding: "0px" }}
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#000000",
                }}
              >
                Input Text
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: "0px 0px" }}>
              <TextareaAutosize minRows={2} placeholder="Enter Text"
                defaultValue={chart.title}
                onChange={(e) => handleChartChange("title", e.target.value)}
                sx={{
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    { border: "1px solid #0869FB", },
                  "&:hover": { border: "1px solid #0869FB", },
                }}
                style={{
                  minHeight: "80px", width: "100%", border: "1px solid #EAEAEC", resize: "none",
                  overflow: "auto", fontSize: "14px", padding: "16px", outline: "none",
                  fontFamily: "Inter", fontWeight: "500", borderRadius: "8px"
                }} />
            </AccordionDetails>
          </Accordion>
          : <>
            {!currentMetricState.isOpen || chart.type === "KPI" ? (
              <Accordion
                defaultExpanded={true}
                sx={{
                  boxShadow: "none", width: "100%",
                  '&:before': { display: 'none', },
                }}
                disableGutters
                elevation={0}
              >
                <AccordionSummary
                  sx={{
                    maxHeight: "54px",
                    minHeight: "54px",
                    width: "100%",
                    padding: "0px",
                  }}
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#000",
                    }}
                  >
                    Source
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "0px 0px", width: "100%" }}>
                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#666666",
                    }}
                  >
                    Channel
                  </Typography>
                  <MetricList
                    channel={channel ?? {}}
                    metric={channel && { id: channel.type, name: channel.title }}
                    metrics={allChannelsList}
                    isEditable={true}
                    onClick={(channelObject) =>
                      setChannel(
                        GetChannel(
                          channelObject.isBlend ? channelObject : channelObject.id
                        )
                      )
                    }
                    defaultTitle="Data platform"
                  />

                  <Typography
                    style={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "#666666",
                      marginTop: "10px",
                    }}
                  >
                    Account
                  </Typography>
                  {!channel?.isBlend ? (
                    // <MetricList
                    //   channel={channel ?? {}}
                    //   metric={account}
                    //   metrics={accountList}
                    //   isEditable={true}
                    //   onClick={(accObj) => setAccount(accObj)}
                    //   defaultTitle="Data Account"
                    // />
                    <InputSelect2 
                        options={accountList}
                        multiple={true}
                        readOnly={false}
                        title={""}
                        handleDelete={(option) => handleAccount(option)}
                        value={selectedAccountsArr_R}
                        handleAdd={(option) => handleAccount(option)}
                    />
                  ) : (
                    <div style={{ marginTop: "10px" }}>
                      <InputSelect
                        value={state.audiences?.map((audience) => (audience?.[0]?.metaData))}
                        options={state.audiences?.map((audience) => {
                          const channel = GetChannel(audience?.[0]?.dataSource);
                          let metaData = audience?.[0]?.metaData ?? {};
                          return ({
                            id: metaData.id,
                            name: metaData.title,
                            subtitle: metaData.id + (metaData.subTitle ? ", " + metaData.subTitle : ""),
                            icon: channel?.icon
                          })
                        }) ?? []}
                        multiple={true}
                        readOnly={true}
                        title={""}
                        selectBg={"none"}
                      />
                    </div>
                  )}
                </AccordionDetails>
              </Accordion>
            ) : null}

            {!tables.length && !metrics.length ? (
              <div
                style={{
                  height: "60%",
                  borderRadius: "6px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#FAFAFA",
                }}
              >
                <NoResultsIcon width="200px" height="200px" />
                <h3
                  style={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    color: "#666666",
                    textAlign: "center",
                  }}
                >
                  The previous selected source does not have any data. <br />
                  Please change the source to update the chart.
                </h3>
              </div>
            ) : (
              <>
                {loading ? (
                  <CustomLoader
                    heading=""
                    subHeading={"Waiting for account columns data"}
                  />
                ) : currentChartType === "KPI" ? (
                  <EditChartType4
                    key={channel + currentChartType + renderNew}
                    renderNew={renderNew}
                    handleAllChartsForceReload={handleAllChartsForceReload}
                    editDrawerState={editDrawerState}
                    currentMetricState={currentMetricState}
                    setCurrentMetricState={setCurrentMetricState}
                    channel={channel}
                    accountCols={accountCols_R}
                    channelCols={channelCols}
                    selectedAccounts={selectedAccountsArr_R}
                  />
                ) : ChartFilterSupport(currentChartType) ? (
                  <EditChartType3
                    key={channel + currentChartType + renderNew}
                    handleAllChartsForceReload={handleAllChartsForceReload}
                    editDrawerState={editDrawerState}
                    currentChartType={currentChartType}
                    setSwitchChartMetrics={setChartMetrics}
                    setSwitchChartDimensions={setChartDimensions}
                    channel={channel}
                    accountCols={accountCols_R}
                    channelCols={channelCols}
                    selectedAccounts={selectedAccountsArr_R}
                    accountColsMappedAcc = {accountColsMapped_R}
                  />
                ) : (currentChartType === "PIE" || currentChartType === "PIE3D" ||
                  currentChartType === "DONUT" || currentChartType === "DONUT3D" ||
                  currentChartType === "FUNNEL") ? (
                  <EditChartType2
                    key={channel + currentChartType + renderNew}
                    handleAllChartsForceReload={handleAllChartsForceReload}
                    editDrawerState={editDrawerState}
                    currentMetricState={currentMetricState}
                    setCurrentMetricState={setCurrentMetricState}
                    channel={channel}
                    accountCols={accountCols_R}
                    channelCols={channelCols}
                    selectedAccounts={selectedAccountsArr_R}
                  />
                ) : (
                  <EditChartType1
                    key={channel + currentChartType + renderNew}
                    handleAllChartsForceReload={handleAllChartsForceReload}
                    editDrawerState={editDrawerState}
                    currentMetricState={currentMetricState}
                    setCurrentMetricState={setCurrentMetricState}
                    channel={channel}
                    accountCols={accountCols_R}
                    channelCols={channelCols}
                    selectedAccounts={selectedAccountsArr_R}
                  />
                )}
              </>
            )}
          </>}
      </Scrollbars>

      <div style={{ width: "100%", marginTop: "20px" }}>
        <Button
          style={{ width: "100%", height: "40px", borderRadius: "8px" }}
          variant="contained"
          sx={{
            background: "#0968fb",
          }}
          onClick={() => {
            handleMixPanelData(EVENTS.chart_type_edit_widget, { report_name: report, ...chart })
            handleChartChange("saveChanges");
          }}
        >
          Save & Update
        </Button>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  blends: state.blends.blends,
  authenticatedAccounts: state.channel.authenticatedAccounts,
});

export default connect(mapStateToProps)(EditChart);