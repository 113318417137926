import React, { useState, useEffect } from "react";
import Modal from 'react-modal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Select, { components } from "react-select";
import StarIcon from '@mui/icons-material/Star';
import { Button, Grid, IconButton, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import { inviteUsers } from "../../api/user/saga";
import { handleMixPanelData,EVENTS } from "../../utils/mixpanelV2";

const orgDomainRoles = [
	// {
	// 	value: "superadmin",
	// 	label: "Super Admin",
	// 	description:"Accessible to add and manage the data sources and organization members"
	// },
	{
		value: "admin",
		label: "Admin",
		description: "Accessible to manage the data sources"
	},
	{
		value: "editor",
		label: "Editor",
		description: "Accessible to modify the reports"
	},
	{
		value: "viewer",
		label: "Viewer",
		description: "Accessible to view the reports"
	}
];

// const externalRoles = [
// 	{
// 		value: "externaleditor",
// 		label: "External Editor",
// 		description: "Accessible to modify other org's reports as well"
// 	}
// ];

// const supportedDomians = ["pixis.ai"]

const Option = (props) => {
	return (
		<components.Option {...props} >
			<div className="left">
				<strong className="title">{props.data.label}</strong>
				<div style={{ fontSize: "10px" }}>{props.data.description}</div>
			</div>
		</components.Option>
	);
};


const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		backgroundColor: "#ffff",
		width: "400px",
		minHeight: "400px",
		padding: "0",
		zIndex: 9999999999,
		position: "absolute",
		overflow: 'hidden'
	},
};

const InviteTeamModal = (props) => {
	const { modal, setModal, domain, dataOfInviteNewUser, setDataOfInviteNewUser, getOrganizationMember, getInvitedOrgainzationMembers } = props;
	const [isError, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [isEmailValid, setEmailValid] = useState(false);
	const [dropdownOptions, setDropDownOptions] = useState([])

	useEffect(() => {
		setError(false);
		setErrorMessage("");
		setEmailValid(false);
		setDataOfInviteNewUser({ email: "", name: "", role: "" });
	}, [modal]);

	useEffect(() => {
		setDropDownOptions([])
		if (dataOfInviteNewUser.email.endsWith(domain.split(".").splice(-2).join("."))) {
			setDropDownOptions([...orgDomainRoles])
		} else {
			// for (let domain of supportedDomians) {
			// 	if (dataOfInviteNewUser.email.endsWith(domain)) {
			// 		setDropDownOptions([...externalRoles])
			// 		break;
			// 	}
			// }
		}
	}, [dataOfInviteNewUser.email])

	const getDropdownValue = (data) => {
		const { value } = data;
		setDataOfInviteNewUser({ ...dataOfInviteNewUser, role: value })
	};

	const isValidation = (userData) => {
		let error = { errorMessage: "", isError: false, isEmailValid: true };
		if (userData.email === "" || userData.name === "" || userData.role === "") {
			error.errorMessage = "Fields can't be empty";
			error.isError = true;
		}
		if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(userData.email)) {
			error.errorMessage = "enter valid email";
			error.isEmailValid = false
			error.isError = true;
		}
		return error;
	}

	const getEmail = (email) => {
		setDataOfInviteNewUser({ ...dataOfInviteNewUser, email: email });
		if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
			setEmailValid(false);
			return;
		}
		setEmailValid(true);
	}

	const sendAnInvite = async () => {
		const isAnyError = isValidation(dataOfInviteNewUser);
		if (isAnyError.isError) {
			setError(true);
			setErrorMessage(isAnyError.errorMessage);
			return;
		}
		inviteUsers({
			name: dataOfInviteNewUser.name,
			email: dataOfInviteNewUser.email,
			role: dataOfInviteNewUser.role
		}).then((data) => {
			setErrorMessage(dataOfInviteNewUser.role === "externaleditor"
				? "You've invited mentioned user as an external editor for your org."
				: "Invitation link have sent to your user's email id valid for next 7 days");
			dataOfInviteNewUser.role === "externaleditor" ? getOrganizationMember() : getInvitedOrgainzationMembers();
			setDataOfInviteNewUser({ email: "", name: "", role: "" });
			setTimeout(() => {
				setModal(!modal);
			}, 2000);
			handleMixPanelData(EVENTS.success_invite_team);
		}).catch((err) => {
			setErrorMessage(err.data.message);
			setError(true);
		})
	}

	return (
		<>
			<Modal isOpen={modal} style={customStyles}>
				<div style={{ width: "100%", height: "40px", borderBottom: "1px solid #eee", padding: "10px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
					<div>
						<b>Invite a team member</b>
					</div>
					<IconButton
						onClick={() => setModal(!modal)}
						aria-label="save"
						sx={{
							borderRadius: '4px',
							padding: '2px',
							backgroundColor: '#F2F2F2',
							fontSize: '18px',

						}}
						size="small"
					>
						<Close />
					</IconButton>
				</div>
				<div style={{ width: "100%", height: "calc(100% - 40px)", position: "relative", zIndex: 90000000, backgroundColor: "#ffff", justifyContent: "center", alignItems: "center", display: "flex" }}>
					<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", width: "80%" }}>
						<Grid item xs={12} style={{ padding: '4px 0 4px 0', marginBottom: "15px", marginTop: "5px" }}>
							<p style={{ color: isError ? "red" : "green" }}>{errorMessage}</p>
							<p><b>E-mail <sup style={{ color: "red" }}><StarIcon style={{ fontSize: "9px" }} /></sup></b></p>
							<TextField
								placeholder="Invite someone..."
								sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
								style={{ width: '100%' }}
								id="outlined-basic"
								InputLabelProps={{ shrink: true }}
								InputProps={{
									endAdornment: <CheckCircleIcon style={{ color: isEmailValid ? "#1CC9AA" : "#eee" }} />
								}}
								value={dataOfInviteNewUser["email"]}
								onChange={(e) => getEmail(e.target.value)}
								size="small"
								variant="outlined" />
						</Grid>
						<Grid item xs={12} style={{ padding: '4px 0 4px 0', marginBottom: "15px" }}>
							<p><b>Name</b></p>
							<TextField
								sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }}
								placeholder="Enter your name"
								style={{ width: '100%' }}
								size="small"
								id="outlined-basic"
								value={dataOfInviteNewUser["name"]}
								InputLabelProps={{ shrink: true, style: { color: "black", fontWeight: "bolder" } }}
								onChange={(e) => setDataOfInviteNewUser({ ...dataOfInviteNewUser, name: e.target.value })}
								variant="outlined" />
						</Grid>
						<Grid item xs={12} style={{ padding: '4px 0 4px 0', marginBottom: "15px" }}>
							<p><b>Role</b></p>
							<Select
								placeholder="Select Role"
								label="Single select"
								options={dropdownOptions}
								components={{
									Option
								}}
								styles={{
									menuPortal: provided => ({ ...provided, zIndex: 9999 }),
									menu: provided => ({ ...provided, zIndex: 9999 })
								}}
								menuPlacement="bottom"
								menuPortalTarget={document.body}
								menuPosition={'fixed'}
								onChange={getDropdownValue}
							/>
						</Grid>
						<Grid cstyle={{ padding: '4px 0 4px 0' }} item xs={12} alignItems="flex-end">
							<Button style={{ backgroundColor: "#0869FB", color: "white", padding: "8px 15px", textAlign: "right", float: "right", borderRadius: "10px", marginTop: "0px", textTransform: "none", transform: "translateY(-10px)" }} onClick={() => sendAnInvite()}>Send an invite</Button>
						</Grid>
					</div>
				</div>
			</Modal>
		</>
	)
}
export default InviteTeamModal;